import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  FirestoreDataConverter,
} from 'firebase/firestore';
import { store } from '../providers/StoreProvider';
import { companyAtom } from '../atoms/auth';
import { db9 } from '../firebase';

export function companyDoc<T = DocumentData>(
  collectionPath: string,
  documentPath: string
): DocumentReference<T>;
export function companyDoc<T>(
  collectionPath: string,
  documentPath: string,
  converter: FirestoreDataConverter<T>
): DocumentReference<T>;

export function companyDoc<T>(
  collectionPath: string,
  documentPath: string,
  converter?: FirestoreDataConverter<T>
): DocumentReference<T | DocumentData> {
  const company = store.get(companyAtom);
  const q = doc(
    db9,
    `companies/${company.id}/${collectionPath}/${documentPath}`
  );
  if (converter) {
    return q.withConverter(converter);
  }
  return q;
}

export function companyCollection<T = DocumentData>(
  collectionPath: string
): CollectionReference<T>;
export function companyCollection<T>(
  collectionPath: string,
  converter: FirestoreDataConverter<T>
): CollectionReference<T>;

export function companyCollection<T>(
  collectionPath: string,
  converter?: FirestoreDataConverter<T>
): CollectionReference<T | DocumentData> {
  const company = store.get(companyAtom);
  const q = collection(db9, `companies/${company.id}/${collectionPath}`);
  if (converter) {
    return q.withConverter(converter);
  }
  return q;
}
