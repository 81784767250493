import React, { useState } from 'react';
import { eventNames, logEvent } from '../../../analytics';
import { Icon } from 'components/basics';
import { Close } from 'components/icons';
import { BasicInfoSection } from './BasicInfoSection';
import { InteractionHistorySection } from './InteractionHistorySection';
import { CompanyInteractionHistorySection } from './CompanyInteractionHistorySection';
import { MemoInfoSection } from './MemoInfoSection';
import { useStore } from 'hooks/useStore';
import { observer } from 'mobx-react';
import { SalesforceContact } from './SalesforceContact';
import { twMerge } from 'tailwind-merge';
import { ContactObject } from '../../../store/contact';
import SimpleBar from 'simplebar-react';
import { RightSidebarContactName } from './RightSidebarContactName';
import { Avatar } from '../../../components/basics/Avatar/Avatar';

interface RightSidebarProps {
  contact: ContactObject;
}

const Contact: React.FC<RightSidebarProps> = observer(({ contact }) => {
  const store = useStore();
  const [view, setView] = useState('contact');

  const closeContact = () => {
    store.contactStore.selectedContact = null;
    logEvent(eventNames.hide_message_histories);
  };

  return (
    <div className="fixed right-0 top-0 z-30 grid h-full max-h-full w-[400px] grid-rows-[auto_1fr] bg-sea-50 shadow-lg">
      <div className="flex items-center justify-between border-b border-sumi-300 px-6 py-4">
        <div className="flex items-center justify-between">
          <h2
            className={twMerge(
              'text-md mb-0',
              view === 'contact'
                ? 'font-semibold'
                : 'cursor-pointer text-gray-500'
            )}
            onClick={() => setView('contact')}
          >
            コンタクト
          </h2>
          {store.isCRMIntegrationSupported &&
            store.oauthStore.salesforceIntegrated && (
              <h2
                className={twMerge(
                  'text-md mb-0 pl-6',
                  view === 'salesforce'
                    ? 'font-semibold'
                    : 'cursor-pointer text-gray-500'
                )}
                onClick={() => setView('salesforce')}
              >
                Salesforce
              </h2>
            )}
        </div>
        <button
          type="button"
          className="m-0 flex h-6 w-6 cursor-pointer items-center justify-center bg-transparent p-0"
          onClick={closeContact}
        >
          <Icon icon={Close} size={12} className="block" />
        </button>
      </div>
      <SimpleBar className="h-full overflow-auto">
        <div className="flex flex-col gap-6 p-6">
          <div className="flex items-center">
            <Avatar
              className="mr-4 flex-shrink-0"
              size={48}
              name={
                contact.name[0]?.toUpperCase() || contact.email[0].toUpperCase()
              }
            />
            <RightSidebarContactName />
          </div>
          {view === 'contact' ? (
            <>
              <BasicInfoSection />
              <InteractionHistorySection />
              <CompanyInteractionHistorySection />
              <MemoInfoSection />
            </>
          ) : (
            <SalesforceContact />
          )}
        </div>
      </SimpleBar>
    </div>
  );
});

export default Contact;
