import {
  createDefaultFilterCondition,
  FilterCondition,
} from '../FilterEditDrawer/FilterEditDrawer';
import { Fragment } from 'react';
import { Icon } from '../../../../../components/basics';
import { DoNotDisturbOn, Plus } from '../../../../../components/icons';
import { tv } from 'tailwind-variants';
import { Checkbox, Input, Select } from '../../../../../components/forms';
import { cloneDeep } from 'lodash';
import { Tooltip } from '../../../../../components/basics/Tooltip/Tooltip';

type Props = {
  value: FilterCondition[][];
  onChange: (value: FilterCondition[][]) => void;
  onValidate: (valid: boolean) => void;
  disabled?: boolean;
};

const orCondition = tv({
  base: '',
  variants: {
    removable: {
      true: 'grid grid-cols-[1fr_auto] gap-2',
    },
  },
});

export const FilterConditionInput = ({
  value,
  onChange,
  onValidate,
  disabled = false,
}: Props) => {
  const validate = (value: FilterCondition[][]) => {
    const foundInvalidCondition = value.find((orCondition) =>
      orCondition.find((condition) => condition.filterText.trim().length === 0)
    );
    onValidate(foundInvalidCondition == null);
  };
  const update = (i: number, j: number, condition: FilterCondition) => {
    const copy = cloneDeep(value);
    copy[i][j] = condition;
    validateAndChange(copy);
  };
  const addOrCondition = (i: number) => {
    const shallowCopy = [...value];
    shallowCopy[i] = [...shallowCopy[i], createDefaultFilterCondition()];
    validateAndChange(shallowCopy);
  };
  const addAndCondition = () => {
    const newValue = [...value, [createDefaultFilterCondition()]];
    validateAndChange(newValue);
  };
  const onDelete = (i: number, j: number) => {
    const shallowCopy = [...value];
    const deletedArray = shallowCopy[i].filter((_e, index) => index !== j);
    if (deletedArray.length === 0) {
      const newValue = shallowCopy.filter((_e, index) => index !== i);
      validateAndChange(newValue);
      return;
    }
    shallowCopy[i] = deletedArray;
    validateAndChange(shallowCopy);
  };
  const validateAndChange = (value: FilterCondition[][]) => {
    validate(value);
    onChange(value);
  };
  return (
    <div className="flex flex-col gap-2 text-sm">
      {value.map((orConditions, i) => {
        return (
          <Fragment key={i}>
            <div className="flex flex-col gap-2 rounded-lg bg-sumi-50 p-2">
              {orConditions.map((condition, j) => {
                const isRemovable = orConditions.length > 1 || value.length > 1;
                return (
                  <Fragment key={j}>
                    <div
                      className={orCondition({
                        removable: isRemovable,
                      })}
                    >
                      <div className="grid grid-cols-[1fr_1.5fr_1fr_auto] items-center gap-2">
                        <Select
                          value={condition.target}
                          onChange={(v) =>
                            update(i, j, {
                              ...condition,
                              target: v as never,
                              operator:
                                v !== 'subject' &&
                                v !== 'body' &&
                                condition.operator === 'equal'
                                  ? 'contain'
                                  : condition.operator,
                            })
                          }
                          options={[
                            { value: 'subject', label: '件名が' },
                            { value: 'body', label: '本文が' },
                            { value: 'from', label: 'Fromが' },
                            { value: 'to', label: 'Toが' },
                            { value: 'cc', label: 'Ccが' },
                            { value: 'bcc', label: 'Bccが' },
                          ]}
                          variants={{ rounded: 'lg' }}
                          disabled={disabled}
                          className="h-10 bg-white"
                        />
                        <Input
                          value={condition.filterText}
                          onChange={(e) =>
                            update(i, j, {
                              ...condition,
                              filterText: e.target.value,
                            })
                          }
                          spellCheck={false}
                          disabled={disabled}
                        />
                        <Select
                          value={condition.operator}
                          onChange={(v) =>
                            update(i, j, {
                              ...condition,
                              operator: v as never,
                            })
                          }
                          options={[
                            { value: 'contain', label: 'を含む' },
                            { value: 'notContain', label: 'を含まない' },
                            ...(condition.target === 'subject' ||
                            condition.target === 'body'
                              ? [{ value: 'equal', label: 'と一致する' }]
                              : []),
                          ]}
                          variants={{ rounded: 'lg' }}
                          className="h-10 bg-white"
                          disabled={disabled}
                        />
                        <Tooltip content="大文字/小文字を区別">
                          <div>
                            <Checkbox
                              label="A/a"
                              checked={condition.caseSensitive}
                              onChange={(e) =>
                                update(i, j, {
                                  ...condition,
                                  caseSensitive: e.target.checked,
                                })
                              }
                              disabled={disabled}
                            />
                          </div>
                        </Tooltip>
                      </div>
                      {isRemovable && (
                        <button
                          type="button"
                          className="cursor-pointer bg-transparent p-0 text-sumi-300"
                          onClick={() => onDelete(i, j)}
                          disabled={disabled}
                        >
                          <Icon icon={DoNotDisturbOn} size={24} />
                        </button>
                      )}
                    </div>
                    {j < orConditions.length - 1 && (
                      <div className="w-full select-none text-center text-sm">
                        または
                      </div>
                    )}
                  </Fragment>
                );
              })}
              {!disabled && (
                <div
                  className={
                    value.some((e) => e.length > 1)
                      ? 'pr-[calc(theme(space.2)_+_24px)]'
                      : undefined
                  }
                >
                  <button
                    type="button"
                    className="flex h-8 w-full cursor-pointer items-center justify-center gap-2 rounded-lg border border-sumi-300 bg-transparent bg-white p-0 text-sm hover:bg-sumi-50"
                    onClick={() => addOrCondition(i)}
                    disabled={disabled}
                  >
                    <Icon icon={Plus} size={20} />
                    <span>OR条件を追加する</span>
                  </button>
                </div>
              )}
            </div>
            {i < value.length - 1 && (
              <div className="w-full select-none text-center text-sm">かつ</div>
            )}
          </Fragment>
        );
      })}
      {!disabled && (
        <button
          type="button"
          className="flex h-8 w-full cursor-pointer items-center justify-center gap-2 rounded-lg border border-sumi-300 bg-transparent bg-white p-0 text-sm hover:bg-sumi-50"
          onClick={() => addAndCondition()}
        >
          <Icon icon={Plus} size={20} />
          <span>AND条件を追加する</span>
        </button>
      )}
    </div>
  );
};
