import { Icon } from '../../basics';
import { Plus } from '../../icons';
import React, { ComponentProps, useCallback, useState } from 'react';
import { List } from '../../basics/List/List';
import { ContactDrawerMemo } from '../ContactDrawerMemo/ContactDrawerMemo';
import { range } from 'lodash';
import { ContactDrawerMemoSkeleton } from '../ContactDrawerMemoSkeleton/ContactDrawerMemoSkeleton';
import { MemoEditForm } from '../MemoEditForm/MemoEditForm';
import { Avatar } from '../../basics/Avatar/Avatar';

type Memo = {
  id: string;
  text: string;
  username: string;
  avatar: Omit<ComponentProps<typeof Avatar>, 'size'>;
  timestamp: Date | undefined;
};

type Props = {
  memos: Memo[];
  hasMore: boolean;
  loading: boolean;
  onLoadMore: () => Promise<void>;
  onAdd: (text: string) => Promise<boolean>;
  onEdit: (memoId: string, text: string) => Promise<boolean>;
  onDelete: (memoId: string) => Promise<void>;
};

export const ContactDrawerMemos = ({
  memos,
  hasMore,
  loading,
  onLoadMore,
  onAdd,
  onEdit,
  onDelete,
}: Props) => {
  const [formOpened, setFormOpened] = useState(false);
  const openAddForm = useCallback(() => {
    if (loading) {
      return;
    }
    setFormOpened(true);
  }, [loading]);
  const handleAdd = useCallback(
    async (text: string) => {
      if (await onAdd(text)) {
        setFormOpened(false);
      }
    },
    [onAdd]
  );
  return (
    <div className="flex w-full flex-col gap-3">
      <div className="grid grid-cols-[1fr_auto] gap-2 text-sm">
        <span>メモ</span>
        <button
          type="button"
          className="m-0 flex cursor-pointer items-center gap-1 bg-transparent p-0 text-sea-500"
          onClick={openAddForm}
        >
          <Icon icon={Plus} size="1.1em" />
          <span>追加</span>
        </button>
      </div>
      <List>
        {formOpened && (
          <List.Item>
            <MemoEditForm
              value=""
              onEdit={handleAdd}
              onCancel={setFormOpened}
              autoFocus
            />
          </List.Item>
        )}
        {memos.length === 0 &&
          loading &&
          range(2).map((i) => (
            <List.Item key={i}>
              <ContactDrawerMemoSkeleton />
            </List.Item>
          ))}
        {memos.length === 0 && !loading && !formOpened && (
          <div className="flex h-24 items-center justify-center text-sumi-600">
            メモが存在しません
          </div>
        )}
        {memos.map((memo) => (
          <List.Item key={memo.id}>
            <ContactDrawerMemo
              id={memo.id}
              avatar={memo.avatar}
              username={memo.username}
              text={memo.text}
              timestamp={memo.timestamp}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </List.Item>
        ))}
        {hasMore && memos.length > 0 && (
          <List.Item>
            <button
              type="button"
              onClick={onLoadMore}
              className="m-0 flex h-6 cursor-pointer items-center bg-transparent p-0 text-sm text-sea-500 hover:underline"
              disabled={loading}
            >
              もっと見る
            </button>
          </List.Item>
        )}
      </List>
    </div>
  );
};
