import { RecordType } from 'store/salesforce';
import { ReactNode } from 'react';
import { Icon } from '../../../components/basics';
import { OpenInNew } from '../../../components/icons';

interface Props {
  classic: boolean;
  instanceUrl: string;
  record: RecordType;
  id: string;
  children?: ReactNode;
}

export const SalesforceLink = ({
  classic,
  instanceUrl,
  record,
  id,
  children,
}: Props): JSX.Element => {
  children = children || (
    <Icon
      icon={OpenInNew}
      style={{ height: '1rem' }}
      className="text-[#545454]"
    />
  );
  const url = classic
    ? `${instanceUrl}/${id}`
    : `${instanceUrl}/lightning/r/${record}/${id}/view`;
  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
