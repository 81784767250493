import { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ComponentPropsWithoutRef<'div'>;

type ListComposition = {
  Item: typeof Item;
};

export const List: FC<Props> & ListComposition = ({
  className,
  children,
  ...props
}) => {
  return (
    <div className={twMerge('flex flex-col text-sm', className)} {...props}>
      {children}
    </div>
  );
};

type ItemProps = ComponentPropsWithoutRef<'div'>;

const Item: FC<ItemProps> = ({ className, children, ...props }) => {
  return (
    <div
      className={twMerge(
        "relative py-3 before:absolute before:inset-x-2 before:bottom-0 before:h-[1px] before:bg-sumi-300 before:content-[''] first:pt-0 last:pb-0 last:before:hidden",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

List.Item = Item;
