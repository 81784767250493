import { Avatar } from '../../basics/Avatar/Avatar';
import React, {
  ComponentProps,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { Tooltip } from '../../basics/Tooltip/Tooltip';
import Linkify from 'react-linkify';
import reactStringReplace from 'react-string-replace';
import { escapeRegExp } from 'lodash';
import { tv } from 'tailwind-variants';
import { Button, Icon } from '../../basics';
import { AddReaction, Delete, Edit, Reply } from '../../icons';
import { twMerge } from 'tailwind-merge';
import Picker from '@emoji-mart/react';
import * as Popover from '@radix-ui/react-popover';
import data from '@emoji-mart/data/sets/15/google.json';
import i18n from '@emoji-mart/data/i18n/ja.json';
import { Emoji as EmojiMartEmoji } from '@emoji-mart/data';
import { Dialog } from '../../basics/dialog/Dialog';
import { DialogHeader } from '../../basics/dialog/DialogHeader';
import { DialogFooter } from '../../basics/dialog/DialogFooter';
import { DialogContent } from '../../basics/dialog/DialogContent';
import * as RadixDialog from '@radix-ui/react-dialog';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import TextareaAutosize from 'react-textarea-autosize';
import { CommentAttachment } from '../CommentAttachment/CommentAttachment';
import SimpleBar from 'simplebar-react';
import { shouldSubmit } from '../../../utils/keyboard';
import { PCOrTabletOnly, SPOnly } from '../../../App/Common/MediaQuery';
import { Drawer } from '../../basics/Drawer/Drawer';
import { isSP } from '../../../shared/util';
import styles from './Comment.module.css';
import * as HoverCard from '@radix-ui/react-hover-card';
import { Reaction } from '../Reaction/Reaction';
import { AddReactionButton } from '../AddReactionButton/AddReactionButton';

type Attachment = {
  id: string;
  name: string;
};

type Props = {
  id: string;
  user: NonNullable<ComponentProps<typeof Avatar>['user']>;
  content: string;
  reply?:
    | 'loading'
    | 'deleted'
    | {
        name: string;
        content: string;
        mentionedUsers: string[];
      };
  reactions?: Record<string, { id: string; name: string }[]>;
  timestamp: Date;
  mentionTargets: string[];
  attachments?: Attachment[];
  onRemoveAttachment?: (attachmentId: string) => Promise<void>;
  onDownloadAttachment?: (attachmentId: string) => void;
  currentUser?: {
    id: string;
    name: string;
  };
  highlight?: boolean;
  onReply: (() => void) | undefined;
  onToggleEmoji: ((emoji: string) => void) | undefined;
  onEditContent: ((content: string) => Promise<void>) | undefined;
  onDelete: (() => Promise<boolean>) | undefined;
  reactionSupportStatus: 'loading' | 'supported' | 'unsupported';
  replySupportStatus: 'loading' | 'supported' | 'unsupported';
  isAuthor: boolean;
  readonly?: boolean;
  isEdited?: boolean;
  collisionBoundary?: Element | null;
  noDeleteButton?: boolean;
};

const wrapper = tv({
  base: 'relative z-[1] grid w-full grid-cols-[auto_1fr] flex-col items-start gap-2 text-sumi-900',
  variants: {
    highlight: {
      true: twMerge(
        "before:pointer-events-none before:absolute before:-inset-2 before:z-[-1] before:rounded-[calc(theme(borderRadius.lg)_+_theme(spacing.2)_/_2)] before:bg-sea-100 before:content-['']",
        'before:animate-comment-highlight'
      ),
    },
    deleting: {
      true: 'opacity-50',
    },
  },
});

const bubble = tv({
  base: 'w-fit whitespace-pre-line break-all rounded-lg rounded-tl-md bg-white px-2 py-1.5 text-sm leading-6',
});

const toolbarButton = tv({
  base: 'm-0 flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg bg-transparent p-0 enabled:hover:bg-sumi-100 disabled:opacity-50',
});

export const Comment = memo(function Comment({
  id,
  user,
  content,
  reply,
  reactions = {},
  timestamp,
  mentionTargets,
  currentUser,
  highlight = false,
  attachments = [],
  onRemoveAttachment,
  onDownloadAttachment,
  onReply,
  onToggleEmoji,
  onEditContent,
  onDelete,
  reactionSupportStatus,
  replySupportStatus,
  isAuthor = false,
  readonly = false,
  isEdited = false,
  collisionBoundary,
  noDeleteButton,
}: Props) {
  const editTextareaRef = useRef<HTMLTextAreaElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [toolbarEmojiPickerOpen, setToolbarEmojiPickerOpen] = useState(false);
  const [inlineEmojiPickerOpen, setInlineEmojiPickerOpen] = useState(false);
  const [mobileEmojiPickerOpen, setMobileEmojiPickerOpen] = useState(false);
  const [forceToolbarOpen, setForceToolbarOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editingContent, setEditingContent] = useState('');
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [mountDrawer, setMountDrawer] = useState(false);
  const confirm = useConfirmDialog();

  const onDownload = useCallback(
    (attachmentId: string) => onDownloadAttachment?.(attachmentId),
    [onDownloadAttachment]
  );

  const onRemove = useCallback(
    (attachmentId: string) => {
      if (!isAuthor || !onRemoveAttachment) {
        return;
      }
      const found = attachments.find((a) => a.id === attachmentId);
      if (!found) {
        return;
      }

      confirm({
        title: `添付ファイルを削除しますか？`,
        description: (
          <>
            "{found.name}" を削除しますか？
            <br />
            一度削除すると元に戻せません
          </>
        ),
        okText: '削除',
        okType: 'danger',
        onOk: () => onRemoveAttachment(attachmentId),
      });
    },
    [isAuthor, attachments, confirm, onRemoveAttachment]
  );

  const handleDelete = useCallback(() => {
    setShowDeleteDialog(false);
    if (!onDelete) {
      return;
    }
    setDeleting(true);
    (async () => {
      try {
        if (await onDelete()) {
          return;
        }
        setDeleting(false);
      } catch (e) {
        console.error(e);
        setDeleting(false);
      }
    })();
  }, [onDelete]);

  const openMobilePicker = useCallback(
    () => setMobileEmojiPickerOpen(true),
    []
  );

  const handleOpenHoverCard = useCallback(() => {
    if (isSP()) {
      setMountDrawer(true);
    }
  }, []);

  const date = useMemo(() => {
    const date = moment(timestamp);
    return (
      <Tooltip content={date.format('YYYY年M月D日 HH:mm')}>
        <div className="text-sumi-600">{date.fromNow()}</div>
      </Tooltip>
    );
  }, [timestamp]);
  const replyComment = useMemo(() => {
    if (!reply) {
      return null;
    }
    if (reply === 'loading') {
      return (
        <div className="flex flex-col gap-2">
          <div className="h-4 w-32 animate-pulse rounded bg-sumi-100" />
          <div className="grid grid-cols-[auto_1fr] gap-1">
            <div className="h-full w-[4px] rounded-full bg-sumi-300" />
            <div className="h-[28px] w-48 animate-pulse rounded-lg bg-sumi-100" />
          </div>
        </div>
      );
    }
    if (reply === 'deleted') {
      return (
        <div className="flex flex-col gap-2">
          <div className="text-xs text-sumi-600">コメントに返信しました</div>
          <div className="grid grid-cols-[auto_1fr] gap-1">
            <div className="h-full w-[4px] rounded-full bg-sumi-300" />
            <div className={bubble({ className: 'text-xs text-sumi-600' })}>
              削除されたコメント
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="flex flex-col gap-2">
        <div className="text-xs text-sumi-600">
          {reply.name}さんに返信しました
        </div>
        <div className="grid grid-cols-[auto_1fr] gap-1">
          <div className="h-full w-[4px] rounded-full bg-sumi-300" />
          <div className={bubble({ className: 'text-xs' })}>
            {highlightMentions(
              reply.content,
              mentionTargets,
              currentUser?.name
            )}
          </div>
        </div>
      </div>
    );
  }, [reply]);
  const isMobile = isSP();
  const picker = useMemo(
    () => (
      <Picker
        data={data}
        i18n={i18n}
        theme="light"
        set="google"
        dynamicWidth={isMobile}
        emojiButtonSize={isMobile ? 48 : undefined}
        emojiSize={isMobile ? 36 : undefined}
        onEmojiSelect={(
          emoji: EmojiMartEmoji & { shortcodes: string },
          e: PointerEvent
        ) => {
          onToggleEmoji?.(emoji.shortcodes);
          if (!e.shiftKey) {
            setToolbarEmojiPickerOpen(false);
            setInlineEmojiPickerOpen(false);
            setMobileEmojiPickerOpen(false);
          }
        }}
      />
    ),
    [onToggleEmoji, isMobile]
  );

  const comment = useMemo(() => {
    return (
      <Linkify componentDecorator={componentDecorator}>
        {highlightMentions(content, mentionTargets, currentUser?.name)}
      </Linkify>
    );
  }, [content, mentionTargets, currentUser?.name]);

  const reactionEntries = useMemo(
    () =>
      Object.entries(reactions)
        .map((e) => ({ emoji: e[0], count: e[1].length, users: e[1] }))
        .sort((a, b) => b.count - a.count),
    [reactions]
  );

  const reactionElements = useMemo(() => {
    return reactionEntries.map((e) => (
      <Reaction
        key={e.emoji}
        emoji={e.emoji}
        count={e.count}
        onToggle={onToggleEmoji}
        reactedUsers={e.users
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name, 'ja'))}
        reacted={
          currentUser != null && e.users.some((u) => u.id === currentUser.id)
        }
        readonly={readonly || reactionSupportStatus !== 'supported'}
        disabled={deleting}
      />
    ));
  }, [reactionEntries, currentUser?.id, readonly, reactionSupportStatus]);

  // タッチデバイス用
  useEffect(() => {
    const onTouchStart = (e: TouchEvent) => {
      if (e.target === contentRef.current) {
        setForceToolbarOpen(true);
      } else {
        // ツールバーをタッチしてすぐ非表示になりボタンを押せないのを防ぐために遅延をいれる
        setTimeout(() => setForceToolbarOpen(false), 200);
      }
    };
    document.addEventListener('touchstart', onTouchStart);
    return () => document.removeEventListener('touchstart', onTouchStart);
  }, []);

  const reactionTooltipContent =
    reactionSupportStatus === 'supported'
      ? 'リアクション'
      : 'ビジネスプランでリアクションを利用できます';

  return (
    <>
      <HoverCard.Root
        openDelay={0}
        closeDelay={0}
        open={forceToolbarOpen || toolbarEmojiPickerOpen ? true : undefined}
        onOpenChange={handleOpenHoverCard}
      >
        <div
          className={wrapper({ highlight, deleting })}
          {...({ name: `comment-${id}` } as any)}
        >
          <Avatar size={36} user={user} />
          <div className="flex w-full flex-col gap-1">
            {replyComment}
            <div className="flex items-center gap-4 text-xs">
              <div className="font-bold">
                {user.name ?? '削除されたユーザー'}
              </div>
              {date}
              {isEdited && <div className="text-sumi-500">(編集済み)</div>}
            </div>
            <div
              className={twMerge(
                'relative min-w-[80px] pr-8',
                editing ? 'w-full' : 'w-fit'
              )}
            >
              {editing ? (
                <form
                  action=""
                  onSubmit={async (e) => {
                    e.preventDefault();
                    setEditLoading(true);
                    try {
                      await onEditContent?.(editingContent);
                      setEditing(false);
                    } catch (e) {
                      console.error(e);
                    } finally {
                      setEditLoading(false);
                    }
                  }}
                  className="grid w-full grid-cols-[1fr_auto_auto] items-end gap-2"
                >
                  <TextareaAutosize
                    className="h-9 resize-none rounded-lg border border-sumi-300 px-2 py-1.5 text-sm leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-sea-200"
                    value={editingContent}
                    onChange={(e) => setEditingContent(e.target.value)}
                    onKeyDown={(e) => {
                      if (shouldSubmit(e)) {
                        (e.target as HTMLElement)
                          .closest('form')
                          ?.requestSubmit();
                      }
                    }}
                    ref={editTextareaRef}
                  />
                  <Button
                    color="primary"
                    variant="text"
                    className="h-9"
                    onClick={() => setEditing(false)}
                    disabled={editLoading}
                    aria-label="コメント編集のキャンセル"
                  >
                    キャンセル
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="h-9"
                    loading={editLoading}
                    aria-label="編集したコメントを保存"
                  >
                    保存
                  </Button>
                </form>
              ) : (
                <HoverCard.Trigger asChild>
                  <div
                    aria-label="ユーザーコメント"
                    className={bubble()}
                    ref={contentRef}
                  >
                    {comment}
                  </div>
                </HoverCard.Trigger>
              )}
              {!editing && !deleting && !readonly && (
                <HoverCard.Content
                  side="top"
                  sideOffset={-16}
                  align="end"
                  alignOffset={isAuthor ? -90 : -40}
                  collisionPadding={4}
                  collisionBoundary={collisionBoundary ?? undefined}
                  data-testid="comment-options"
                >
                  <div className="p-4 pt-2">
                    <div
                      className={twMerge(
                        'flex w-fit flex-nowrap gap-1 rounded-lg border border-sumi-300 bg-white p-1 text-sumi-600',
                        'shadow-comment-toolbar',
                        toolbarEmojiPickerOpen ? 'visible' : ''
                      )}
                    >
                      <PCOrTabletOnly>
                        <Popover.Root
                          open={toolbarEmojiPickerOpen}
                          onOpenChange={setToolbarEmojiPickerOpen}
                        >
                          <Tooltip
                            content={reactionTooltipContent}
                            visible={reactionSupportStatus !== 'loading'}
                          >
                            <Popover.Trigger asChild>
                              <button
                                aria-label="リアクションをつける"
                                type="button"
                                className={toolbarButton()}
                                disabled={reactionSupportStatus !== 'supported'}
                              >
                                <Icon icon={AddReaction} size={20} />
                              </button>
                            </Popover.Trigger>
                          </Tooltip>
                          <Popover.Portal>
                            <Popover.Content
                              align="start"
                              alignOffset={-4}
                              sideOffset={6}
                              className="z-20"
                            >
                              {picker}
                            </Popover.Content>
                          </Popover.Portal>
                        </Popover.Root>
                      </PCOrTabletOnly>
                      <SPOnly>
                        <Tooltip
                          content={reactionTooltipContent}
                          visible={reactionSupportStatus !== 'loading'}
                        >
                          <button
                            aria-label="リアクションをつける"
                            type="button"
                            className={toolbarButton()}
                            disabled={reactionSupportStatus !== 'supported'}
                            onClick={openMobilePicker}
                          >
                            <Icon icon={AddReaction} size={20} />
                          </button>
                        </Tooltip>
                      </SPOnly>
                      <Tooltip
                        content={
                          replySupportStatus === 'supported'
                            ? '返信'
                            : 'ビジネスプランで返信を利用できます'
                        }
                        visible={replySupportStatus !== 'loading'}
                      >
                        <button
                          aria-label="コメントに返信する"
                          type="button"
                          data-testid="reply-comment"
                          className={toolbarButton()}
                          onClick={() => onReply?.()}
                          disabled={replySupportStatus !== 'supported'}
                        >
                          <Icon icon={Reply} size={20} />
                        </button>
                      </Tooltip>
                      {isAuthor && (
                        <>
                          <Tooltip content="編集">
                            <button
                              aria-label="コメントを編集する"
                              type="button"
                              data-testid="edit-comment"
                              className={toolbarButton()}
                              onClick={() => {
                                setEditing(true);
                                setEditingContent(content);
                                setTimeout(() => {
                                  const textarea = editTextareaRef.current;
                                  if (!textarea) {
                                    return;
                                  }
                                  textarea.focus();
                                  textarea.setSelectionRange(
                                    textarea.value.length,
                                    textarea.value.length
                                  );
                                });
                              }}
                            >
                              <Icon icon={Edit} size={20} />
                            </button>
                          </Tooltip>
                          {!noDeleteButton && (
                            <Tooltip content="削除">
                              <button
                                aria-label="コメントを削除する"
                                type="button"
                                data-testid="delete-comment"
                                className={toolbarButton({
                                  className: 'hover:text-sun-500',
                                })}
                                onClick={() => setShowDeleteDialog(true)}
                              >
                                <Icon icon={Delete} size={20} />
                              </button>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </HoverCard.Content>
              )}
            </div>
            {attachments.length > 0 && (
              <div className="grid w-full grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
                {attachments?.map((attachment) => (
                  <CommentAttachment
                    key={attachment.id}
                    id={attachment.id}
                    name={attachment.name}
                    onDownload={onDownload}
                    onRemove={onRemove}
                  />
                ))}
              </div>
            )}
            {reactionEntries.length > 0 && (
              <div className="flex flex-wrap gap-1">
                {reactionElements}
                {!readonly && reactionSupportStatus === 'supported' && (
                  <>
                    <PCOrTabletOnly>
                      <Popover.Root
                        open={inlineEmojiPickerOpen}
                        onOpenChange={setInlineEmojiPickerOpen}
                      >
                        <Popover.Trigger asChild>
                          <AddReactionButton disabled={deleting} />
                        </Popover.Trigger>
                        <Popover.Portal>
                          <Popover.Content
                            align="start"
                            side="right"
                            sideOffset={6}
                          >
                            {picker}
                          </Popover.Content>
                        </Popover.Portal>
                      </Popover.Root>
                    </PCOrTabletOnly>
                    <SPOnly>
                      <AddReactionButton
                        onClick={openMobilePicker}
                        disabled={deleting}
                      />
                    </SPOnly>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </HoverCard.Root>
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogHeader title="コメントを削除しますか？" />
        <DialogContent className="flex flex-col gap-4">
          <div>
            一度削除すると元に戻せません。他の人も確認できなくなります。
          </div>
          <SimpleBar className="max-h-[300px]">
            <div className="rounded-lg border border-sumi-200 bg-sumi-50 p-4">
              <Comment
                id={id}
                user={user}
                content={content}
                timestamp={timestamp}
                reply={reply}
                reactions={reactions}
                mentionTargets={mentionTargets}
                currentUser={currentUser}
                onReply={undefined}
                onToggleEmoji={undefined}
                onEditContent={undefined}
                onDelete={undefined}
                isAuthor={false}
                reactionSupportStatus={reactionSupportStatus}
                replySupportStatus={replySupportStatus}
                readonly
              />
            </div>
          </SimpleBar>
        </DialogContent>
        <DialogFooter>
          <RadixDialog.Close asChild>
            <Button variant="outlined">キャンセル</Button>
          </RadixDialog.Close>
          <Button
            data-testid="delete-comment-dialog-button"
            color="danger"
            onClick={handleDelete}
          >
            削除
          </Button>
        </DialogFooter>
      </Dialog>
      <SPOnly>
        {mountDrawer && (
          <Drawer
            key="mobile-picker"
            placement="bottom"
            open={mobileEmojiPickerOpen}
            onOpenChange={setMobileEmojiPickerOpen}
            className={twMerge(styles.mobileEmojiPickerWrapper, 'h-[435px]')}
            disableScroll={false}
          >
            {mobileEmojiPickerOpen && picker}
          </Drawer>
        )}
      </SPOnly>
    </>
  );
});

const componentDecorator = (href: string, text: string, key: number) => (
  <a
    href={href}
    key={key}
    className="text-sea-500 hover:underline"
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
);

export const highlightMentions = (
  text: string,
  targets: string[],
  me: string | undefined
) => {
  const otherMentions = [
    '@all',
    ...targets
      .sort((a, b) => b.length - a.length)
      .filter((t) => t !== me)
      .map((t) => '@' + escapeRegExp(t)),
  ].join('|');
  const regex = new RegExp(`(${otherMentions})`, 'g');
  let replaced = reactStringReplace(text, regex, (match, i) => (
    <span className="font-bold text-sea-500" key={i}>
      {match}
    </span>
  ));

  if (me) {
    replaced = reactStringReplace(
      replaced,
      new RegExp(`(@${escapeRegExp(me)})`, 'g'),
      (match, i) => (
        <span className="font-bold text-[#f19023]" key={`me-${i}`}>
          {match}
        </span>
      )
    );
  }

  return replaced;
};
