import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.996 19a.731.731 0 0 1-.746-.75v-5.5h-5.5a.723.723 0 0 1-.534-.217.732.732 0 0 1-.216-.537.731.731 0 0 1 .75-.746h5.5v-5.5c0-.213.072-.39.217-.534A.732.732 0 0 1 12.004 5a.731.731 0 0 1 .746.75v5.5h5.5c.212 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746h-5.5v5.5c0 .212-.072.39-.217.534a.732.732 0 0 1-.537.216Z"
    />
  </svg>
);
export default SvgPlus;
