import * as React from 'react';
import type { SVGProps } from 'react';
const SvgForum = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.025 18c-.233 0-.463-.117-.688-.35-.224-.233-.337-.467-.337-.7V14.5h12.5V6H21c.233 0 .458.117.675.35.217.233.325.475.325.725v13.1c0 .333-.154.563-.462.688-.309.125-.58.07-.813-.163l-2.7-2.7h-11ZM6 13l-2.725 2.725c-.233.233-.504.287-.813.162C2.155 15.762 2 15.534 2 15.2V3.05c0-.233.108-.467.325-.7C2.542 2.117 2.767 2 3 2h12.975c.25 0 .483.112.7.337.217.225.325.463.325.713v8.9c0 .233-.108.467-.325.7-.217.233-.45.35-.7.35H6Zm9.5-1.5v-8h-12v8h12Z"
    />
  </svg>
);
export default SvgForum;
