import React, {
  ReactElement,
  RefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon } from '../../basics';
import { Close } from '../../icons';
import { v4 } from 'uuid';
import { useWindowEvent } from '../../../hooks/useWindowEvent';
import { clamp, isEqual, throttle } from 'lodash';
import { tv } from 'tailwind-variants';

type Entry = {
  value: string;
  text: string;
  disabled?: boolean;
};

type GroupEntry = {
  group: string;
  entries: Entry[];
};

type Props = {
  open?: boolean;
  value: string[];
  onChange: (value: string[]) => void;
  suggests: string[] | Entry[] | GroupEntry[];
  validate?: (value: string) => boolean;
  addable?: boolean;
  disabled?: boolean;
  renderItem?: (state: RenderState) => ReactElement;
  placeholder?: string;
  closeWhenSelect?: boolean;
  inputRef?: RefObject<HTMLInputElement | undefined | null>;
};

type DropdownState = {
  width: number;
  top: number;
};

type RenderState = {
  entry: Entry;
  closeButton: boolean;
  preDelete: boolean;
  newItem: boolean;
  disabled: boolean;
  remove: () => void;
};

const inputWrapper = tv({
  base: 'min-h-[theme(height.10)] cursor-text rounded-lg border border-sumi-300 bg-white px-2 py-1.5',
  variants: {
    disabled: {
      true: 'cursor-not-allowed text-sumi-500',
    },
  },
});

const tag = tv({
  base: 'grid h-6 grid-cols-[1fr_auto] items-center rounded-lg border border-sumi-200 bg-sumi-50',
  variants: {
    disabled: {
      true: 'px-2',
      false: 'pl-2',
    },
    preDelete: {
      true: 'border-sun-200 bg-sun-100',
    },
  },
});

const suggest = tv({
  base: 'w-full cursor-pointer select-none truncate whitespace-nowrap rounded-lg bg-transparent p-0 text-start text-base',
  variants: {
    selected: {
      true: 'bg-sumi-100',
    },
    customRenderer: {
      true: '',
      false: 'min-h-[theme(height.8)] px-2',
    },
  },
});

export const MultipleInput = ({
  open,
  value,
  onChange,
  suggests,
  validate,
  addable = false,
  disabled = false,
  renderItem,
  placeholder,
  closeWhenSelect,
  inputRef: externalInputRef,
}: Props) => {
  const inputId = useMemo(() => `multiInput_${v4()}`, []);
  const portalId = useMemo(() => `multiInput_${v4()}`, []);
  const [inputValue, setInputValue] = useState('');
  const [inputWidth, setInputWidth] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mirrorRef = useRef<HTMLDivElement>(null);
  const openedAtRef = useRef(0);
  const [hasFocus, setHasFocus] = useState(false);
  const [dropdownState, setDropdownState] = useState<DropdownState>({
    width: 0,
    top: 0,
  });
  const [preDelete, setPreDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    const mirrorElement = mirrorRef.current;
    if (!mirrorElement) {
      return;
    }
    setInputWidth(mirrorElement.clientWidth);
  }, [inputValue]);

  useImperativeHandle(externalInputRef, () => inputRef.current);

  const isAddable = (target: Entry): boolean => {
    if (value.includes(target.value)) {
      return false;
    }

    if (addable) {
      return true;
    }

    if (suggests.length === 0) {
      return false;
    }

    switch (checkSuggestsType(suggests)) {
      case 'string-array':
        return (suggests as string[]).includes(target.value);
      case 'entry-array':
        return (suggests as Entry[]).some((s) => s.value === target.value);
      case 'group-array':
        return (suggests as GroupEntry[]).some((g) =>
          g.entries.some((e) => e.value === target.value)
        );
    }
  };
  const onSubmitInput = (target: Entry, shiftKey = false) => {
    inputRef.current?.focus();

    if (!isAddable(target)) {
      return;
    }

    if (validate && !validate(target.value)) {
      return;
    }

    onChange([...value, target.value]);
    setInputValue('');

    if (closeWhenSelect && !shiftKey) {
      setHasFocus(false);
    }
  };
  const onDelete = (index: number) => {
    const newValue = value.filter((_e, i) => i !== index);
    onChange(newValue);
    inputRef.current?.focus();
  };

  const updateDropdownState = () => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) {
      return;
    }

    const rect = wrapperElement.getBoundingClientRect();
    setDropdownState({
      width: rect.width,
      top: rect.height,
    });
  };

  useEffect(() => {
    const wrapperElement = wrapperRef.current;
    if (!wrapperElement) {
      return;
    }

    updateDropdownState(); // 初期化

    const update = () => {
      updateDropdownState();
    };

    const throttledUpdate = throttle(updateDropdownState, 100);
    window.addEventListener('scroll', update);
    const observer = new ResizeObserver(() => {
      throttledUpdate();
    });
    observer.observe(wrapperElement);
    return () => {
      window.removeEventListener('scroll', update);
      observer.disconnect();
    };
  }, []);

  useWindowEvent('click', (e) => {
    if (Date.now() - openedAtRef.current < 100) {
      return;
    }

    const target = e.target;
    if (!(target instanceof Element)) {
      return;
    }
    if (!hasFocus) {
      return;
    }

    if (!target.closest(`#${inputId}`) && !target.closest(`#${portalId}`)) {
      setHasFocus(false);
    }
  });

  const convertedSuggests = convertSuggests(suggests);
  let filteredSuggests = convertedSuggests
    .map(
      (s): GroupEntry => ({
        ...s,
        entries: s.entries.filter(
          (e) =>
            isAddable(e) &&
            e.text.toLowerCase().startsWith(inputValue.toLowerCase())
        ),
      })
    )
    .filter((s) => s.entries.length > 0);
  const isValueFound = convertedSuggests.some((group) =>
    group.entries.some((e) => !e.disabled && e.text === inputValue)
  );
  if (!isValueFound && inputValue && addable) {
    filteredSuggests = [
      {
        group: '',
        entries: [{ value: inputValue, text: inputValue }],
      },
      ...filteredSuggests,
    ];
  }
  const filteredFlatEntries = filteredSuggests.flatMap((g) => g.entries);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value.trim();

    if (e.key === 'Backspace' && inputValue.length === 0) {
      if (preDelete && !e.repeat) {
        onDelete(value.length - 1);
        setPreDelete(false);
      } else {
        setPreDelete(true);
      }
    } else {
      setPreDelete(false);
    }

    if (e.nativeEvent.isComposing || e.key === 'Process') {
      return;
    }

    if (e.key === ' ') {
      e.preventDefault();
      if (inputValue.length > 0) {
        onSubmitInput({ value: inputValue, text: inputValue }, e.shiftKey);
      }
      return;
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      const selected = filteredFlatEntries.at(selectedIndex);
      if (selected) {
        onSubmitInput(selected, e.shiftKey);
      }
      return;
    }

    let direction = 0;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      direction = -1;
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      direction = 1;
    }
    if (direction !== 0) {
      const newIndex = clamp(
        selectedIndex + direction,
        0,
        filteredFlatEntries.length - 1
      );
      setSelectedIndex(newIndex);
    }
  };

  return (
    <div className="relative">
      <div
        id={inputId}
        className={inputWrapper({ disabled })}
        onClick={(e) => {
          e.stopPropagation();
          inputRef.current?.focus();
        }}
        ref={wrapperRef}
        data-testid="multiinput"
      >
        <div
          className="relative flex min-h-[theme(height.6)] flex-wrap items-center gap-2"
          data-testid="multiinput-values"
        >
          {value.length <= 0 && placeholder && !inputValue && (
            <div className="pointer-events-none absolute left-0 select-none text-sumi-500">
              {placeholder}
            </div>
          )}
          {value.map((v, i) => {
            const found = convertSuggests(suggests)
              .flatMap((e) => e.entries)
              .find((s) => s.value === v);
            let label = (
              <div
                className={tag({
                  disabled,
                  preDelete: preDelete && i === value.length - 1,
                })}
              >
                <span className="select-none overflow-hidden truncate whitespace-nowrap leading-4">
                  {found?.text ?? v}
                </span>
                <button
                  type="button"
                  className="flex h-6 w-6 cursor-pointer items-center justify-center bg-transparent p-0"
                  onClick={() => onDelete(i)}
                  data-testid="multiinput-delete-button"
                  disabled={disabled}
                >
                  <Icon icon={Close} size={16} />
                </button>
              </div>
            );
            if (renderItem) {
              label = renderItem({
                entry: found ?? { value: v, text: v },
                closeButton: true,
                preDelete: preDelete && i === value.length - 1,
                newItem: false,
                disabled,
                remove: () => onDelete(i),
              });
            }
            return (
              <div key={i} onClick={(e) => e.stopPropagation()}>
                {label}
              </div>
            );
          })}
          <input
            className="block bg-transparent p-0 text-sm outline-none"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{ width: Math.max(inputWidth, 2) }}
            ref={inputRef}
            onKeyDown={onKeyDown}
            onFocus={() => {
              setHasFocus(true);
              updateDropdownState();
              openedAtRef.current = Date.now();
            }}
            spellCheck={false}
            disabled={disabled}
          />
        </div>
        <div className="invisible relative h-0 w-0 overflow-hidden">
          <div className="absolute whitespace-nowrap text-sm" ref={mirrorRef}>
            {inputValue}
          </div>
        </div>
      </div>
      {(hasFocus || open) && (
        <div
          id={portalId}
          className="pointer-events-auto absolute left-0 z-[50000] flex max-h-[25dvh] flex-col overflow-auto rounded-lg bg-white p-2 shadow-dropdown"
          style={{
            width: dropdownState.width,
            top: dropdownState.top,
          }}
        >
          {filteredSuggests.length <= 0 && (
            <div className="text-center text-sumi-500">データがありません</div>
          )}
          {filteredSuggests.map(({ group, entries }, i) => (
            <div key={i}>
              {group.length > 0 && (
                <div className="mb-1 mt-2 select-none pl-2 text-xs text-sumi-500">
                  {group}
                </div>
              )}
              {entries.map((entry, j) => {
                const index = filteredFlatEntries.indexOf(entry);
                return (
                  <button
                    type="button"
                    key={j}
                    className={suggest({
                      selected: selectedIndex === index,
                      customRenderer: renderItem != null,
                    })}
                    onClick={(e) => onSubmitInput(entry, e.shiftKey)}
                    onPointerEnter={() => setSelectedIndex(index)}
                    data-selected={selectedIndex === index}
                    disabled={entry.disabled}
                  >
                    {renderItem
                      ? renderItem({
                          entry: entry,
                          closeButton: false,
                          preDelete: false,
                          newItem:
                            !isValueFound && !!inputValue && addable && i === 0,
                          disabled,
                          remove: () => {
                            //
                          },
                        })
                      : entry.text}
                  </button>
                );
              })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const checkSuggestsType = (
  suggests: string[] | Entry[] | GroupEntry[]
): 'string-array' | 'entry-array' | 'group-array' => {
  if (suggests.length === 0) {
    return 'group-array';
  } else if (typeof suggests[0] === 'string') {
    return 'string-array';
  } else if (isEqual(Object.keys(suggests[0]), ['value', 'text'])) {
    return 'entry-array';
  } else {
    return 'group-array';
  }
};

const convertSuggests = (
  suggests: string[] | Entry[] | GroupEntry[]
): GroupEntry[] => {
  switch (checkSuggestsType(suggests)) {
    case 'string-array': {
      const entries = (suggests as string[]).map((s) => ({
        value: s,
        text: s,
      }));
      return [{ group: '', entries }];
    }
    case 'entry-array': {
      const entries = suggests as Entry[];
      return [{ group: '', entries }];
    }
    case 'group-array': {
      return suggests as GroupEntry[];
    }
  }
};
