import { Comment } from 'lib';
import React, { memo } from 'react';
import Avatar from '../../../../../Common/Avatar';
import { highlightMention } from '../../../../../../util';
import { useAtomValue } from 'jotai';
import { usersAtom } from '../../../../../../atoms/firestore/user';
import { groupsAtom } from '../../../../../../atoms/firestore/group';

type Props = {
  comment: Comment | undefined;
};

export const MessageLatestComment = memo(function MessageLatestComment({
  comment,
}: Props) {
  const users = useAtomValue(usersAtom);
  const groups = useAtomValue(groupsAtom);

  if (!comment) {
    return null;
  }

  const commenter = users.find((u) => u.id === comment?.commenter);
  return (
    <div className="mt-[8px] flex items-center" aria-label="最新メールコメント">
      <div>
        <Avatar size="small" user={commenter} />
      </div>
      <div className="ml-[8px] overflow-hidden text-ellipsis whitespace-nowrap rounded-[8px] bg-[#f8f8fb] px-[12px] py-[8px] text-[12px]">
        {highlightMention(comment.text, users, groups)}
      </div>
    </div>
  );
});
