import { PCTooltip } from 'App/Common/Tooltip';
import { Icon, IconButton } from 'components/basics';
import {
  ChatGradient,
  Description,
  ThumbDownGradient,
  ThumbUpGradient,
  YaritoriAiGradient,
} from 'components/icons';
import { Link } from 'react-router-dom';
import { isSP } from 'shared/util';

export enum TopbarItem {
  ShowTemplateModal,
  ShowTextCompletionModal,
  PositiveCompletion,
  NegativeCompletion,
}

type Props = {
  isReply: boolean;
  onClickTopbar: (topbarItem: TopbarItem) => void;
  submitting: boolean;
  isYaritoriAISupported: boolean;
};

const CreateMessageTopBar = ({
  isReply,
  onClickTopbar,
  submitting,
  isYaritoriAISupported,
}: Props) => {
  return (
    <div className="-mx-4 flex h-11 items-center justify-between border-b border-[#cccccc]/60 px-4">
      <button
        onClick={() => onClickTopbar(TopbarItem.ShowTemplateModal)}
        className="flex h-8 cursor-pointer select-none items-center gap-1 rounded bg-transparent hover:bg-sumi-100"
      >
        <Icon icon={Description} className="h-5 w-5" />
        テンプレートから作成
      </button>

      <div className="flex items-center">
        {!isYaritoriAISupported && !isSP() && (
          <>
            <Icon icon={YaritoriAiGradient} size={24} className="mr-1 block" />
            <PCTooltip
              title={
                <Link className="text-white" to="/settings/company/payments">
                  AI翻訳や文章生成を使用するにはプランをアップデートしてください。
                </Link>
              }
            >
              <IconButton
                component={ChatGradient}
                size="lg"
                disabled={submitting}
              />
            </PCTooltip>
          </>
        )}
        {isYaritoriAISupported && (
          <>
            <Icon icon={YaritoriAiGradient} size={24} className="mr-1 block" />
            <PCTooltip title="yaritori AI">
              <IconButton
                onClick={() =>
                  onClickTopbar(TopbarItem.ShowTextCompletionModal)
                }
                component={ChatGradient}
                size="lg"
                disabled={submitting}
              />
            </PCTooltip>
          </>
        )}
        {isYaritoriAISupported && isReply && (
          <>
            <PCTooltip title="ポジティブに返信">
              <IconButton
                onClick={() => onClickTopbar(TopbarItem.PositiveCompletion)}
                component={ThumbUpGradient}
                size="lg"
                disabled={submitting}
              />
            </PCTooltip>
            <PCTooltip title="ネガティブに返信">
              <IconButton
                onClick={() => onClickTopbar(TopbarItem.NegativeCompletion)}
                component={ThumbDownGradient}
                size="lg"
                disabled={submitting}
              />
            </PCTooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateMessageTopBar;
