import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Heading } from 'components/basics/Heading/Heading';
import { Comment } from '../../Common/Comment';
import { DefaultInput } from '../../Common/Input';
import { DefaultButton } from '../../Common/Button';
import Tooltip from '../../Common/Tooltip';
import { db } from '../../../firebase';
import CIDRForm from './CIDRForm';
import { deleteDoc, setDoc, doc, getDoc } from 'firebase/firestore';
import store from 'store';
import ipaddr from 'ipaddr.js';

export type IPLimitedProps = RouteComponentProps;

export interface IPLimitedSettingData {
  allowCIDRList?: { cidr: string }[];
}

const IPLimited: React.FC<IPLimitedProps> = (props) => {
  const companyId = store.signInCompany;
  const docPath = `/companies/${companyId}/settings/ipLimited`;
  const isMounted = useRef<boolean>(false);
  const isAdmin = store.me.isAdmin;
  const [input, setInput] = useState('');

  const [ipLimitedData, setIpLimitedData] = useState<
    IPLimitedSettingData | undefined
  >();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    getDoc(doc(db, docPath)).then((snap) => {
      if (!snap.exists) {
        return;
      }
      const data = snap.data();
      setIpLimitedData(data);
    });
  }, [docPath]);

  if (!isAdmin) {
    return (
      <div
        className={`z-0 max-w-full flex-1 overflow-auto p-[32px_16px_32px_32px]`}
      >
        <Heading level={1}>IPアドレス制限</Heading>

        <div className={`mb-[50px]`}>管理者のみの機能です。</div>
      </div>
    );
  }

  return (
    <div
      className={`z-0 max-w-full flex-1 overflow-auto p-[32px_16px_32px_32px]`}
    >
      <Heading level={1}>IPアドレス制限</Heading>

      <div className={`mb-[50px]`}>
        <Heading level={2}>CIDRを登録する</Heading>
        <p>重要: 誤ったCIDRを入力し保存した場合ログインができなくなります。</p>
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            try {
              ipaddr.parseCIDR(input);
            } catch (error) {
              return;
            }
            const ref = db.doc(docPath);
            const data: { cidr: string }[] = [];
            data.push(...(ipLimitedData?.allowCIDRList ?? []));
            if (data.find(({ cidr }) => cidr === input)) {
              return;
            }
            data.push({ cidr: input });
            const doc = {
              ...ipLimitedData,
              allowCIDRList: data,
            };
            await setDoc<IPLimitedSettingData>(ref, doc);
            if (isMounted.current) {
              setIpLimitedData(doc);
              setInput('');
            }
          }}
        >
          <div style={{ display: 'flex' }}>
            <Tooltip title="権限がありません" visible={!isAdmin}>
              <DefaultInput
                className={`mb-[10px] h-[40px] w-[400px]`}
                value={input}
                onChange={(event) => {
                  setInput(event.target.value);
                }}
                placeholder="34.0.0.0/15"
                disabled={!isAdmin}
                required
              />
            </Tooltip>
            <Tooltip title={'権限がありません'} visible={!isAdmin}>
              <div>
                <DefaultButton
                  className={`ml-[12.5px] h-[40px] w-[100px] max-w-[100px]`}
                  type="primary"
                  disabled={!isAdmin}
                >
                  追加
                </DefaultButton>
              </div>
            </Tooltip>
          </div>
        </form>
        <Comment>
          例：全てのIPアドレスを許可「0.0.0.0/0」、IPv6の特定の範囲を許可する「2404:f340::/32」
        </Comment>
      </div>
      <div className={`mb-[50px]`}>
        <Heading level={2}>一覧</Heading>
        {ipLimitedData?.allowCIDRList?.map((data) => (
          <CIDRForm
            key={data.cidr}
            cidr={data.cidr}
            onSubmit={async (change) => {
              const data =
                ipLimitedData.allowCIDRList?.map((item) =>
                  change.before === item.cidr ? { cidr: change.after } : item
                ) ?? [];

              const doc = {
                ...ipLimitedData,
                allowCIDRList: data,
              };
              const ref = db.doc(docPath);
              await setDoc<IPLimitedSettingData>(ref, doc);
              if (isMounted.current) {
                setIpLimitedData(doc);
              }
            }}
            onDelete={async (cidr) => {
              const ref = db.doc(docPath);
              const data =
                ipLimitedData.allowCIDRList?.filter(
                  (item) => item.cidr !== cidr
                ) ?? [];

              if (data.length === 0) {
                await deleteDoc(
                  doc(db, 'companies', companyId, 'settings', 'ipLimited')
                );
                if (isMounted.current) {
                  setIpLimitedData({ allowCIDRList: [] });
                }
                return;
              }

              const _doc = {
                ...ipLimitedData,
                allowCIDRList: data,
              };
              await setDoc<IPLimitedSettingData>(ref, _doc);
              if (isMounted.current) {
                setIpLimitedData(_doc);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default IPLimited;
