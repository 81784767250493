import { useCollapseState } from './hooks/useCollapseState';
import { Icon } from '../../../components/basics';
import React, { useMemo } from 'react';
import { sumBy } from 'lodash';
import { Forum, Plus } from '../../../components/icons';
import { LeftSidebarLink } from './button/LeftSidebarLink';
import { SidebarSectionTitle } from '../../../components/sidebar/SidebarSectionTitle/SidebarSectionTitle';
import { useAtomValue } from 'jotai';
import { roomsAtom } from '../../../atoms/firestore/room';
import { meAtom } from '../../../atoms/auth';
import { usersAtom } from '../../../atoms/firestore/user';
import { tv } from 'tailwind-variants';

type Props = {
  onNewRoom: () => void;
  supported: boolean;
};

const message = tv({
  base: 'rounded-lg bg-sumi-100 p-2 text-xs',
});

export const LeftSidebarChatRooms = ({
  onNewRoom,
  supported,
}: Props): JSX.Element => {
  const [chatOpen, setChatOpen] = useCollapseState('room', false);

  const me = useAtomValue(meAtom);
  const chatRooms = useAtomValue(roomsAtom);
  const users = useAtomValue(usersAtom);

  const userId = me.id;
  const allNotificationCount = useMemo(
    () => sumBy(chatRooms, (r) => r.unreadCount(userId)),
    [chatRooms, userId]
  );

  const title = useMemo(
    () => (
      <SidebarSectionTitle
        onOpenChange={setChatOpen}
        open={chatOpen}
        rightButton={
          me.isReadOnly || !supported
            ? undefined
            : {
                icon: <Icon icon={Plus} size={20} className="text-sea-500" />,
                onClick: onNewRoom,
              }
        }
        count={!chatOpen ? allNotificationCount : undefined}
      >
        チャット
      </SidebarSectionTitle>
    ),
    [me.isReadOnly, supported, onNewRoom, chatOpen, allNotificationCount]
  );

  const rooms = useMemo(() => {
    if (!chatOpen) {
      return null;
    } else if (chatRooms.length <= 0) {
      return (
        <p className={message()}>
          他のユーザーがいる場合、チャットが表示されます
        </p>
      );
    } else if (!supported) {
      return (
        <p className={message()}>
          プランをスタンダード以上にアップグレードすると、チャットが利用できます。
        </p>
      );
    } else {
      return chatRooms.map((r) => {
        const label =
          r.subject ??
          r.members
            .filter((m) => userId !== m)
            .map(
              (m) => users.find((u) => u.id === m)?.name || '削除されたユーザー'
            )
            .join(', ');
        return (
          <LeftSidebarLink
            key={r.id}
            icon={Forum}
            label={label}
            count={r.unreadCount(userId)}
            to={`/rooms/${r.id}`}
          />
        );
      });
    }
  }, [chatOpen, chatRooms, userId, users, supported]);
  return (
    <>
      {title}
      {rooms}
    </>
  );
};
