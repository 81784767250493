import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Alert, Checkbox, Modal, Select as DefaultSelect, Tag } from 'antd';
import { Heading } from 'components/basics/Heading/Heading';
import { Comment } from '../../../../Common/Comment';
import { DefaultInput } from '../../../../Common/Input';
import { getTagLabel } from '../../../../../util';
import { TAG_COLORS } from 'lib';

const { Option } = DefaultSelect;

const NewTagModal = ({ open, onClose, add, tags }) => {
  const [showParentTag, setShowParentTag] = useState(false);
  const [parentTagId, setParentTagId] = useState(null);
  const [name, setName] = useState('');
  const [color, setColor] = useState(null);
  const [adding, setAdding] = useState(false);

  const duplicatedName = tags.some((t) => t.name === name);
  const invalidName = name.trim() === '' || duplicatedName;

  const selectableParentTags = useMemo(() => {
    return tags.filter((tag) => !tag.parentTagId && !tag.isDefault);
  }, [tags]);

  const selectedParentTag = useMemo(() => {
    return selectableParentTags.find((tag) => tag.id === parentTagId);
  }, [selectableParentTags, parentTagId]);

  const onChangeParentTagCheckbox = useCallback((e) => {
    setParentTagId(null);
    setShowParentTag(e.target.checked);
  }, []);

  const onChangeParentTag = useCallback((tagId) => {
    setParentTagId(tagId);
  }, []);

  const addTag = async (e) => {
    e.preventDefault();
    setAdding(true);
    await add({
      name,
      color,
      parentTagId,
    });
    setAdding(false);
  };

  const renderTagResult = useCallback(() => {
    const { label, shortLabel } = getTagLabel(name, {
      parentTagLabel: selectedParentTag?.name,
    });

    return (
      <Select value={color} onChange={setColor}>
        <Option value={null}>
          <Tag>{shortLabel ?? label}</Tag>
        </Option>
        {TAG_COLORS.map((c) => (
          <Option value={c} key={c}>
            <Tag color={c}>{shortLabel ?? label}</Tag>
          </Option>
        ))}
      </Select>
    );
  }, [color, name, selectedParentTag]);

  return (
    <Modal
      title="新規タグ"
      cancelText="キャンセル"
      okText="作成"
      visible={open}
      onOk={addTag}
      onCancel={onClose}
      okButtonProps={{ disabled: invalidName }}
      cancelButtonProps={{ disabled: adding }}
      confirmLoading={adding}
    >
      <Heading level={3}>名前</Heading>
      <NameField>
        {showParentTag && (
          <div style={{ marginRight: 10 }}>
            <Select value={parentTagId} onChange={onChangeParentTag}>
              <Option value={null}>親タグなし&nbsp;</Option>
              {selectableParentTags.map((tag) => {
                const tagMaxLength = 5;
                const isLongTag = tag.name.length > tagMaxLength;
                return (
                  <Option value={tag.id} key={tag.id}>
                    <Tag color={tag.color}>
                      {isLongTag
                        ? `${tag.name.slice(0, tagMaxLength)}...`
                        : tag.name}
                    </Tag>
                  </Option>
                );
              })}
            </Select>
          </div>
        )}
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="重要"
          required
          disabled={adding}
        />
      </NameField>
      <Comment>例：重要、後で返信</Comment>
      <div style={{ marginTop: 14, display: 'flex', alignItems: 'center' }}>
        <Checkbox
          style={{ marginRight: 10 }}
          checked={showParentTag}
          onChange={onChangeParentTagCheckbox}
        />
        <Comment>親タグを設定する</Comment>
      </div>
      {!adding && duplicatedName && (
        <Alert
          message="すでに作成されているタグ名です"
          type="error"
          style={{ marginTop: '8px' }}
        />
      )}

      <Heading level={3} style={{ marginTop: 24 }}>
        色
      </Heading>
      <div>
        <div
          style={{ display: 'inline-block', marginTop: 10, marginBottom: 10 }}
        >
          {renderTagResult()}
        </div>
      </div>
    </Modal>
  );
};

const NameField = styled.div`
  display: flex;
  max-width: 400px;
  height: 40px;
  margin: 10px 0;
`;

const Input = styled(DefaultInput)`
  height: 40px;
`;

const Select = styled(DefaultSelect)`
  width: auto;
  height: 40px;

  > .ant-select-selection {
    display: flex;
    align-items: center;
    min-width: 110px;
    height: 40px;
  }
`;

export default NewTagModal;
