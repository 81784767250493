import {
  convertNtoBr,
  DraftAttachment,
  MessageData,
  SentData,
  SignatureData,
} from 'lib';
import { DocumentReference, Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';
import moment from 'moment';

export type DraftData = {
  teamId: string;
  originalInboxId?: string;
  inboxId: string;
  isReply: boolean;
  isForwarded: boolean;
  inReplyToMessageId?: string;
  inReplyToMessageRef?: DocumentReference<MessageData>;
  inReplyToSentId?: string;
  inReplyToSentRef?: DocumentReference<SentData>;
  drafter: string;
  messageId?: string;
  to: string[];
  cc: string[];
  bcc: string[];
  subject: string;
  originalSubject?: string;
  body: string;
  bodyHtml: string;
  signature: (SignatureData & { id: string }) | null;
  useQuote: boolean;
  attachments: DraftAttachment[];
  scheduledAt?: Timestamp | null;
  failedAt?: Timestamp | null;
  followupAt?: Timestamp | null;
  followupStatus?: string;
  isSending?: boolean;
  isGenerating?: boolean;
  plainTextMode: boolean;
  retryAt?: Timestamp; // 予約送信時に再試行する時間
  ignoreUnsubscribes?: boolean; // 予約送信時に購読解除状態を無視して送るか
  status?: string;
  responseCode?: number;
  updatedAt: Timestamp;
  createdAt: Timestamp;
};

export const createDraft = createEntityFactory(
  'Draft',
  (data: DraftData, ref) => ({
    date: moment(data.createdAt.toMillis()),

    get signature(): (DraftData['signature'] & { bodyHtml: string }) | null {
      if (!data.signature) {
        return null;
      }
      return {
        ...data.signature,
        bodyHtml:
          data.signature.bodyHtml ||
          // HTML化対応前の署名はテキストからHTMLに変換する
          convertNtoBr(data.signature.body),
      };
    },

    useQuote: data.useQuote ?? false,

    isScheduled: !!data.scheduledAt,

    isFailed: !!data.failedAt,

    isSending: data.isSending ?? false,

    isGenerating: data.isGenerating ?? false,

    plainTextMode: data.plainTextMode ?? false,

    ignoreUnsubscribes: data.ignoreUnsubscribes ?? false,

    companyId: ref.parent.parent?.id,
  })
);

export type Draft = ReturnType<typeof createDraft>;

export const draftConverter = createConverter(createDraft);
