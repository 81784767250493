export const ContactDrawerMemoSkeleton = () => {
  return (
    <div className="relative flex flex-col gap-2 text-sm">
      <div className="flex items-center gap-2">
        <div className="h-8 w-8 animate-pulse rounded-full bg-sumi-100" />
        <div className="h-4 w-24 animate-pulse rounded bg-sumi-100" />
      </div>
      <div className="flex flex-col gap-2">
        <div className="h-4 w-[40%] animate-pulse rounded bg-sumi-100" />
        <div className="h-4 w-[80%] animate-pulse rounded bg-sumi-100" />
      </div>
    </div>
  );
};
