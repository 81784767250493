import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReply = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m5.9 11.65 3.55 3.55c.15.15.22.325.212.525a.767.767 0 0 1-.237.525.734.734 0 0 1-.538.225.734.734 0 0 1-.537-.225l-4.825-4.825A.72.72 0 0 1 3.3 10.9c0-.2.075-.375.225-.525l4.85-4.85c.15-.15.329-.225.537-.225.209 0 .388.075.538.225.15.15.225.329.225.537a.734.734 0 0 1-.225.538L5.9 10.15h10.15c1.416 0 2.596.47 3.537 1.412C20.53 12.504 21 13.683 21 15.1v3.15a.728.728 0 0 1-.75.75.728.728 0 0 1-.75-.75V15.1c0-1-.325-1.825-.975-2.475-.65-.65-1.475-.975-2.475-.975H5.9Z"
    />
  </svg>
);
export default SvgReply;
