import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAttachFileAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M11.5 22c-1.517 0-2.813-.52-3.888-1.563C6.537 19.396 6 18.117 6 16.6V5.85c0-1.059.38-1.965 1.138-2.72C7.898 2.378 8.81 2 9.875 2c1.083 0 2 .375 2.75 1.125s1.125 1.667 1.125 2.75v8.35h-1V5.85c0-.798-.277-1.472-.832-2.023C11.363 3.275 10.684 3 9.88 3c-.803 0-1.484.276-2.043.826C7.28 4.378 7 5.053 7 5.85v10.8c0 1.217.44 2.246 1.322 3.088.881.841 1.94 1.262 3.178 1.262.634 0 1.23-.12 1.788-.363a4.672 4.672 0 0 0 1.462-.987v1.3c-.467.333-.97.592-1.512.775A5.385 5.385 0 0 1 11.5 22Zm4.75-4.25H14a.724.724 0 0 1-.534-.217.732.732 0 0 1-.216-.537.731.731 0 0 1 .75-.746h2.25V14c0-.213.072-.39.217-.534a.732.732 0 0 1 .537-.216.731.731 0 0 1 .746.75v2.25H20c.212 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746h-2.25V20c0 .212-.072.39-.217.534a.732.732 0 0 1-.537.216.731.731 0 0 1-.746-.75v-2.25ZM11.5 17v1a2.081 2.081 0 0 1-1.6-.712 2.42 2.42 0 0 1-.65-1.688V6.3c0-.133.05-.25.15-.35a.48.48 0 0 1 .7 0c.1.1.15.217.15.35v9.375c0 .367.12.68.36.938.239.258.536.387.89.387Zm4.5-5.925V6.3c0-.133.05-.25.15-.35a.48.48 0 0 1 .7 0c.1.1.15.217.15.35v4.775h-1Z"
    />
  </svg>
);
export default SvgAttachFileAdd;
