import { SettingPageDrawer } from '../../../Settings/common/SettingPageDrawer/SettingPageDrawer';
import { Controller, useForm } from 'react-hook-form';
import { InputGroup } from '../../../../components/forms/InputGroup/InputGroup';
import { Input } from '../../../../components/forms';
import { Button, Loading } from '../../../../components/basics';
import { useConfirmDialog } from '../../../../hooks/confirmDialog';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { isSimilarAddress, normalizeAddress, TagColor } from 'lib';
import { debounce } from 'lodash';
import { ContactTagsInput } from '../ContactTagsInput/ContactTagsInput';

type Contact = {
  name: string;
  email: string;
  tags: { name: string; color: TagColor | null | undefined }[];
  companyName: string;
  phoneNumber: string;
  memo: string;
};

export type ContactUpdate = Contact;

type EmailStatus = {
  email: string;
  status: 'available' | 'optOut' | 'error';
};

type Props = {
  contactId: string | undefined;
  contact: Partial<Contact>;
  checkDuplicateEmail: (email: string) => Promise<boolean>;
  tags: { name: string; color: TagColor | null | undefined }[];
  emailStatuses: EmailStatus[] | undefined; // undefinedはロード状態を表す
  teamName: string | undefined;
  memoSection: ReactElement;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onChange: (update: ContactUpdate) => Promise<void>;
  onDelete: (contactId: string) => Promise<void>;
  readonly: boolean;
  container?: HTMLElement;
};

const STATUS_MESSAGES: { [key in EmailStatus['status']]: string } = {
  available: '配信可能',
  optOut: 'オプトアウト',
  error: 'エラー',
} as const;

export const ContactEditDrawer = ({
  contactId,
  contact,
  checkDuplicateEmail,
  tags,
  emailStatuses,
  teamName,
  memoSection,
  open,
  onOpenChange,
  onChange,
  onDelete,
  readonly,
  container,
}: Props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<Contact>({
    defaultValues: {
      name: contact.name ?? '',
      email: contact.email ?? '',
      tags: contact.tags ?? [],
      companyName: contact.companyName ?? '',
      phoneNumber: contact.phoneNumber ?? '',
      memo: contact.memo ?? '',
    },
  });
  const openDialog = useConfirmDialog();
  const [emailDuplicated, setEmailDuplicated] = useState(false);
  const onSubmit = async (update: Contact) => {
    if (await checkDuplicate(update.email)) {
      return;
    }
    await onChange(update);
  };

  const checkDuplicate = useMemo(
    () =>
      debounce(async (email: string) => {
        if (contact.email === email) {
          setEmailDuplicated(false);
          return;
        }
        const result = await checkDuplicateEmail(normalizeAddress(email));
        setEmailDuplicated(result);
        return result;
      }, 200),
    [checkDuplicateEmail]
  );
  const emailValue = watch('email');
  useEffect(() => {
    if (!emailValue || isSimilarAddress(contact.email ?? '', emailValue)) {
      setEmailDuplicated(false);
      return;
    }

    checkDuplicate(normalizeAddress(emailValue));
  }, [emailValue]);

  return (
    <SettingPageDrawer
      title={contactId ? 'お客様情報' : 'お客様情報作成'}
      open={open}
      onOpenChange={onOpenChange}
      container={container}
      drawerClassName="bg-white"
    >
      <div className="flex flex-col gap-4 text-sm">
        <form
          action=""
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 text-sm"
        >
          <InputGroup
            label="名前"
            description="例）田中 一郎"
            errorMessage={errors.name?.message}
          >
            <Input
              {...register('name', {
                setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
              })}
              placeholder="田中 一郎"
              disabled={readonly || isSubmitting}
              autoFocus
            />
          </InputGroup>
          <InputGroup
            label="メールアドレス"
            description="例）test@example.com"
            errorMessage={
              emailDuplicated
                ? '既に登録されているメールアドレスです'
                : errors.email?.message
            }
            required
          >
            <Input
              {...register('email', {
                setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
                required: '入力してください',
              })}
              placeholder="test@example.com"
              disabled={readonly || isSubmitting}
              type="email"
            />
          </InputGroup>
          <InputGroup
            label="タグ"
            description={
              <>
                自由に複数のタグを付けることが可能です。
                <br />
                例）関東、営業部、契約済み…
              </>
            }
            errorMessage={errors.tags?.message}
          >
            <Controller
              control={control}
              name="tags"
              render={({ field: { value, onChange } }) => (
                <ContactTagsInput
                  tags={tags}
                  value={value}
                  onChange={onChange}
                  disabled={readonly || isSubmitting}
                />
              )}
            />
          </InputGroup>
          <InputGroup
            label="会社名"
            description="例）Onebox株式会社"
            errorMessage={errors.companyName?.message}
          >
            <Input
              {...register('companyName', {
                setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
              })}
              placeholder="Onebox株式会社"
              disabled={readonly || isSubmitting}
            />
          </InputGroup>
          <InputGroup
            label="電話番号"
            description="例）09012345678"
            errorMessage={errors.phoneNumber?.message}
          >
            <Input
              {...register('phoneNumber', {
                setValueAs: (v) => (typeof v === 'string' ? v.trim() : v),
              })}
              placeholder="09012345678"
              disabled={readonly || isSubmitting}
            />
          </InputGroup>
          <div className="flex gap-4">
            {contactId ? (
              <>
                <Button
                  color="danger"
                  disabled={readonly || isSubmitting}
                  onClick={() =>
                    openDialog({
                      title: 'お客様情報を削除しますか？',
                      description: '一度削除すると元に戻せません',
                      okType: 'danger',
                      okText: '削除',
                      onOk: async () => await onDelete(contactId),
                    })
                  }
                >
                  削除
                </Button>
                <Button
                  type="submit"
                  disabled={readonly || !isDirty || emailDuplicated}
                  loading={isSubmitting}
                >
                  更新
                </Button>
              </>
            ) : (
              <Button
                type="submit"
                disabled={readonly || !isDirty || emailDuplicated}
                loading={isSubmitting}
              >
                作成
              </Button>
            )}
          </div>
        </form>
        {memoSection}
        {contactId && (
          <InputGroup
            label="メールアドレスごとの送信状態"
            descriptionTop={
              (teamName ? `${teamName}チーム` : '自分のコンタクト') +
              'のなかに設定されている共有メールアドレス、または、メールの一斉配信時に登録したメールアドレスからの送信状態を表示しています。'
            }
          >
            <>
              {!emailStatuses && <Loading />}
              {emailStatuses && (
                <ul className="m-0 p-0">
                  {emailStatuses.map((s, i) => (
                    <li
                      key={i}
                      className="grid grid-cols-[1fr_auto] justify-end gap-4 leading-7"
                    >
                      <div className="truncate">{s.email}</div>
                      <div>{STATUS_MESSAGES[s.status]}</div>
                    </li>
                  ))}
                </ul>
              )}
            </>
          </InputGroup>
        )}
      </div>
    </SettingPageDrawer>
  );
};
