import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import moment from 'moment';
import { Select } from '../../../forms';
import { Tag } from '../../../basics/Tag/Tag';
import SimpleBar from 'simplebar-react';
import { range, uniq } from 'lodash';
import { TablePagination } from '../../../table/TablePagination/TablePagination';
import { Icon } from '../../../basics';
import { Upgrade } from '../../../icons';
import { DeliveryEventData } from '../../../../firestore/entity/delivery';

const STATUSES = {
  opened: {
    label: '開封済',
    color: 'blue',
  },
  delivered: {
    label: '到着済',
    color: null,
  },
  undelivered: {
    label: '不達',
    color: 'gold',
  },
  unsubscribed: {
    label: '購読解除',
    color: 'red',
  },
} as const;

type Status = DeliveryEventData['status'];

type Entry = {
  name: string[];
  email: string;
  status: Status;
  openedAt: Date | null;
  tel: string[];
};

type Filter = {
  status: Status | null;
  pageSize: number;
};

// type TableContext = {};

type Props = {
  freemium: boolean;
  filter: Filter;
  onFilterChange: (filter: Filter) => void;
  skeletonCount: number;
  data: Entry[];
  onPageChange: (direction: number) => void;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export const DeliveryMessageDetailTable = ({
  freemium,
  filter,
  onFilterChange,
  skeletonCount,
  data,
  onPageChange,
  hasPreviousPage,
  hasNextPage,
}: Props) => {
  const columns: ColumnDef<Entry>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        cell: (info) => {
          const values = uniq(info.getValue() as string[]);
          if (values.length === 0) {
            return '';
          }
          return (
            <ul className="m-0 list-none p-0">
              {values.map((name) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          );
        },
        header: '送信先名',
      },
      {
        accessorKey: 'email',
        cell: (info) => info.getValue(),
        header: '送信先アドレス',
      },
      {
        accessorKey: 'status',
        cell: (info) => {
          const value = info.getValue() as Status;
          return (
            <Tag
              color={STATUSES[value].color}
              size="sm"
              className="border-none px-2"
            >
              {STATUSES[value].label}
            </Tag>
          );
        },
        header: 'ステータス',
      },
      {
        accessorKey: 'openedAt',
        cell: (info) => {
          const value = info.getValue();
          if (value) {
            return moment(value as Date).format('YYYY年M月D日 HH:mm');
          }
          return '';
        },
        header: '開封時間',
      },
      {
        accessorKey: 'tel',
        cell: (info) => {
          const values = uniq(info.getValue() as string[]);
          if (values.length === 0) {
            return '';
          }
          return (
            <ul className="m-0 list-none p-0">
              {values.map((tel) => (
                <li key={tel}>{tel}</li>
              ))}
            </ul>
          );
        },
        header: '電話番号',
      },
    ];
  }, []);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col items-start gap-4 text-sm">
      <div className="text-[16px] font-bold">詳細</div>
      <Select
        value={filter.status}
        onChange={(status: Status | null) =>
          onFilterChange({ ...filter, status })
        }
        placeholder="ステータスを絞り込む"
        options={[
          { value: null, label: '' },
          { value: 'opened', label: STATUSES.opened.label },
          { value: 'delivered', label: STATUSES.delivered.label },
          { value: 'undelivered', label: STATUSES.undelivered.label },
          { value: 'unsubscribed', label: STATUSES.unsubscribed.label },
        ]}
        renderLabel={(_v, label) =>
          label ? `ステータス：${label}` : undefined
        }
      />

      <div className="relative min-h-[calc(theme(height.8)_+_theme(height.9)_*_3)] w-full">
        <SimpleBar className="w-full" autoHide={false}>
          <table className="w-full min-w-[600px]">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="border-b border-b-sumi-300">
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="px-2 py-1 font-normal"
                      >
                        {header.isPlaceholder ? null : (
                          <div className="grid h-8 select-none grid-cols-[auto_1fr] items-center gap-2 whitespace-nowrap">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    key={row.id}
                    className="border-b border-b-sumi-300 last:border-b-0"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          key={cell.id}
                          className="h-9 whitespace-nowrap px-2 py-1"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SimpleBar>
        {data.length === 0 && skeletonCount > 0 && (
          <div>
            {range(skeletonCount).map((i) => (
              <div
                key={i}
                className="relative h-9 border-b border-b-sumi-300 last:border-b-0"
              >
                <div className="absolute inset-x-0 inset-y-2 animate-pulse rounded bg-sumi-200" />
              </div>
            ))}
          </div>
        )}
        {data.length === 0 && skeletonCount === 0 && (
          <div className="flex h-[calc(theme(height.9)_*_3)] w-full items-center justify-center">
            <span className="select-none text-sumi-700">
              データがありません
            </span>
          </div>
        )}
        {freemium && data.length > 0 && (
          <div className="pointer-events-none absolute bottom-0 left-0 flex h-[calc(100%_-_theme(height.10))] w-full items-end justify-center bg-[linear-gradient(to_top,white,transparent)]">
            <div className="pointer-events-auto flex items-center gap-4 py-4">
              <Icon icon={Upgrade} size={24} />
              <span>アップグレードすると全件見れるようになります</span>
            </div>
          </div>
        )}
      </div>
      {!freemium && (
        <div className="flex w-full justify-end">
          <TablePagination
            pageSize={filter.pageSize}
            onPageSizeChange={(pageSize) =>
              onFilterChange({ ...filter, pageSize })
            }
            sizes={[10, 25, 50, 100]}
            onPageChange={onPageChange}
            hasPrevious={hasPreviousPage}
            hasNext={hasNextPage}
          />
        </div>
      )}
    </div>
  );
};
