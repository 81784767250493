import moment from 'moment';
import { Timestamp } from 'firebase/firestore';
import { createConverter, createEntityFactory } from '../converter';

export interface MemoData {
  userId: string;
  teamId: string;
  text: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;

  // 古いメモからマイグレートされたものならtrue
  // マイグレートされたものはcreatedAtが正確な日付を示してないので、日付表示しないほうがいい
  isOld?: boolean;
}

export const createMemo = createEntityFactory('Memo', (data: MemoData) => ({
  date: moment(data.createdAt.toMillis()),
}));

export type Memo = ReturnType<typeof createMemo>;

export const memoConverter = createConverter(createMemo);
