import { Heading } from 'components/basics/Heading/Heading';
import React, { PropsWithChildren } from 'react';

export const SettingField: React.FC<
  PropsWithChildren<{
    label: string;
    hidden?: boolean;
  }>
> = ({ label, hidden, children }) => {
  if (hidden) {
    return null;
  }

  return (
    <div>
      <Heading level={3} className="label mb-[16px]">
        {label}
      </Heading>
      {children}
    </div>
  );
};
