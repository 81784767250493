import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.1 17.825a.918.918 0 0 0 .675-.275.918.918 0 0 0 .275-.675.918.918 0 0 0-.275-.675.918.918 0 0 0-.675-.275.918.918 0 0 0-.675.275.918.918 0 0 0-.275.675c0 .267.092.492.275.675a.918.918 0 0 0 .675.275Zm-.875-3.65H12.7c0-.433.054-.83.163-1.188.108-.358.445-.77 1.012-1.237.517-.433.883-.858 1.1-1.275.217-.417.325-.875.325-1.375 0-.883-.288-1.592-.863-2.125-.574-.533-1.337-.8-2.287-.8-.817 0-1.537.204-2.162.612-.625.409-1.08.971-1.363 1.688l1.325.5c.183-.467.458-.83.825-1.087.367-.259.8-.388 1.3-.388.567 0 1.025.154 1.375.463.35.308.525.704.525 1.187 0 .367-.108.713-.325 1.037-.217.325-.533.663-.95 1.013-.5.433-.87.863-1.112 1.288-.242.425-.363.987-.363 1.687ZM12 22a9.676 9.676 0 0 1-3.875-.788 10.144 10.144 0 0 1-3.188-2.15 10.142 10.142 0 0 1-2.15-3.187A9.676 9.676 0 0 1 2 12c0-1.383.263-2.683.788-3.9a9.994 9.994 0 0 1 2.15-3.175 10.25 10.25 0 0 1 3.187-2.137A9.676 9.676 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.676 9.676 0 0 1-.788 3.875 10.25 10.25 0 0 1-2.137 3.188 9.994 9.994 0 0 1-3.175 2.15A9.738 9.738 0 0 1 12 22Zm0-1.5c2.367 0 4.375-.83 6.025-2.488C19.675 16.354 20.5 14.35 20.5 12c0-2.367-.825-4.375-2.475-6.025C16.375 4.325 14.367 3.5 12 3.5c-2.35 0-4.354.825-6.013 2.475C4.33 7.625 3.5 9.633 3.5 12c0 2.35.83 4.354 2.487 6.012C7.646 19.671 9.65 20.5 12 20.5Z"
    />
  </svg>
);
export default SvgHelp;
