import { useDraft } from './hooks/useDraft';
import { Button, Icon } from '../../../components/basics';
import { Plus } from '../../../components/icons';
import { tv } from 'tailwind-variants';
import { ReadOnlyTooltip } from '../../../components/ReadOnlyTooltip';
import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import { Tooltip } from '../../../components/basics/Tooltip/Tooltip';

type Props = {
  shadow: boolean;
};

const button = tv({
  base: 'flex h-10 w-[120px] items-center justify-center gap-1 rounded-lg bg-white font-bold',
  variants: {
    shadow: {
      true: 'shadow-draft-button',
    },
  },
});

const CreateDraftButton = observer(({ shadow }: Props): JSX.Element => {
  const { createDraft, hasDraft } = useDraft();
  const store = useStore();
  const isReadOnly = store.me.isReadOnly;
  const content = (
    <div className={'w-fit'}>
      <Button
        color="primary"
        variant="outlined"
        className={button({ shadow })}
        onClick={createDraft}
        disabled={isReadOnly || hasDraft}
      >
        <Icon icon={Plus} size={24} />
        <span className="mr-1">作成</span>
      </Button>
    </div>
  );
  if (isReadOnly) {
    return (
      <ReadOnlyTooltip action={'use'} enabled={isReadOnly}>
        {content}
      </ReadOnlyTooltip>
    );
  } else if (store.inboxes.length === 0) {
    return (
      <Tooltip content="所属するチームがないか、チームにメールが設定されていません">
        {content}
      </Tooltip>
    );
  }
  return content;
});

export default CreateDraftButton;
