import { ReactNode, useState } from 'react';
import { Icon } from 'components/basics';
import { CaretDown } from 'components/icons';

interface Props {
  label: string;
  actions: ReactNode;
  children: ReactNode;
}

export const SalesforceDetails = ({
  label,
  actions,
  children,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <div
        className="flex cursor-pointer cursor-pointer items-center justify-between py-2 font-bold text-gray-700 hover:text-black"
        onClick={() => setOpen(!open)}
      >
        <span className="flex">
          {label}
          <Icon
            icon={CaretDown}
            size={20}
            className={`ml-1 mr-2 ${open ? 'rotate-180' : 'rotate-0'}`}
          />
        </span>
        <div onClick={(e) => e.stopPropagation()}>{actions}</div>
      </div>
      {open && children}
    </>
  );
};
