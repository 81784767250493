import { siteKey } from 'firebase.js';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import ja from 'world_countries_lists/data/countries/ja/world.json';
import '../../../../node_modules/antd-country-phone-input/dist/index.css';
import RecaptchaContainer from '../../Auth/RecaptchaContainer';
import useMFAModal from '../../Auth/useMFAModal';
import { Heading } from 'components/basics/Heading/Heading';

import { DefaultInput } from '../../Common/Input';
import { DefaultButton } from '../../Common/Button';
import { Comment } from '../../Common/Comment';
import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from 'firebase/auth';
import { useCallback, useState } from 'react';

const defaultCountryPhoneInput = {
  code: 81,
  short: 'JP',
};

export const EnrollMFAPhoneNumberForm = (props) => {
  /** @type {import('firebase/auth').User} */
  const currentUser = props.currentUser;
  const multiFactorUser = currentUser ? multiFactor(currentUser) : null;
  const [addPhoneNumber, setAddPhoneNumber] = useState('');
  const [countryPhoneInput, setCountryPhoneInput] = useState({
    ...defaultCountryPhoneInput,
    phone: '\0',
  });
  const [isReady, setIsReady] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [password, setPassword] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const { reauthenticate } = useMFAModal();
  const [errorMessage, setErrorMessage] = useState('');

  const enroll = async (event) => {
    event.preventDefault();
    try {
      setErrorMessage('');
      if (!multiFactorUser || !recaptchaVerifier) {
        return;
      }
      const auth = getAuth();
      await reauthenticateWithCredential(
        currentUser,
        EmailAuthProvider.credential(currentUser.email, password)
      ).catch((err) => {
        if (err.code !== 'auth/multi-factor-auth-required') {
          throw err;
        }
        return reauthenticate(currentUser, { password }).then(() => {});
      });

      const multiFactorSession = await multiFactorUser.getSession();

      const phoneInfoOptions = {
        phoneNumber: addPhoneNumber,
        session: multiFactorSession,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      const verificationId = await phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        .catch((err) => {
          if (err.code !== 'auth/multi-factor-auth-required') {
            throw err;
          }
          return reauthenticate(currentUser);
        });
      setVerificationId(verificationId);
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-phone-number':
          setErrorMessage('電話番号の形式が違います');
          break;
        case 'auth/wrong-password':
          setErrorMessage('パスワードが違います');
          break;
        case 'auth/second-factor-already-in-use':
          setErrorMessage('既に登録されています');
          break;
        default:
          setErrorMessage(error.code);
      }
    }
  };

  const verificationCodeSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!verificationCode) {
        return;
      }

      try {
        // Ask user for the verification code. Then:
        const cred = PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        const _addPhoneNumber = addPhoneNumber ?? '';
        const addPhoneNumberName = _addPhoneNumber.substring(
          _addPhoneNumber.length,
          -4
        ); // 最後の4桁を名前にする

        // Complete enrollment.
        await multiFactorUser.enroll(multiFactorAssertion, addPhoneNumberName);
        window.location.reload();
      } catch (error) {
        switch (error.code) {
          case 'auth/invalid-verification-code':
            setErrorMessage('確認コードが違います');
            break;
          default:
            setErrorMessage(error.code);
        }
      }
    },
    [addPhoneNumber, multiFactorUser, verificationCode, verificationId]
  );

  if (props.disabled) {
    return null;
  }

  if (verificationId) {
    return (
      <form onSubmit={verificationCodeSubmit}>
        {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
        <Heading level={3}>確認コード</Heading>
        <DefaultInput
          className={`mb-[10px] h-[40px] w-[400px]`}
          type="text"
          value={verificationCode}
          onChange={(event) => {
            const val = event.target.value;
            setVerificationCode(val);
          }}
        />
        <DefaultButton
          style={{ marginTop: '10px', width: '400px', height: '40px' }}
          type="primary"
          disabled={!verificationCode}
        >
          確認コードの送信
        </DefaultButton>
      </form>
    );
  }

  return (
    <form onSubmit={enroll} style={{ marginTop: '30px' }}>
      {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
      <Heading level={3}>電話番号の追加</Heading>
      <ConfigProvider locale={ja}>
        <CountryPhoneInput
          style={{ width: '400px' }}
          type="tel"
          inline
          placeholder="0801231234"
          value={countryPhoneInput ?? {}}
          autoComplete="off"
          disabled={!isReady}
          onFocus={() => {
            // disable auto complete
            // https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off
            setCountryPhoneInput({
              ...countryPhoneInput,
              phone: countryPhoneInput.phone.replace('\0', ''),
            });
          }}
          onChange={(event) => {
            setCountryPhoneInput(event);
            if (!event.phone) {
              return;
            }
            const phoneNumber = `+${event.code}${event.phone
              ?.replace(/^0/, '')
              .replaceAll('-', '')
              .replaceAll(' ', '')}`;
            setAddPhoneNumber(phoneNumber);
          }}
        />
      </ConfigProvider>
      <Heading level={3}>パスワード</Heading>
      <Comment>yaritoriのログイン時のパスワードを入力してください</Comment>
      <DefaultInput
        className={`mb-[10px] h-[40px] w-[400px]`}
        type="password"
        value={password}
        placeholder="************"
        onChange={(event) => {
          const val = event.target.value;
          setPassword(val);
        }}
      />
      <RecaptchaContainer
        siteKey={siteKey}
        onReady={() => {
          setIsReady(true);
        }}
        onCheckBox={({ recaptchaVerifier }) => {
          setRecaptchaVerifier(recaptchaVerifier);
        }}
        expiredCallback={() => setRecaptchaVerifier(null)}
      />
      <DefaultButton
        type="primary"
        style={{ marginTop: '10px', width: '400px', height: '40px' }}
        disabled={!addPhoneNumber || !password || !recaptchaVerifier}
      >
        確認番号の送信
      </DefaultButton>
    </form>
  );
};
