import { observer } from 'mobx-react';
import { useEffect, useMemo, useRef } from 'react';
import { Close, Filter, Search } from '../../../components/icons';
import { Button, Icon } from '../../../components/basics';
import {
  dirtyResetAtom,
  filterFormToQuery,
  SearchRange,
  Tray,
  useSearch,
} from './search/useSearch';
import * as Popover from '@radix-ui/react-popover';
import { SearchFilterForm } from './SearchFilterForm';
import { Controller } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { useStore } from '../../../hooks/useStore';
import { Tooltip } from '../../../components/basics/Tooltip/Tooltip';
import { atom, useAtom, useAtomValue } from 'jotai';
import { meAtom } from '../../../atoms/auth';
import { Select } from '../../../components/forms';
import { isEqual, omit } from 'lodash';

export type SearchFilterData = {
  keyword: string;
  searchRange: SearchRange;
  tray: Tray;
  status: string;
  from: string;
  to: string;
  subjectOrText: string;
  tag: string;
  assignee: string | null;
  after: Date | null;
  before: Date | null;
  hasAttachments: boolean;
  attachmentsFilename: string;
};

type FilterPreset = 'allReceivedEmails' | 'allSentEmails' | 'custom';

const PLACEHOLDERS: { [key in FilterPreset]: string } = {
  allReceivedEmails: 'すべての受信メールから検索',
  allSentEmails: 'すべての送信メールから検索',
  custom: 'フィルタをかけて検索',
} as const;

const focusedAtom = atom(false);
export const searchFilterOpenAtom = atom(false);
export const searchFilterAutoFocusIdAtom = atom<string>();

export const openSearchFilterAtom = atom(
  null,
  (_get, set, autoFocusId?: string) => {
    set(searchFilterAutoFocusIdAtom, autoFocusId);
    set(focusedAtom, true);
    set(searchFilterOpenAtom, true);
  }
);

export const MainSearch = observer((): JSX.Element => {
  const [focused, setFocused] = useAtom(focusedAtom);
  const [filterOpened, setFilterOpened] = useAtom(searchFilterOpenAtom);
  const searchRef = useRef<HTMLDivElement>();
  const inputRef = useRef<HTMLInputElement>(null);
  const { searchStore } = useStore();
  const [isDirty, resetFilter] = useAtom(dirtyResetAtom);
  const me = useAtomValue(meAtom);
  const filterAutoFocusId = useAtomValue(searchFilterAutoFocusIdAtom);

  const search = useSearch(() => setFocused(false));

  useEffect(() => {
    if (!focused) {
      return;
    }
    const handleClick = (e: MouseEvent) => {
      const elem = searchRef.current;
      if (!elem) {
        return;
      }
      if (filterOpened) {
        return;
      }
      const node = e.target as HTMLElement;
      const menu = document.getElementById('searchRangeMenu');
      if (!elem.contains(node) && !menu?.contains(node)) {
        setFocused(false);
        setFilterOpened(false);
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  }, [focused, inputRef.current, filterOpened]);

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus();
    }
  }, [focused, inputRef.current]);

  useEffect(() => {
    if (!filterOpened || !filterAutoFocusId) {
      return;
    }

    setTimeout(() => search.filterForm.setFocus(filterAutoFocusId as never));
  }, [filterOpened, filterAutoFocusId, search.filterForm.setFocus]);

  const keyword = search.filterForm.watch('keyword');
  const formValues = search.filterForm.watch();

  const filterPreset: FilterPreset = useMemo(() => {
    if (formValues.tray === 'sent') {
      return formValues.searchRange.type === 'allInboxes'
        ? 'allSentEmails'
        : 'custom';
    }
    if (formValues.tray === 'messages') {
      const presetQuery = filterFormToQuery({
        tray: 'messages',
        searchRange: { type: 'allInboxes' },
      });
      const isMessagesPreset = isEqual(
        filterFormToQuery(omit(formValues, 'keyword')),
        presetQuery
      );
      return isMessagesPreset ? 'allReceivedEmails' : 'custom';
    }
    return 'custom';
  }, [searchStore.query, formValues, me.id]);

  const shouldShowSimpleFilter = useMemo(() => {
    return !searchStore.inSearch || filterPreset !== 'custom';
  }, [searchStore.inSearch, searchStore.query]);

  const onChangePreset = (preset: FilterPreset) => {
    let tray: Tray;
    switch (preset) {
      case 'allReceivedEmails':
        tray = 'messages';
        break;
      case 'allSentEmails':
        tray = 'sent';
        break;
      default:
        return;
    }
    search.filterForm.reset();
    search.filterForm.setValue('keyword', keyword);
    search.filterForm.setValue('tray', tray);
    inputRef.current?.focus();
  };

  return focused || keyword || searchStore.inSearch ? (
    <Popover.Root open={focused}>
      <div
        className={twMerge(
          'relative flex h-full w-full items-center rounded-lg border bg-white text-sumi-900',
          focused ? 'border-sea-500 shadow-dropdown' : 'border-sumi-300'
        )}
      >
        <Popover.Trigger asChild>
          <div
            id="search-input"
            className="flex h-10 flex-1 items-center gap-0.5 pr-1"
            onClick={() => setFocused(true)}
          >
            <form
              onSubmit={search.filterForm.handleSubmit(search.search)}
              className="relative h-10 flex-1 text-sm"
            >
              <div className="pointer-events-none absolute left-0 top-0 flex h-10 w-12 items-center justify-center text-sumi-500">
                <Icon icon={Search} />
              </div>
              <Controller
                control={search.filterForm.control}
                name="keyword"
                render={({ field }) => (
                  <input
                    type="text"
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    ref={inputRef}
                    spellCheck={false}
                    className="h-full w-full bg-transparent pl-12 text-sm text-sumi-900 outline-none placeholder:text-sumi-500"
                    placeholder={PLACEHOLDERS[filterPreset]}
                  />
                )}
              />
            </form>
            {(searchStore.inSearch || isDirty) && (
              <Tooltip content="検索を解除">
                <button
                  type="button"
                  className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full bg-transparent p-0 text-sumi-900 hover:bg-sumi-100"
                  onClick={() => {
                    resetFilter();
                    inputRef.current?.focus();
                  }}
                >
                  <Icon icon={Close} size={24} />
                </button>
              </Tooltip>
            )}
            <Tooltip content="詳細な条件">
              <button
                type="button"
                className={twMerge(
                  'flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full bg-transparent p-0 text-sumi-900 hover:bg-sumi-100',
                  searchStore.inSearch ? 'text-sea-500' : undefined
                )}
                onClick={() => setFilterOpened(true)}
              >
                <Icon icon={Filter} />
              </button>
            </Tooltip>
          </div>
        </Popover.Trigger>
        <Popover.Content
          className="z-50	w-[var(--radix-popover-trigger-width)] rounded-lg bg-white p-2 shadow-dropdown"
          sideOffset={1}
          onOpenAutoFocus={(e) => e.preventDefault()}
          onInteractOutside={(e) => {
            if ((e.target as HTMLElement).closest('#search-input')) {
              return;
            }
            setFocused(false);
            setFilterOpened(false);
          }}
        >
          {filterOpened || !shouldShowSimpleFilter ? (
            <SearchFilterForm search={search} />
          ) : (
            <Select
              value={filterPreset}
              onChange={(preset: FilterPreset) => onChangePreset(preset)}
              options={[
                { value: 'allReceivedEmails', label: 'すべての受信メール' },
                { value: 'allSentEmails', label: 'すべての送信メール' },
              ]}
              renderLabel={(_v, l) => `検索対象: ${l}`}
              variants={{
                border: false,
                width: 'full',
              }}
              footerElement={
                <Button
                  icon={Filter}
                  variant="text"
                  className="text-start font-normal no-underline"
                  onClick={() => setFilterOpened(true)}
                  size="sm"
                >
                  もっと絞り込む
                </Button>
              }
            />
          )}
        </Popover.Content>
      </div>
    </Popover.Root>
  ) : (
    <div className="flex h-full w-full items-center gap-[10px] rounded-lg bg-sumi-50 px-3.5 py-2.5 text-sumi-500">
      <Icon icon={Search} />
      <div
        onClick={() => setFocused(true)}
        className="flex-1 cursor-text select-none text-sm"
      >
        {PLACEHOLDERS[filterPreset]}
      </div>
    </div>
  );
});
