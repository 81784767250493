import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { PCOrTabletOnly, SPOnly } from '../Common/MediaQuery';
import { useStore } from 'hooks/useStore';
import { MsgRouteMatch, useMsgRouteMatch } from 'hooks/useMsgRouteMatch';
import { MessageModal } from './LeftSidebar/MessageModal';
import { SPTop } from './SPTop';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  messagesFilterAtom,
  useOnUpdateMessage,
} from '../../atoms/firestore/message';
import { PCOrTabletTop } from './PCOrTabletTop';
import { FloatingCreateDraftButton } from './LeftSidebar/FloatingCreateDraftButton';
import { useShouldShowFloatingDraftButton } from './LeftSidebar/hooks/useShouldShowFloatingDraftButton';
import { useSearchHistoryListener } from '../Common/MainNav/search/useSearchHistoryListener';
import { sentFilterAtom } from '../../atoms/firestore/sent';
import {
  allTeamStatusesInGroupAtom,
  isAllStatusesLoadedAtom,
} from 'atoms/firestore/customStatuses';
import { getStatusesFromView } from 'utils/frontMessageStatus';
import { modalDraftEffect } from 'atoms/firestore/draft';

export const Top: React.FC = observer(() => {
  const store = useStore();
  const prevMatch = useRef<MsgRouteMatch | null>(null);
  const shouldShowFloatingDraftButton = useShouldShowFloatingDraftButton();
  const [, allTeamStatusesInGroup] = useAtomValue(allTeamStatusesInGroupAtom);

  useOnUpdateMessage();

  // 検索履歴の処理
  useSearchHistoryListener(store.searchStore);
  useAtomValue(modalDraftEffect);

  const setMessagesFilter = useSetAtom(messagesFilterAtom);
  const setSentFilter = useSetAtom(sentFilterAtom);
  const isAllStatusesLoaded = useAtomValue(isAllStatusesLoadedAtom);

  const shouldResetTab = (
    match: MsgRouteMatch,
    prevMatch: MsgRouteMatch
  ): boolean => {
    const prevParams = prevMatch.params;
    if (match.page !== prevMatch.page) {
      return true;
    } else if (match.page === 'team') {
      const { teamId, inboxId, tagId } = match.params;
      return (
        teamId !== prevParams.teamId ||
        tagId !== prevParams.tagId ||
        inboxId !== prevParams.inboxId
      );
    } else if (match.page === 'line') {
      const { teamId, lineAccountId } = match.params;
      return (
        teamId !== prevParams.teamId ||
        lineAccountId !== prevParams.lineAccountId
      );
    }
    return false;
  };

  const shouldFetch = (
    match: MsgRouteMatch,
    prevMatch: MsgRouteMatch
  ): boolean => {
    const prevParams = prevMatch.params;
    if (match.page !== prevMatch.page) {
      return true;
    } else if (match.page === 'me') {
      return match.params.tab !== prevParams.tab;
    } else if (match.page === 'team') {
      const { teamId, inboxId, tagId, tab } = match.params;
      return (
        teamId !== prevParams.teamId ||
        tagId !== prevParams.tagId ||
        inboxId !== prevParams.inboxId ||
        tab !== prevParams.tab
      );
    } else if (match.page === 'line') {
      const { teamId, lineAccountId, tab } = match.params;
      return (
        teamId !== prevParams.teamId ||
        lineAccountId !== prevParams.lineAccountId ||
        tab !== prevParams.tab
      );
    }
    return false;
  };

  const fetchMessages = (match: MsgRouteMatch) => {
    if (!match.page) {
      return;
    }
    if (match.page === 'line') {
      store.lineStore.lineAccountId = match.params.lineAccountId;
      store.lineStore.syncLineThreads();
      return;
    }
    const { view, deleted } = store.messageFilterStore;
    const { teamId, inboxId, tagId } = match.params;
    if (match.tab === 'sent') {
      setSentFilter({
        teamId,
        inboxId,
      });
      return;
    }

    const status = getStatusesFromView(view, allTeamStatusesInGroup);
    setMessagesFilter({
      teamId,
      inboxId,
      tagId,
      status,
      customStatus: match.tab === 'other',
      assignedMe: match.page === 'me',
      deleted,
    });
  };

  const match = useMsgRouteMatch();

  useEffect(() => {
    if (store.teamsLoading || !isAllStatusesLoaded) {
      return;
    }

    store.messagesStore.selectView(match.tab);
    if (prevMatch.current && shouldResetTab(match, prevMatch.current)) {
      store.resetMessageView();
    }
    if (!prevMatch.current || shouldFetch(match, prevMatch.current)) {
      fetchMessages(match);
    }

    prevMatch.current = match;
  }, [match, store.tags, store.teamsLoading, isAllStatusesLoaded]);

  return (
    <>
      <PCOrTabletOnly>
        <PCOrTabletTop />
      </PCOrTabletOnly>
      <SPOnly>
        <SPTop />
      </SPOnly>

      {shouldShowFloatingDraftButton && <FloatingCreateDraftButton />}
      {/* メッセージ作成モーダル */}
      <MessageModal />
    </>
  );
});
