import { ChatGradient, YaritoriAiGradient } from '../../../icons';
import { Icon, IconButton } from '../../../basics';
import { Tooltip } from '../../../basics/Tooltip/Tooltip';
import { useHistory } from 'react-router-dom';
import {
  EditorHandle,
  WysiwygEditor,
  WysiwygEditorProps,
} from '../../../../App/Common/Editor/WysiwygEditor/WysiwygEditor';
import { tv } from 'tailwind-variants';
import { EditorToolbarWithControls } from '../../../../App/Common/Editor/EditorToolbar/EditorToolbarWithControls';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { debounce, isEqual } from 'lodash';
import SimpleBar from 'simplebar-react';
import { AiPromptDialog } from '../../../../App/Common/Editor/AiPromptDialog/AiPromptDialog';
import { reservedWords } from '../../../../App/Common/Editor/util';
import { ReservedWordsModal } from '../../../../App/Common/Editor/ReservedWordsModal/ReservedWordsModal';

const RESERVED_WORDS = [
  {
    name: '送信先の名前',
    description: '送信先のメールアドレスに設定されている名前',
    value: reservedWords.toName,
  },
  {
    name: '送信先のメールアドレス',
    description: '送信先のメールアドレス',
    value: reservedWords.toEmail,
  },
] as const;

type Props = {
  onChange: (plainText: string, html: string) => void;
  isYaritoriAISupported: boolean;
  initEditorHandle?: (handle: EditorHandle) => void;
  uploadImage: NonNullable<WysiwygEditorProps['uploadImage']>;
  onInsertImage: NonNullable<WysiwygEditorProps['onInsertImage']>;
  onDeleteImage: NonNullable<WysiwygEditorProps['onDeleteImage']>;
  onGenerate: (prompt: string, handle: EditorHandle) => void;
  defaultValue?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChangePlaintext: (plaintext: boolean) => void;
  isPlaintext: boolean;
  setHasUnsavedChanges: (value: boolean) => void;
  editorChildren?: (handle: EditorHandle) => ReactNode;
};

const divider = tv({
  base: 'mx-4 h-[1px] bg-sumi-300',
});

export const DeliveryMessageEditor = ({
  onChange,
  isYaritoriAISupported,
  initEditorHandle,
  uploadImage,
  onInsertImage,
  onDeleteImage,
  onGenerate,
  defaultValue,
  onChangePlaintext,
  isPlaintext,
  disabled = false,
  readonly = false,
  setHasUnsavedChanges,
  editorChildren,
}: Props) => {
  const history = useHistory();
  const [handle, setHandle] = useState<EditorHandle>();
  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const [showReservedWords, setShowReservedWords] = useState(false);
  const lastUpdatedContentsRef = useRef<{ plainText: string; html: string }>({
    plainText: '',
    html: '',
  });
  useEffect(() => {
    const editor = handle?.editor;
    if (!editor) {
      return;
    }
    const notifyChanges = () => {
      const plainText = handle.getText();
      const html = handle.getHtml();
      if (isEqual(lastUpdatedContentsRef.current, { plainText, html })) {
        setHasUnsavedChanges(false);
        return;
      }
      onChange(plainText, html);
      lastUpdatedContentsRef.current = { plainText, html };
      setHasUnsavedChanges(false);
    };
    const debouncedUpdate = debounce(notifyChanges, 500, {
      maxWait: 5000,
    });
    const onUpdate = () => {
      setHasUnsavedChanges(true);
      debouncedUpdate();
    };
    editor.on('update', onUpdate);
    editor.on('blur', notifyChanges);
    return () => {
      editor.off('update', onUpdate);
      editor.off('blur', notifyChanges);
    };
  }, [handle?.editor]);

  const handleGenerate = (prompt: string) => {
    if (!handle) {
      return;
    }
    onGenerate(prompt, handle);
    setAiDialogOpen(false);
  };

  return (
    <>
      <div className="flex flex-col rounded-lg border border-sumi-300">
        <div className="flex items-center gap-1 px-4 py-2">
          <Icon icon={YaritoriAiGradient} size={24} className="mr-1 block" />
          {isYaritoriAISupported ? (
            <Tooltip content="yaritori AI">
              <IconButton
                onClick={() => setAiDialogOpen(true)}
                component={ChatGradient}
                size="lg"
              />
            </Tooltip>
          ) : (
            <Tooltip content="AI翻訳や文章生成を使用するにはプランをアップデートしてください。">
              <IconButton
                onClick={() => history.push('/settings/company/payments')}
                component={ChatGradient}
                size="lg"
                disabled={disabled}
              />
            </Tooltip>
          )}
        </div>
        <div className={divider()} />
        <SimpleBar
          id="editorScroll"
          className="h-[400px]"
          classNames={{
            contentWrapper: 'simplebar-content-wrapper h-full',
            contentEl: 'simplebar-content h-full',
          }}
        >
          <WysiwygEditor
            key={isPlaintext ? 'plain' : 'html'}
            placeholder={`各位

「」を下記の通り開催します！
本会議では〇〇を説明するので必ずご参加ください。

イベントの詳細 ： 日時・場所
参加方法：対面/リモート
注意事項：持参物・入館方法
お問い合わせ先：担当者・連絡先
            `}
            editorClassName="p-4"
            initEditorHandle={(handle) => {
              setHandle(handle);
              initEditorHandle?.(handle);
            }}
            defaultValue={defaultValue}
            disabled={disabled}
            uploadImage={uploadImage}
            onInsertImage={onInsertImage}
            onDeleteImage={onDeleteImage}
            readonly={readonly}
            isPlaintextMode={isPlaintext}
          >
            {handle && editorChildren?.(handle)}
          </WysiwygEditor>
        </SimpleBar>
        <div className={divider()} />
        <div className="grid grid-cols-[1fr_auto] items-center gap-2 px-4 py-2">
          <EditorToolbarWithControls
            handle={handle}
            closable={false}
            disabled={disabled}
            onInsertImage={async (handle, file) => {
              const result = await uploadImage(file);
              if (result) {
                handle.insertImage(result.src, result.contentId);
              }
            }}
            plaintextMode={{
              value: isPlaintext,
              onChange: onChangePlaintext,
            }}
          />
          {/* 一旦非表示 */}
          {/*<button*/}
          {/*  type="button"*/}
          {/*  className="cursor-pointer whitespace-nowrap bg-transparent p-0"*/}
          {/*  onClick={() => {*/}
          {/*    setShowReservedWords(!showReservedWords);*/}
          {/*    handle?.editor?.commands.focus();*/}
          {/*  }}*/}
          {/*  disabled={disabled}*/}
          {/*>*/}
          {/*  予約語*/}
          {/*</button>*/}
        </div>
      </div>
      <AiPromptDialog
        open={aiDialogOpen}
        onGenerate={handleGenerate}
        onOpenChange={setAiDialogOpen}
        placeholder="イベント/ 研修の案内メールのテンプレートを作成してください！"
      />
      <ReservedWordsModal
        open={showReservedWords}
        onClose={() => setShowReservedWords(false)}
        description={
          <>
            送信時に「送信先の名前」などを自動でメール本文に埋め込むことができる機能です。
            <br />
            ※情報がない場合は空欄で表示されます。
          </>
        }
        words={RESERVED_WORDS}
        focusEditor={() => handle?.editor?.commands.focus()}
        insertReservedWord={(text) => handle?.insertText(text)}
      />
    </>
  );
};
