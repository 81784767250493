import { Alert, Button, Modal } from 'antd';
import { Heading } from 'components/basics/Heading/Heading';
import { DefaultInput } from '../../Common/Input';
import React, { useState } from 'react';
import { DefaultButton } from '../../Common/Button';
import { addLineAccountFunction } from '../../../functions';
import { useStore } from 'hooks/useStore';
import { useParams } from 'react-router-dom';

type Props = {
  visible: boolean;
  close: () => void;
};

export const AccountAddModal = ({ visible, close }: Props): JSX.Element => {
  const [channelId, setChannelId] = useState('');
  const [channelSecret, setChannelSecret] = useState('');
  const [adding, setAdding] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [error, setError] = useState('');

  const { teamId } = useParams<{ teamId: string }>();

  const store = useStore();

  const onCancel = () => {
    close();
    setChannelId('');
    setChannelSecret('');
    setAdding(false);
    setWebhookUrl('');
    setError('');
  };

  const onClickAdd = async () => {
    setAdding(true);
    try {
      const res = await addLineAccountFunction({
        companyId: store.signInCompany,
        teamId,
        channelId,
        channelSecret,
      });

      setWebhookUrl(res.data);
    } catch (e) {
      setError('アカウントの追加に失敗しました');
    }
    setAdding(false);
  };

  return (
    <Modal visible={visible} onCancel={onCancel} footer={null} width={600}>
      <Heading level={1} className="!mt-4 !pb-4 text-center">
        LINEアカウントの追加
      </Heading>
      {webhookUrl ? (
        <>
          <div>
            Webhook URLをコピーしてLINE Official Account
            Managerのアカウントに登録してください。
          </div>
          <div className="flex items-center">
            <div className="font-bold">{webhookUrl}</div>
            <Button
              type="link"
              size="small"
              icon="copy"
              onClick={async () => {
                if (navigator.clipboard) {
                  await navigator.clipboard.writeText(webhookUrl);
                }
              }}
            />
          </div>
        </>
      ) : (
        <>
          <SecureInput
            label="Channel ID"
            value={channelId}
            setValue={setChannelId}
          />
          <SecureInput
            label="Channel Secret"
            value={channelSecret}
            setValue={setChannelSecret}
          />
          {error && <Alert type="error" message={error} className="!mx-4" />}
          <Buttons
            buttons={[
              {
                type: 'danger',
                label: 'キャンセル',
                onClick: onCancel,
                disabled: adding,
              },
              {
                type: 'primary',
                label: '追加',
                onClick: onClickAdd,
                disabled: adding || !channelId || !channelSecret,
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

type InputProps = {
  label: string;
  value: string;
  setValue: (value: string) => void;
};

const SecureInput = ({ label, value, setValue }: InputProps): JSX.Element => {
  return (
    <div className="!px-4">
      <Heading level={3} className="!mb-1">
        {label}
      </Heading>
      <DefaultInput
        type="password"
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        className="!mb-4"
      />
    </div>
  );
};

type ButtonsType = {
  buttons: {
    type: 'primary' | 'danger';
    label: string;
    onClick: () => void;
    disabled: boolean;
  }[];
};

const Buttons = ({ buttons }: ButtonsType) => {
  return (
    <div className="mt-4 flex justify-center gap-4">
      {buttons.map((b, i) => (
        <DefaultButton key={i} {...b} className="!h-10 !w-28">
          {b.label}
        </DefaultButton>
      ))}
    </div>
  );
};
