import { IconButton } from 'components/basics';
import { PCTooltip } from '../../Common/Tooltip';
import { isSP } from 'shared/util';
import { Dropdown, Icon, Menu, Modal } from 'antd';
import { PCOnly } from 'App/Common/MediaQuery';
import CreateMessageSubmitButton, {
  Props as SubmitButtonPropsType,
} from './CreateMessageSubmitButton';
import {
  AttachFileAdd,
  Delete,
  Description,
  Format,
  More,
} from 'components/icons';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Draft } from '../../../firestore/entity/draft';

const { confirm } = Modal;

export enum ToolbarItem {
  ToggleHtml,
  ShowTemplateModal,
  ShowUploadDialog,
  Delete,
  PlainTextMode,
}

type Props = {
  draft: Draft;
  isUpdating: boolean;

  /** 別ウィンドウで編集中の場合はTRUE */
  inModal?: boolean;
  updatedAt: any;
  submitting: boolean;
  htmlToolbarVisible: boolean;
  onClickToolbar: (toolbarItem: ToolbarItem) => void;
  plainTextMode: boolean;
  submitButtonProps: SubmitButtonPropsType;
  cancelFollowup: () => void;
};

const CreateMessageFooter = ({
  draft,
  isUpdating,
  updatedAt,
  submitting,
  htmlToolbarVisible,
  onClickToolbar,
  plainTextMode,
  submitButtonProps,
  cancelFollowup,
}: Props) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <PCTooltip title="装飾">
          <IconButton
            color="sumi"
            onClick={() => onClickToolbar(ToolbarItem.ToggleHtml)}
            component={Format}
            className={htmlToolbarVisible ? 'text-sea-500' : ''}
            size="lg"
            disabled={submitting}
          />
        </PCTooltip>
        <PCTooltip title="テンプレート">
          <IconButton
            color="sumi"
            onClick={() => onClickToolbar(ToolbarItem.ShowTemplateModal)}
            component={Description}
            size="lg"
            disabled={submitting}
          />
        </PCTooltip>
        {!isSP() && (
          <PCTooltip title="添付ファイルを追加">
            <IconButton
              color="sumi"
              onClick={() => onClickToolbar(ToolbarItem.ShowUploadDialog)}
              component={AttachFileAdd}
              size="lg"
              disabled={submitting}
            />
          </PCTooltip>
        )}
        <PCTooltip title="下書きを削除">
          <IconButton
            color="sumi"
            onClick={() => onClickToolbar(ToolbarItem.Delete)}
            component={Delete}
            size="lg"
            disabled={submitting}
            aria-label="下書きを削除"
          />
        </PCTooltip>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="0"
                onClick={() => onClickToolbar(ToolbarItem.PlainTextMode)}
              >
                {plainTextMode && <Icon type="check" />}
                プレーンテキストモード
              </Menu.Item>
              {isSP() && (
                <Menu.Item
                  key="4"
                  onClick={() => onClickToolbar(ToolbarItem.ShowUploadDialog)}
                >
                  添付ファイルを追加
                </Menu.Item>
              )}
            </Menu>
          }
          trigger={['click']}
          placement="topRight"
        >
          <IconButton
            color="sumi"
            component={More}
            size="lg"
            disabled={submitting}
          />
        </Dropdown>
        {draft.followupAt && (
          <span
            className="flex items-center gap-1"
            onClick={() =>
              confirm({
                title: 'フォローアップをキャンセルしますか？',
                onOk: cancelFollowup,
                okText: 'フォローアップをキャンセル',
                cancelText: 'そのままにする',
              })
            }
          >
            <InfoCircleOutlined />
            {moment(draft.followupAt.toMillis()).format('YYYY年M月D日 HH:mm')}
            にフォローアップします
          </span>
        )}
      </div>

      <div className="flex items-center gap-4">
        <PCOnly>
          <div className="text-sm text-sumi-600">
            {isUpdating
              ? '下書きを保存中...'
              : updatedAt && `${updatedAt.format('HH:mm')}に自動保存しました`}
          </div>
        </PCOnly>

        <CreateMessageSubmitButton {...submitButtonProps} />
      </div>
    </div>
  );
};

export default CreateMessageFooter;
