import { CaretLeft } from 'components/icons';
import React, { ReactNode } from 'react';
import { Icon } from '../../../components/basics';

type ParentProps = {
  children: ReactNode;
  title: string;
  isModalOpen: boolean;
  footerElement?: ReactNode;
  onCancel: () => void;
};

const CreateMesssageToolModal: React.FC<ParentProps> = ({
  children,
  footerElement,
  onCancel,
  title,
  isModalOpen,
}) => {
  return (
    <div
      className={`fixed inset-0 z-50 !m-0 flex h-screen w-full !max-w-full flex-col justify-between !rounded-none bg-white p-4 md:relative md:top-24 md:m-auto ${
        isModalOpen ? 'scale-100' : 'scale-0'
      } transition duration-300 ease-in-out`}
    >
      <div>
        <button
          onClick={onCancel}
          className="flex cursor-pointer items-center gap-2 bg-transparent !p-0"
        >
          <Icon icon={CaretLeft} />
          <p className="mb-0 text-sm">メールに戻る</p>
        </button>
        <p className="mt-5 text-sm">{title}</p>

        {children}
      </div>
      {footerElement && (
        <div className="-mx-4 gap-2 p-4 pb-0">{footerElement}</div>
      )}
    </div>
  );
};

export default CreateMesssageToolModal;
