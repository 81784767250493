import { Heading } from 'components/basics/Heading/Heading';
import React, { useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { observer } from 'mobx-react';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { Switch } from 'antd';
import Tooltip from '../../Common/Tooltip';

export const YaritoriAI = observer((): JSX.Element => {
  const store = useStore();
  const [alertComplaintEmail, setAlertComplaintEmail] = useState(
    store.company.alertComplaintEmail ?? false
  );

  const updateAlertComplaintEmail = async (checked: boolean) => {
    const prev = alertComplaintEmail;
    setAlertComplaintEmail(checked);
    updateDoc(doc(db, `/companies/${store.signInCompany}`), {
      alertComplaintEmail: checked,
      updatedAt: serverTimestamp(),
    }).catch((e) => {
      setAlertComplaintEmail(prev);
      throw e;
    });
  };

  if (!store.isImpressionClassificationSupported) {
    return <></>;
  }

  const isNotAdmin = !store.me.isAdmin;

  return (
    <div className="p-8">
      <Heading level={1}>yaritori AI</Heading>
      <div className="flex items-center text-sm">
        クレームメールアラート
        <Tooltip title="権限がありません" visible={isNotAdmin}>
          <Switch
            checked={alertComplaintEmail}
            onChange={updateAlertComplaintEmail}
            className="ml-3"
            disabled={isNotAdmin}
          />
        </Tooltip>
      </div>
    </div>
  );
});
