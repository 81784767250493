import React from 'react';
import { observer } from 'mobx-react';
import { PCSplitPane } from './PCSplitPane';
import { PageWithMainNav } from 'App/Common/MainNav';
import { MessageTabs } from './status/MessageTabs';
import { MessagePanes } from './MessagePanes';
import { Route, Switch } from 'react-router-dom';
import { isSP } from 'shared/util';
import { MSG_LIST_PATHS } from 'hooks/useMsgRouteMatch';
import { FilterDisplay } from './FilterDisplay';
import { PCOnly, TabletOnly } from '../Common/MediaQuery';
import { LeftSidebar } from './LeftSidebar/LeftSidebar';
import { SidebarSearchOverlayWithLogic } from '../../components/sidebar/SidebarSearchOverlay/SidebarSearchOverlayWithLogic';
import CreateDraftButton from './LeftSidebar/CreateDraftButton';

export const PCOrTabletTop: React.FC = observer(() => {
  const content = (
    <div className="flex h-full w-full flex-col bg-sumi-50">
      <Switch>
        <Route path="/search/:tray/:query" exact={isSP()}>
          <FilterDisplay />
        </Route>
        <Route path={MSG_LIST_PATHS} exact={isSP()}>
          <div className="h-tab bg-white">
            <MessageTabs />
          </div>
        </Route>
      </Switch>
      <div className="relative h-full">
        <MessagePanes />
      </div>
    </div>
  );
  return (
    <PageWithMainNav>
      <div className="relative h-full">
        <PCOnly>
          <PCSplitPane
            defaultWidth={Math.min(Math.round(0.2 * window.innerWidth), 240)}
            maxWidth={320}
            minWidth={200}
            preferenceKey="OuterSplitPane"
          >
            <div className="grid h-full grid-rows-[auto_1fr]">
              <div className="sticky top-0 z-20 p-4">
                <CreateDraftButton shadow={false} />
              </div>
              <SidebarSearchOverlayWithLogic>
                <LeftSidebar />
              </SidebarSearchOverlayWithLogic>
            </div>
            {content}
          </PCSplitPane>
        </PCOnly>
        <TabletOnly>{content}</TabletOnly>
      </div>
    </PageWithMainNav>
  );
});
