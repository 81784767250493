import { Heading } from 'components/basics/Heading/Heading';
import { SlackIntegration } from './slack';
import { ChatworkIntegration } from './chatwork';
import { IntegrationRoutes } from './IntegrationRoutes';
import { useStore } from 'hooks/useStore';
import { SalesforceIntegration } from './salesforce/SalesforceIntegration';

type Props = {
  routes: IntegrationRoutes;
};

export const Integrations: React.FC<Props> = (props) => {
  const store = useStore();
  return (
    <div className={`p-[32px]`}>
      <Heading level={1}>外部サービス連携</Heading>
      <div className="service mb-[48px]">
        <Heading level={2}>Slack</Heading>
        <SlackIntegration />
      </div>
      {store.isV2Plan && (
        <div className="service mb-[48px]">
          <Heading level={2}>Chatwork</Heading>
          <ChatworkIntegration routes={props.routes} />
        </div>
      )}
      <div className="service mb-[48px]">
        <Heading level={2}>Salesforce</Heading>
        <SalesforceIntegration />
      </div>
    </div>
  );
};
