import { useEffect, useState } from 'react';

import { Heading } from 'components/basics/Heading/Heading';

import { getAuth, onAuthStateChanged, multiFactor } from 'firebase/auth';
import { EnrollMFAPhoneNumberForm } from './EnrollMFAPhoneNumberForm';
import { ListEnrolledFactors } from './ListEnrolledFactors';
import { VerifyEmailForm } from './VerifyEmailForm';

/**
 * @typedef {object} MFASettingProps
 * @prop {import('firebase/auth').User=} user
 * @prop {import('firebase/auth').MultiFactorUser=} multiFactorUser
 */

/**
 * @param {MFASettingProps} props
 * @returns {JSX.Element | null}
 */
export const MFASetting = (props) => {
  const [currentUser, setCurrentUser] = useState(props.user ?? null);
  const [multiFactorUser, setMultiFactorUser] = useState(
    props?.multiFactorUser ?? null
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      setCurrentUser(user);
      setMultiFactorUser(user ? multiFactor(user) : null);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <div className="mb-[30px]">
      <Heading level={2}>2段階認証</Heading>
      <VerifyEmailForm
        currentUser={currentUser}
        disabled={currentUser?.emailVerified}
      />
      <ListEnrolledFactors
        multiFactorUser={multiFactorUser}
        disabled={!currentUser?.emailVerified}
      />
      <EnrollMFAPhoneNumberForm
        currentUser={currentUser}
        disabled={!currentUser?.emailVerified}
      />
    </div>
  );
};
