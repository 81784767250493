import {
  atomFamilyWithLRU,
  atomWithPaginate,
  Paginate,
  PaginateAtom,
} from '../../utils/atom';
import { getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { companyCollection } from '../../firestore';
import { store } from '../../providers/StoreProvider';
import { isEqual } from 'lodash';
import { Memo, memoConverter } from '../../firestore/entity/memo';
import { atomFamily, loadable } from 'jotai/utils';
import { atom } from 'jotai';

export const memosFamily = atomFamilyWithLRU<
  { contactId: string; teamId: string },
  Paginate<Memo>,
  PaginateAtom<Memo>
>({
  initializeAtom: (filter) => {
    return atomWithPaginate(
      query(
        companyCollection(`contacts/${filter.contactId}/memos`, memoConverter),
        where('teamId', '==', filter.teamId),
        orderBy('createdAt', 'desc')
      ),
      (snapshot, prev) => {
        snapshot.docChanges().forEach((change) => {
          const message = change.doc.data();
          switch (change.type) {
            case 'added':
              prev.push(message);
              break;
            case 'modified':
              prev.splice(
                prev.findIndex((m) => m.id === message.id),
                1,
                message
              );
              break;
            case 'removed':
              prev.splice(
                prev.findIndex((m) => m.id === message.id),
                1
              );
              break;
          }
        });

        return prev.sort(
          (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
        );
      },
      10
    );
  },
  max: 20,
  maxSize: 200,
  sizeCalculator: (value) => {
    if (value.state !== 'hasData') {
      return 0;
    }
    return value.data.length;
  },
  dispose: (anAtom) => store.set(anAtom, 'unsubscribe'),
  areEqual: isEqual,
});

export const recentMemoFamily = atomFamily(
  ({ contactId, teamId }: { contactId: string; teamId: string }) => {
    return loadable(
      atom(async () => {
        try {
          const snapshot = await getDocs(
            query(
              companyCollection(`contacts/${contactId}/memos`, memoConverter),
              where('teamId', '==', teamId),
              orderBy('updatedAt', 'desc'),
              limit(1)
            )
          );
          return snapshot.empty ? undefined : snapshot.docs[0].data();
        } catch (e) {
          console.error(e);
          return undefined;
        }
      })
    );
  },
  isEqual
);

export const recentMemosFamily = atomFamily(
  ({ teamId, contactIds }: { teamId: string; contactIds: string[] }) => {
    return atom((get) => {
      return Object.fromEntries(
        contactIds.map((contactId) => [
          contactId,
          get(recentMemoFamily({ teamId, contactId })),
        ])
      );
    });
  },
  isEqual
);
