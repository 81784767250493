import React, { useState } from 'react';
import { Alert, Button, Col, DatePicker, Row, Statistic, Table } from 'antd';
import { Heading } from 'components/basics/Heading/Heading';
import 'moment/locale/ja';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';
import Avatar from '../Common/Avatar';
import { Comment } from '../Common/Comment';
import { reportMessageByTags, reportSentFunction } from '../../functions';
import * as color from '../../color';
import { PageWithMainNav } from 'App/Common/MainNav';
import {
  ReportMessageByTagsTable,
  TagDataItem,
} from './ReportMessageByTagsTable';
import { useStore } from 'hooks/useStore';
import { useSignInCompanyStripeProduct } from 'atoms/firestore/signInCompanyStripeProduct';

const { RangePicker } = DatePicker;
const { Column } = Table;

const maxDays = 30;

interface User {
  id: number;
  name: string;
  email: string;
}

interface Team {
  id: number;
  name: string;
}

interface Inbox {
  id: number;
  email: string;
}

interface ReportResult {
  users: UserResult[];
  teams: TeamResult[];
  total: {
    new: number;
    reply: number;
  };
}

interface UserResult {
  user: User;
  new: number;
  reply: number;
  sum: number;
}

interface TeamResult {
  team: Team;
  inboxes: InboxResult[];
  new: number;
  reply: number;
  sum: number;
}

interface InboxResult {
  inbox: Inbox;
  new: number;
  reply: number;
  sum: number;
}

const Reports = () => {
  const store: any = useStore(); // fix type
  const now = moment().clone();
  const [dates, setDates] = useState<moment.Moment[]>([]);
  const [value, setValue] = useState<[moment.Moment, moment.Moment]>([
    now.clone().startOf('month'),
    now.clone().subtract(1, 'days'),
  ]);
  const [result, setResult] = useState<ReportResult | null>(null);
  const [resultLoading, setResultLoading] = useState<boolean>(false);
  const [messageByTagsData, setMessageByTagsData] = useState<TagDataItem[]>([]);
  const [stripeProductData] = useSignInCompanyStripeProduct();

  const disabledDate = (current: moment.Moment | null) => {
    if (!current) return false;
    if (current.isAfter(now, 'day')) return true;
    if (dates.length !== 1) return false;

    return Math.abs(current.diff(dates[0], 'days')) > maxDays;
  };

  const createUsersResult = (usersData: any) =>
    store.users
      .map((user: User) => ({
        user: user,
        new: usersData[user.id]?.new || 0,
        reply: usersData[user.id]?.reply || 0,
      }))
      .map((result: any) => ({ ...result, sum: result.new + result.reply }));

  const createInboxesResult = (teamId: number, inboxesData: any) =>
    store
      .getTeamInboxes(teamId)
      .map((inbox: Inbox) => ({
        inbox: inbox,
        new: inboxesData[inbox.id]?.new || 0,
        reply: inboxesData[inbox.id]?.reply || 0,
      }))
      .map((result: any) => ({
        ...result,
        sum: result.new + result.reply,
      }));

  const createTeamsResult = (teamsData: any) =>
    store.teams
      .map((team: Team) => ({
        team: team,
        inboxes: createInboxesResult(
          team.id,
          teamsData[team.id]?.inboxes || {}
        ),
      }))
      .map((result: any) => ({
        ...result,
        new: result.inboxes.reduce(
          (count: number, inbox: any) => count + inbox.new,
          0
        ),
        reply: result.inboxes.reduce(
          (count: number, inbox: any) => count + inbox.reply,
          0
        ),
      }))
      .map((result: any) => ({ ...result, sum: result.new + result.reply }));

  const onExec = async () => {
    setResultLoading(true);
    const data = {
      companyId: store.signInCompany,
      startDate: value[0].format('YYYY-MM-DD'),
      endDate: value[1].format('YYYY-MM-DD'),
    };
    try {
      const response = await reportSentFunction(data);
      const reportMessageByTagsResult: TagDataItem[] =
        isSupportReportMessageByTags()
          ? (await reportMessageByTags(data)).data
          : [];

      setResult({
        users: createUsersResult(response.data.users),
        teams: createTeamsResult(response.data.teams),
        total: {
          new: response.data.total.new || 0,
          reply: response.data.total.reply || 0,
        },
      });
      setMessageByTagsData(
        reportMessageByTagsResult.sort((a, b) =>
          (a.teamName ?? '').localeCompare(b.teamName ?? '')
        )
      );
    } catch (e) {
      console.error('Reports.onExec:', e);
    }

    setResultLoading(false);
  };

  const expandedRowRender = (teamRecord: TeamResult) => {
    if (!result || teamRecord.inboxes.length === 0) return null;
    return (
      <Table
        dataSource={teamRecord.inboxes}
        loading={resultLoading}
        pagination={false}
        rowKey={(record) => record.inbox.email}
        style={{ margin: 0 }}
      >
        <Column title="メールアドレス" dataIndex="inbox.email" />
        <Column
          title="新規送信数"
          dataIndex="new"
          sorter={(a: InboxResult, b: InboxResult) => a.new - b.new}
          align="right"
        />
        <Column
          title="返信数"
          dataIndex="reply"
          sorter={(a: InboxResult, b: InboxResult) => a.reply - b.reply}
          align="right"
        />
        <Column
          title="合計"
          dataIndex="sum"
          sorter={(a: InboxResult, b: InboxResult) => a.sum - b.sum}
          defaultSortOrder="descend"
          align="right"
        />
      </Table>
    );
  };

  const isSupportReportMessageByTags = () => {
    if (!stripeProductData) {
      return false;
    }

    return Boolean(stripeProductData.supportReportMessageByTags);
  };

  return (
    <PageWithMainNav>
      <div className="flex h-full">
        <div className="z-0 max-w-full flex-1 overflow-auto py-8 pl-8 pr-4">
          <Alert
            message="レポートについて"
            description={
              <div>
                指定した期間のメール送信数をメンバー・チームごとに集計できる機能です。
              </div>
            }
            type="info"
            style={{ marginBottom: 8 }}
            closable
          />

          <Heading level={1}>レポート</Heading>
          <RangePicker
            size="large"
            locale={locale}
            value={value}
            disabledDate={(current) => disabledDate(current)}
            onCalendarChange={(val: any) => {
              setDates(val || []);
            }}
            onChange={(val: any) => {
              setValue(val || []);
              setDates([]);
            }}
            autoFocus
          />
          <Button
            type="primary"
            size="large"
            disabled={value.length !== 2}
            onClick={onExec}
            loading={resultLoading}
            style={{ marginLeft: 8 }}
          >
            出力
          </Button>
          <Row
            type="flex"
            justify="start"
            gutter={108}
            style={{ marginTop: 48, marginBottom: 48 }}
          >
            <Col>
              <Stat
                value={result ? result.total.new : 0}
                title={'新規送信数'}
              />
            </Col>
            <Col>
              <Stat value={result ? result.total.reply : 0} title={'返信数'} />
            </Col>
            <Col>
              <Stat
                value={result ? result.total.new + result.total.reply : 0}
                title={'合計送信数'}
              />
            </Col>
          </Row>

          <ReportMessageByTagsTable
            loading={resultLoading}
            data={messageByTagsData}
            isSupportReportMessageByTags={isSupportReportMessageByTags()}
          />

          <Heading level={2} style={{ marginTop: 24 }}>
            メンバー別送信数
          </Heading>
          <Table
            dataSource={result?.users}
            loading={resultLoading}
            pagination={false}
            style={{ paddingBottom: 24 }}
            rowKey={(record) => record.user.email}
            bordered
          >
            <Column
              title="メンバー"
              dataIndex="user.name"
              render={(_, record: UserResult) => (
                <div className="flex w-[392px] items-center">
                  <Avatar /> {/* user={record.user} */}
                  <div className="ml-2.5 flex max-w-[300px] flex-col justify-center">
                    <div
                      className={`text-[13px] font-bold text-${color.common.text2}`}
                    >
                      {record.user.name}
                    </div>
                    <Comment>{record.user.email}</Comment>
                  </div>
                </div>
              )}
            />
            <Column
              title="新規送信数"
              dataIndex="new"
              sorter={(a: UserResult, b: UserResult) => a.new - b.new}
              align="right"
            />
            <Column
              title="返信数"
              dataIndex="reply"
              sorter={(a: UserResult, b: UserResult) => a.reply - b.reply}
              align="right"
            />
            <Column
              title="合計"
              dataIndex="sum"
              sorter={(a: UserResult, b: UserResult) => a.sum - b.sum}
              defaultSortOrder="descend"
              align="right"
            />
          </Table>

          <Heading level={2}>チーム別送信数</Heading>
          <Table
            dataSource={result?.teams}
            loading={resultLoading}
            pagination={false}
            rowKey={(record) => record.team?.name}
            expandedRowRender={expandedRowRender}
            bordered
          >
            <Column title="チーム" dataIndex="team.name" />
            <Column
              title="新規送信数"
              dataIndex="new"
              sorter={(a: TeamResult, b: TeamResult) => a.new - b.new}
              align="right"
            />
            <Column
              title="返信数"
              dataIndex="reply"
              sorter={(a: TeamResult, b: TeamResult) => a.reply - b.reply}
              align="right"
            />
            <Column
              title="合計"
              dataIndex="sum"
              sorter={(a: TeamResult, b: TeamResult) => a.sum - b.sum}
              defaultSortOrder="descend"
              align="right"
            />
          </Table>
        </div>
      </div>
    </PageWithMainNav>
  );
};

const Stat: React.FC<{ title: string; value: number }> = ({ title, value }) => (
  <Statistic
    value={value}
    valueStyle={{ textAlign: 'right' }}
    title={title}
    formatter={(value) => value || '-'}
  />
);

export default Reports;
