import { Tooltip } from 'antd';
import { extractEmail, extractNonInlineSentAttachments } from 'lib';
import throttle from 'lodash.throttle';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { v4 as uuidv4 } from 'uuid';
import { eventNames, logEvent } from '../../../analytics';
import * as color from '../../../color';
import { Draft } from './Conversation/Draft';
import firebase, { db } from 'firebase.js';
import { generateStorageAttachmentName } from '../../../util';
import SentSkeleton from '../../Common/Skeleton/sent';
import NotFound from '../../Error/404';
import { uid as rcuid } from '../CreateMessage/rcuid';
import Sent from './Conversation/Sent';
import { uploadFileToStorage } from './util';
import { SPBackButton } from './Conversation/SPBackButton';
import {
  companyCollection,
  companyDoc,
  registerUnsubscribe,
} from '../../../firestore';
import { getDoc, onSnapshot, query, where } from 'firebase/firestore';
import { draftConverter } from '../../../firestore/entity/draft';
import { inboxConverter } from '../../../firestore/entity/inbox';
import { Conversation } from './Conversation/Conversation';

class SentIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addCommentHeight: 0,
      sent: null,
      fetchingSent: true,
      drafts: [],
    };
    this.messageDetailRef = React.createRef();
  }

  componentDidMount() {
    this.setSent();
  }

  componentWillUnmount() {
    this._unsubscribeSent?.();
    this._unsubscribeDrafts?.();
  }

  startReplyToSent = throttle(
    async (sent, replyAll) => {
      const inboxSnapshot = await getDoc(
        companyDoc('inboxes', sent.inboxId, inboxConverter)
      );
      if (!inboxSnapshot.exists()) {
        console.error('Sent.startReplyToSent: !inboxSnapshot.exists:', {
          sentId: sent.id,
          inboxId: sent.inboxId,
        });
        return;
      }
      const inbox = inboxSnapshot.data();

      let to =
        (replyAll
          ? sent.to
          : sent.to.length > 0
          ? sent.to.slice(0, 1)
          : null) || [];

      let cc = [];
      if (replyAll && sent.cc) {
        // toと重複する場合はセットしない
        cc = sent.cc.filter(
          (c) => !to.some((t) => extractEmail(c) === extractEmail(t))
        );
      }

      // 自動cc
      cc = [
        ...cc,
        ...inbox.autoCcs.filter(
          (acc) =>
            !cc.some((c) => extractEmail(c) === acc) &&
            !to.some((t) => extractEmail(acc) === extractEmail(t))
        ),
      ];

      // 自動Bcc
      const bcc = inbox.autoBccs.filter(
        (abcc) =>
          ![...to, ...cc].some((r) => extractEmail(abcc) === extractEmail(r))
      );

      // 下書きを作成する
      await db
        .collection(`companies/${this.props.store.signInCompany}/drafts`)
        .add({
          inboxId: inbox.id,
          teamId: inbox.teamId,
          to,
          cc,
          bcc,
          subject: `${sent.subject.startsWith('Re: ') ? '' : 'Re: '}${
            sent.subject
          }`,
          originalSubject: sent.subject,
          body: '',
          signature:
            this.props.store.getSignature(inbox.defaultSignatureId)
              ?.signature || null,
          useQuote: true,
          attachments: [],
          plainTextMode: this.props.store.me.plainTextMode,
          drafter: this.props.store.me.id,
          inReplyToSentId: sent.id,
          inReplyToSentRef: sent.ref,
          isReply: false, // MEMO: 新規のメッセージとして作成しているため、isReplyはfalseとしている
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });

      logEvent(eventNames.start_reply_to_sent, { isReply: false });
    },
    2000,
    { trailing: false }
  );

  startForwardingToSent = throttle(
    async (sent) => {
      const inboxSnapshot = await getDoc(
        companyDoc('inboxes', sent.inboxId, inboxConverter)
      );
      if (!inboxSnapshot.exists()) {
        console.error('Sent.startForwardingToSent: !inboxSnapshot.exists:', {
          sentId: sent.id,
          inboxId: sent.inboxId,
        });
      }
      const inbox = inboxSnapshot.data();

      // 下書きを作成する（事前にidを取得する）
      const draftRef = db
        .collection(`companies/${this.props.store.signInCompany}/drafts`)
        .doc();

      // 添付ファイルも転送にする
      const attachments = await Promise.all(
        extractNonInlineSentAttachments(sent.attachments).map(
          async (attachment) => {
            const ref = firebase.storage().ref(attachment.storagePath);
            const url = await ref.getDownloadURL();
            const res = await fetch(url);
            const blob = await res.blob();
            const file = new File([blob], attachment.name, {
              type: attachment.type,
            });
            file.uid = rcuid(); // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
            const storagePath = `companies/${
              this.props.store.signInCompany
            }/drafts/${
              draftRef.id
            }/attachments/${uuidv4()}/${generateStorageAttachmentName(
              file.name
            )}`;
            return await uploadFileToStorage(
              file,
              storagePath,
              this.props.store.me.id,
              sent.teamId
            );
          }
        )
      );

      // 自動cc
      const cc = inbox.autoCcs.map((acc) => acc);

      // 自動Bcc
      const bcc = inbox.autoBccs.filter(
        (abcc) => ![...cc].some((r) => extractEmail(abcc) === extractEmail(r))
      );

      // 下書きを作成する
      await draftRef.set({
        inboxId: inbox.id,
        teamId: inbox.teamId,
        to: [],
        cc,
        bcc,
        subject: `Fwd: ${sent.subject}`,
        originalSubject: sent.subject,
        body: '',
        signature:
          this.props.store.getSignature(inbox.defaultSignatureId)?.signature ||
          null,
        useQuote: true,
        attachments,
        plainTextMode: this.props.store.me.plainTextMode,
        drafter: this.props.store.me.id,
        inReplyToSentId: sent.id,
        inReplyToSentRef: sent.ref,
        isReply: false, // MEMO: 新規のメッセージとして作成しているため、isReplyはfalseとしている
        isForwarded: true,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      logEvent(eventNames.start_forwarding_to_sent, { isReply: false });
    },
    2000,
    { trailing: false }
  );

  setSent = () => {
    this.setState({ sent: null, fetchingSent: true });
    const { store, sentId } = this.props;
    this._unsubscribeSent = store.sentStore.syncSent(sentId, (sent) => {
      this.setState({ sent, fetchingSent: false });
      this.syncDrafts(sent);
    });
  };

  syncDrafts(sent) {
    if (sent.inReplyToMessageId) {
      // No need to sync drafts because Conversation will take over.
      return;
    }
    this._unsubscribeDrafts = registerUnsubscribe(
      SentIndex,
      onSnapshot(
        query(
          companyCollection('drafts', draftConverter),
          where('teamId', '==', sent.teamId),
          where('inReplyToSentId', '==', sent.id)
        ),
        (snapshot) =>
          this.setState({ drafts: snapshot.docs.map((doc) => doc.data()) })
      )
    );
  }

  render() {
    const { sentId } = this.props;
    const { sent, fetchingSent, drafts } = this.state;
    if (fetchingSent) {
      return (
        <div
          className={wrapperClassName}
          style={{ backgroundColor: color.sidebar.background.normal }}
        >
          <SentSkeleton />
        </div>
      );
    }

    if (!sent) {
      // 存在しない場合
      return (
        <div
          className={wrapperClassName}
          style={{ backgroundColor: color.sidebar.background.normal }}
        >
          <NotFound />
        </div>
      );
    }

    if (sent.isReply && !sent.isInReplyToMessageDeleted) {
      // 返信かつ返信先が削除されていない場合
      return (
        <Conversation
          threadView={false}
          messageId={sent.inReplyToMessageId}
          sentId={sentId}
          sent={sent}
        />
      );
    }

    // 新規作成もしくは返信先が削除されている場合
    return (
      <div
        className={wrapperClassName}
        style={{ backgroundColor: color.sidebar.background.normal }}
        id="ConversationContainer"
      >
        <div
          className={`sticky top-0 z-[2] p-[10px]`}
          style={{ backgroundColor: color.sidebar.background.normal }}
        >
          <SPBackButton backLink="." />
          <div
            className={`subject mt-[8px] overflow-hidden text-ellipsis whitespace-nowrap text-[18px] font-semibold`}
            style={{ color: 'rgba(0, 0, 0, 0.8)' }}
          >
            <Tooltip title={sent.subject}>{sent.subject}</Tooltip>
          </div>
        </div>
        <div className="px-4">
          <Sent
            sent={sent}
            startReply={this.startReplyToSent}
            startForwarding={this.startForwardingToSent}
            me={this.props.store.me}
            key={sent.id}
            isReadOnly={this.props.store.me.isReadOnly}
          />
          {drafts.map((draft) => (
            <Draft draft={draft} key={draft.id} />
          ))}
        </div>
      </div>
    );
  }
}

export default compose(withRouter, inject('store'), observer)(SentIndex);

// TODO: marginはwrapperで指定
const wrapperClassName = `relative flex h-[100vh] w-full flex-1 flex-col overflow-auto md:h-[100vh]`;
