import { Memo } from '../../../firestore/entity/memo';
import { useAtomValue } from 'jotai/index';
import { usersAtom } from '../../../atoms/firestore/user';
import { ContactDrawerMemo } from '../../../components/contact/ContactDrawerMemo/ContactDrawerMemo';

type MemoEntryProps = {
  memo: Memo;
  onEdit: (memoId: string, value: string) => Promise<boolean>;
  onDelete: (memoId: string) => Promise<void>;
};

export const MemoEntry = ({ memo, onEdit, onDelete }: MemoEntryProps) => {
  const users = useAtomValue(usersAtom);
  const user = users.find((u) => u.id === memo.userId);
  return (
    <ContactDrawerMemo
      id={memo.id}
      avatar={{ user }}
      username={user?.name ?? ''}
      text={memo.text}
      timestamp={memo.isOld ? undefined : memo.updatedAt.toDate()}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};
