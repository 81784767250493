import * as React from 'react';
import type { SVGProps } from 'react';
const SvgThumbDownGradient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <rect width={27} height={27} x={0.5} y={0.5} stroke="url(#a)" rx={13.5} />
    <path
      fill="url(#b)"
      d="M6.5 17.333c-.444 0-.833-.166-1.167-.5-.333-.333-.5-.722-.5-1.166V14c0-.097.014-.201.042-.313.028-.11.056-.215.083-.312l2.5-5.875c.125-.278.334-.514.625-.708.292-.195.598-.292.917-.292h9.167v10.833l-5 4.959c-.209.208-.455.33-.74.364a1.373 1.373 0 0 1-.823-.156 1.316 1.316 0 0 1-.583-.583 1.065 1.065 0 0 1-.084-.771l.938-3.813H6.5Zm10-.708V8.167H9L6.5 14v1.667H14l-1.125 4.583 3.625-3.625ZM20.667 6.5c.458 0 .85.163 1.177.49.326.326.49.718.49 1.177v7.5c0 .458-.164.85-.49 1.177-.327.326-.719.49-1.177.49h-2.5v-1.667h2.5v-7.5h-2.5V6.5h2.5Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={14}
        x2={14}
        y1={0}
        y2={28}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={13.583}
        x2={13.583}
        y1={6.5}
        y2={22.667}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgThumbDownGradient;
