import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { Heading } from 'components/basics/Heading/Heading';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Icon,
  Row,
  Skeleton,
  Switch,
  Tooltip,
} from 'antd';
import { getSlackChannelInfoFunction } from '../../../../functions';
import * as color from '../../../../color';
import { useHistory } from 'react-router-dom';
import { hasMissingV2Scopes } from './authorize';
import { Store } from '../../../../store';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { deleteField, serverTimestamp, updateDoc } from 'firebase/firestore';
import { SlackIntegrationInput } from './SlackIntegration/SlackIntegrationInput';
import { useAtomValue } from 'jotai';
import { meAtom } from 'atoms/auth';

type Props = {
  store: Store;
};

export const UserSlackNotification = inject('store')(
  observer((props: Props) => {
    const history = useHistory();

    const {
      signInCompany,
      slackIntegrationsLoading,
      slackIntegration,
      isV2Plan,
      isSlackNotificationV2Supported,
      isSeenNotificationSupported,
    } = props.store;
    const hasSlackIntegration = Boolean(slackIntegration);
    const me = useAtomValue(meAtom);
    const {
      slackNotificationChannelId,
      slackNotificationChannelName,
      slackNotificationEnabled,
      slackNotificationTypes,
    } = me;

    useEffect(() => {
      if (
        slackIntegrationsLoading ||
        !slackIntegration ||
        !slackNotificationChannelId ||
        slackNotificationChannelName
      ) {
        return;
      }

      getSlackChannelInfoFunction({
        companyId: signInCompany,
        channelId: slackNotificationChannelId,
      }).then((res) =>
        updateSlackChannel(slackNotificationChannelId, res.data.name)
      );
    }, [
      slackNotificationChannelId,
      slackNotificationChannelName,
      slackNotificationEnabled,
      signInCompany,
      slackIntegration,
      slackIntegrationsLoading,
    ]);

    const onChangeSlackEnabled = async (enabled: boolean) => {
      await updateSlackOption('enabled', enabled);
    };

    const onChangeSlackTypes = (types: CheckboxValueType[]) =>
      updateSlackOption('types', types);

    const updateSlackOption = (key: string, val: any) =>
      updateDoc(me.ref, {
        [`notificationSettings.slack.${key}`]: val,
        updatedAt: serverTimestamp(),
      });

    const updateSlackChannel = async (
      channelId?: string,
      channelName?: string
    ) => {
      await updateDoc(me.ref, {
        [`notificationSettings.slack.channelId`]: channelId ?? null,
        [`notificationSettings.slack.channelName`]:
          channelName ?? deleteField(),
        updatedAt: serverTimestamp(),
      });
    };

    const reintegrationNeeded =
      !hasSlackIntegration || hasMissingV2Scopes(slackIntegration?.scope);

    return isV2Plan ? (
      <Skeleton loading={slackIntegrationsLoading}>
        <Tooltip
          title={
            !isSlackNotificationV2Supported &&
            'Slack通知は現在のプランではサポートされていません'
          }
        >
          <Heading level={2} className={`mt-[30px] flex items-center`}>
            <Icon type="slack" style={{ marginRight: 4, fontSize: 21 }} />
            Slack通知
            <Switch
              checked={hasSlackIntegration && slackNotificationEnabled}
              onChange={onChangeSlackEnabled}
              style={{ marginLeft: 12 }}
              disabled={!isSlackNotificationV2Supported || reintegrationNeeded}
            />
          </Heading>
          {isSlackNotificationV2Supported && reintegrationNeeded && (
            <Alert
              message={
                <Row type="flex" justify="space-between" align="middle">
                  <Col>
                    {hasSlackIntegration
                      ? '通知に必要な権限の取得のため再連携の必要があります'
                      : 'Slackワークスペースにyaritoriを連携すると通知を設定できます'}
                  </Col>
                  <Col>
                    <Button
                      onClick={() => history.push('/settings/integrations')}
                      type="primary"
                    >
                      連携設定
                    </Button>
                  </Col>
                </Row>
              }
            />
          )}
          <div className={`mt-[20px]`} style={{ color: color.common.text2 }}>
            <Checkbox.Group
              options={[
                { label: '自分が担当者', value: 'assigned' },
                {
                  label: '自分宛て（@メンション）の新規コメント',
                  value: 'mentionedMessage',
                },
                {
                  label: 'メールの開封',
                  value: 'opened',
                  disabled: !isSeenNotificationSupported,
                },
              ]}
              value={slackNotificationTypes}
              onChange={onChangeSlackTypes}
              disabled={!hasSlackIntegration || !slackNotificationEnabled}
            />
          </div>

          {hasSlackIntegration && (
            <div className={`mt-[20px]`} style={{ color: color.common.text2 }}>
              通知先チャンネル
              <SlackIntegrationInput
                channelId={slackNotificationChannelId ?? undefined}
                channelName={slackNotificationChannelName}
                updateChannel={updateSlackChannel}
                resetChannel={updateSlackChannel}
                disabled={!slackNotificationEnabled}
              />
            </div>
          )}
        </Tooltip>
      </Skeleton>
    ) : (
      <></>
    );
  })
) as any as React.FC<Omit<Props, 'store'>>;
