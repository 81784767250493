import React, { useState, useEffect, useCallback } from 'react';
import { Sent, sentConverter } from 'lib';
import Header from '../Message/header';
import Body from './body';
import { companyDoc } from '../../../firestore';
import { getDoc } from 'firebase/firestore';
import { PrintMessageContainer } from '../PrintMessageContainer';
import { useRouteMatch } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import { Loading } from 'components/basics';

export const PrintSentPath = '/print/sent/:sentId';

export const PrintSent: React.FC = () => {
  const match = useRouteMatch<{ sentId: string }>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [sent, setSent] = useState<Sent | null>(null);
  const [loading, setLoading] = useState(true);

  const loadSent = useCallback(async () => {
    const { sentId } = match.params;

    try {
      const sentSnapshot = await getDoc(
        companyDoc('sent', sentId, sentConverter)
      );
      if (!sentSnapshot.exists()) {
        console.error('Message Not Found');
        setErrorMessage('該当のメールが存在しません。');
        return;
      }

      const sentData = sentSnapshot.data();
      setLoading(false);
      setSent(sentData);
    } catch (err: unknown) {
      if (err instanceof FirebaseError && err.code === 'permission-denied') {
        setErrorMessage('アクセスの権限がありません。');
      } else {
        setErrorMessage('予期せぬエラーが発生しました。');
      }
      console.error(err);
    }
  }, [match.params?.sentId]);

  useEffect(() => {
    loadSent();
  }, [loadSent]);

  /**
   * Body コンポーネントの中にデータを読み込む処理があるため、
   * Body コンポーネント内の処理が完了するまで待機してから印刷処理を実行する
   *
   * handleStorageSentLoaded は Body コンポーネント内の処理が完了した際に呼び出される
   */
  const handleStorageSentLoaded = () => {
    setTimeout(() => {
      window.print();
    }, 500);
  };

  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  if (loading) {
    return <Loading />;
  }

  if (!sent) {
    return <div>メッセージが見つかりません</div>;
  }

  return (
    <PrintMessageContainer>
      <Header>{sent.subject}</Header>
      <hr />
      <Body
        sent={sent}
        attachments={sent.attachments}
        onStorageSentLoaded={handleStorageSentLoaded}
      />
    </PrintMessageContainer>
  );
};
