import { Emoji } from '../../basics/Emoji/Emoji';
import { Count } from '../../basics/Count/Count';
import { Tooltip } from '../../basics/Tooltip/Tooltip';
import React, { useCallback } from 'react';
import { tv } from 'tailwind-variants';

type Props = {
  emoji: string;
  count: number;
  onToggle: ((emoji: string) => void) | undefined;
  reactedUsers: { id: string; name: string }[];
  reacted: boolean;
  readonly: boolean;
  disabled: boolean;
};

const reaction = tv({
  base: 'm-0 flex h-7 select-none items-center gap-1 rounded-lg border border-sumi-100 bg-white p-0 px-1.5 text-sm text-sumi-600',
  variants: {
    reacted: {
      true: 'border-sea-100 bg-sea-50',
    },
    readonly: {
      true: 'cursor-default',
      false: 'cursor-pointer hover:border-sumi-300',
    },
    disabled: {
      true: 'cursor-not-allowed opacity-50 hover:border-sumi-100',
    },
  },
  compoundVariants: [
    {
      reacted: true,
      readonly: false,
      className: 'hover:border-sea-200',
    },
  ],
  defaultVariants: {
    readonly: false,
    disabled: false,
  },
});

export const Reaction = ({
  emoji,
  count,
  onToggle,
  reactedUsers,
  reacted,
  readonly,
  disabled,
}: Props) => {
  const onClick = useCallback(() => {
    if (readonly || disabled) {
      return;
    }
    onToggle?.(emoji);
  }, [reacted, disabled, onToggle, emoji]);
  return (
    <Tooltip
      key={emoji}
      content={
        <div className="flex flex-col items-center gap-2">
          <div className="flex h-[48px] w-[48px] items-center justify-center rounded-lg bg-white">
            <Emoji size={36} shortcodes={emoji} />
          </div>
          <div className="break-all text-xs">
            {reactedUsers
              .map((u) => u.name)
              .sort((a, b) => a.localeCompare(b, 'ja'))
              .join(', ')}
            <span className="text-sumi-500"> さんが</span>
            <span> {emoji} </span>
            <span className="text-sumi-500">でリアクションしました</span>
          </div>
        </div>
      }
      className="rounded-lg p-2"
      delayDuration={500}
    >
      <button
        type="button"
        className={reaction({ reacted, readonly, disabled })}
        onClick={onClick}
        disabled={readonly || disabled}
        data-reaction={emoji}
      >
        <Emoji size={18} shortcodes={emoji} />
        <Count value={count} />
      </button>
    </Tooltip>
  );
};
