import { useEffect, useMemo, useState } from 'react';
import { Edit, Person } from 'components/icons';
import { Input } from 'components/forms';
import { ContactTagsInput } from '../../Contacts/Team/ContactTagsInput/ContactTagsInput';
import { TagColor } from 'lib';
import { Tag } from '../../../components/basics/Tag/Tag';
import { RightSidebarCard } from './RightSidebarCard';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import { ContactObject } from '../../../store/contact';

type TagData = {
  name: string;
  color: TagColor | null | undefined;
};

export const BasicInfoSection = observer(() => {
  const store = useStore();
  const selectedContact = store.contactStore.selectedContact;

  if (!selectedContact) {
    return null;
  }

  return <Internal contact={selectedContact} />;
});

const Internal = observer(({ contact }: { contact: ContactObject }) => {
  const store = useStore();
  const [isEditing, setIsEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [tags, setTags] = useState<TagData[]>([]);

  const contactTags = useMemo(
    () => store.getContactTags(contact.teamId),
    [store.contactTags, contact.teamId]
  );

  const handleSave = async () => {
    setSaving(true);
    try {
      await store.getOrCreateContactTags(tags, contact.teamId);
      await store.contactStore.updateSelectedContact({
        ...contact,
        tags: tags.map((t) => t.name),
        companyName,
        phoneNumber,
      });
      setIsEditing(false);
    } catch (e) {
      console.error(e);
    } finally {
      setSaving(false);
    }
  };
  useEffect(() => {
    const tags = contact.tags?.map((name) => ({
      name,
      color: contactTags.find((t) => t.name === name)?.color,
    }));
    setTags(tags ?? []);
    setCompanyName(contact.companyName);
    setPhoneNumber(contact.phoneNumber);
  }, [contact.tags, contact.companyName, contact.phoneNumber, contactTags]);

  const sortedContactTags = useMemo(
    () =>
      contactTags.slice().sort((a, b) => a.name.localeCompare(b.name, 'ja')),
    [contactTags]
  );

  const tagsInput = useMemo(() => {
    return (
      <ContactTagsInput
        tags={sortedContactTags.map((t) => ({ name: t.name, color: t.color }))}
        value={tags}
        onChange={setTags}
        disabled={saving}
      />
    );
  }, [sortedContactTags, tags, saving]);

  return (
    <RightSidebarCard
      icon={Person}
      title="基本情報"
      rightSection={{
        icon: Edit,
        label: isEditing ? '保存' : '編集',
        action: () => {
          if (isEditing) {
            handleSave().then();
            return false;
          }
          setIsEditing(true);
          return true;
        },
      }}
    >
      <div className="grid grid-cols-[1fr_2fr] items-center gap-4">
        <span className="whitespace-nowrap">会社名</span>
        {isEditing ? (
          <Input
            type="text"
            name="companyName"
            placeholder="入力してください"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="h-[36px] rounded border px-2 py-1"
            disabled={saving}
          />
        ) : (
          <ValueField value={companyName} />
        )}

        <span className="whitespace-nowrap">メールアドレス</span>
        <ValueField value={contact.email} />
        <span className="whitespace-nowrap">電話番号</span>
        {isEditing ? (
          <Input
            type="text"
            name="phone"
            placeholder="入力してください"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="h-[36px] rounded border px-2 py-1"
            disabled={saving}
          />
        ) : (
          <ValueField value={phoneNumber} />
        )}

        <span className="whitespace-nowrap">タグ</span>
        {isEditing ? (
          tagsInput
        ) : (
          <div className="flex flex-wrap gap-1">
            {tags.map((tag) => (
              <Tag key={tag.name} color={tag.color}>
                {tag.name}
              </Tag>
            ))}
          </div>
        )}
      </div>
    </RightSidebarCard>
  );
});

const ValueField = ({ value }: { value: string | undefined }) => {
  return value ? (
    <span className="truncate" title={value}>
      {value}
    </span>
  ) : (
    <span className="text-sumi-600">---</span>
  );
};
