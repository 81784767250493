import React, { ComponentProps, useCallback, useState } from 'react';

type Props = Pick<ComponentProps<'button'>, 'onClick'>;

export const RightSidebarCardMoreButton = ({ onClick }: Props) => {
  const [loading, setLoading] = useState(false);
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        setLoading(true);
        onClick?.(e);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [onClick]
  );
  return (
    <button
      type="button"
      onClick={handleClick}
      className="m-0 flex h-6 cursor-pointer items-center bg-transparent p-0 text-xs text-sea-500 hover:underline"
      disabled={loading}
    >
      もっと見る
    </button>
  );
};
