import { observer } from 'mobx-react';
import Contact from './contact';
import { useStore } from 'hooks/useStore';

export const RightSidebar = observer(() => {
  const { contactStore } = useStore();
  const { selectedContact } = contactStore;

  if (!selectedContact) return <div />;

  return <Contact contact={selectedContact} />;
});
