import { useCallback } from 'react';
import { Mail } from 'components/icons';
import { RightSidebarMessage } from './RightSidebarMessage';
import { useAtom } from 'jotai';
import { RightSidebarCard } from './RightSidebarCard';
import { processHistoryMessagesFamily } from '../../../atoms/firestore/message';
import { RightSidebarCardMoreButton } from './RightSidebarCardMoreButton';
import { Loading } from '../../../components/basics';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';
import { ContactObject } from '../../../store/contact';

export const InteractionHistorySection = observer(() => {
  const store = useStore();
  const contact = store.contactStore.selectedContact;

  return (
    <RightSidebarCard icon={Mail} title="対応履歴">
      {contact ? <Internal contact={contact} /> : <Pending key="pending" />}
    </RightSidebarCard>
  );
});

const Internal = ({ contact }: { contact: ContactObject }) => {
  const [messages, dispatch] = useAtom(
    processHistoryMessagesFamily({
      teamId: contact.teamId,
      email: contact.email,
    })
  );

  const loadMore = useCallback(() => dispatch('loadMore'), [dispatch]);

  if (messages.state === 'loading') {
    return <Pending key="pending" />;
  }

  if (messages.state === 'hasError') {
    return <div>ERROR</div>;
  }

  return (
    <div className="-mt-3 flex flex-col">
      {messages.data.map((message) => (
        <RightSidebarMessage key={message.id} message={message} />
      ))}
      {messages.hasMore && (
        <div className="mt-3">
          <RightSidebarCardMoreButton onClick={loadMore} />
        </div>
      )}
    </div>
  );
};

const Pending = () => {
  return (
    <div className="flex justify-center p-12">
      <Loading size={24} />
    </div>
  );
};
