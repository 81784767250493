import * as React from 'react';
import type { SVGProps } from 'react';
const SvgReplyAll = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m4.15 10.9 4.3 4.3c.15.15.22.325.212.525a.767.767 0 0 1-.237.525.734.734 0 0 1-.538.225.734.734 0 0 1-.537-.225l-4.825-4.825a.762.762 0 0 1-.175-.25.734.734 0 0 1-.05-.275c0-.1.016-.192.05-.275a.762.762 0 0 1 .175-.25l4.85-4.85c.15-.15.329-.225.537-.225.209 0 .388.075.538.225.15.15.225.329.225.537a.734.734 0 0 1-.225.538l-4.3 4.3Zm5.25.75 3.55 3.55c.15.15.22.325.212.525a.767.767 0 0 1-.237.525.734.734 0 0 1-.538.225.734.734 0 0 1-.537-.225l-4.825-4.825a.762.762 0 0 1-.175-.25.734.734 0 0 1-.05-.275c0-.1.016-.192.05-.275a.762.762 0 0 1 .175-.25l4.85-4.85c.15-.15.329-.225.537-.225.209 0 .388.075.538.225.15.15.225.329.225.537a.734.734 0 0 1-.225.538L9.4 10.15h7.625c1.417 0 2.596.47 3.537 1.412.942.942 1.413 2.121 1.413 3.538v3.15a.728.728 0 0 1-.75.75.728.728 0 0 1-.75-.75V15.1c0-1-.325-1.825-.975-2.475-.65-.65-1.475-.975-2.475-.975H9.4Z"
    />
  </svg>
);
export default SvgReplyAll;
