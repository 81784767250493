import * as React from 'react';
import type { SVGProps } from 'react';
const SvgYaritoriAiGradient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="url(#a)" d="M20.063 6v11.772h-3.06V6h3.06Z" />
    <path
      fill="url(#b)"
      d="M10.886 13.4 9.511 9.482 8.138 13.4h2.748Zm.811 2.327H7.326l-.703 2.045H3.36L7.841 6h3.341l4.48 11.772H12.4l-.703-2.045Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={18.532}
        x2={18.532}
        y1={6}
        y2={17.772}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={9.512}
        x2={9.512}
        y1={6}
        y2={17.772}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgYaritoriAiGradient;
