import React from 'react';
import LogoImageSrc from '../../assets/logos/logo_wide_fullcolor_RGB.png';
import { useAtomValue } from 'jotai';
import { meAtom } from 'atoms/auth';

type Props = {
  children: React.ReactNode;
};

export const PrintMessageContainer: React.FC<Props> = ({ children }) => {
  const me = useAtomValue(meAtom);

  return (
    <div className="m-[8px] w-full bg-white">
      <div className="flex items-center justify-between pb-[12px] pt-[12px]">
        <div className="flex items-center">
          <img alt="" className="h-[40px]" src={LogoImageSrc} />
        </div>
        <p className="font-bold text-gray-500">{`${me.name} <${me.email}>`}</p>
      </div>
      <hr />
      {children}
    </div>
  );
};
