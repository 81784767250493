import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Heading } from 'components/basics/Heading/Heading';
import { DefaultButton } from '../../Common/Button';
import { GroupModal } from './Group/GroupModal';
import { useStore } from '../../../hooks/useStore';
import { Group } from './Group';
import { Group as GroupEntity } from '../../../store/entity';
import Tooltip from '../../Common/Tooltip';
import { isPermitAddUserGroup } from 'atoms/firestore/companyPermission';
import { companyAtom } from 'atoms/auth';
import { useAtomValue } from 'jotai';
import { signInCompanyStripeProductAtom } from '../../../atoms/firestore/signInCompanyStripeProduct';

export const Groups = observer(() => {
  const [modalGroup, setModalGroup] = useState<GroupEntity>();
  const [modalVisible, setModalVisible] = useState(false);
  const company = useAtomValue(companyAtom);
  const product = useAtomValue(signInCompanyStripeProductAtom);

  const { me, groupStore } = useStore();
  const { groups } = groupStore;
  const sortedGroups = groups
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name, 'ja'));

  const isAdmin = me.isAdmin;

  const openModal = (group?: GroupEntity) => {
    setModalGroup(group);
    setModalVisible(true);
  };

  const isPermitted = isPermitAddUserGroup(company, groups.length);

  let tooltipTitle = null;
  if (product?.maxUserGroups === 0) {
    tooltipTitle =
      'プランをビジネスにアップグレードすると、グループを作成できます。';
  } else if (!isAdmin) {
    tooltipTitle = '権限がありません';
  } else if (!isPermitted) {
    tooltipTitle = '上限に達したため、プランをアップグレードしてください。';
  }

  return (
    <div className={`pt-[32px]`}>
      <div className={`flex items-center`}>
        <Heading level={2}>グループ一覧</Heading>
        <Tooltip title={tooltipTitle} visible={tooltipTitle}>
          <div className="mb-1 ml-[200px]">
            <DefaultButton
              className={`h-[40px] p-[0_30px]`}
              type="primary"
              onClick={() => openModal()}
              disabled={!isAdmin || !isPermitted}
            >
              グループを作成する
            </DefaultButton>
          </div>
        </Tooltip>
      </div>
      {sortedGroups.length ? (
        sortedGroups.map((group) => (
          <Group key={group.id} group={group} onEdit={openModal} />
        ))
      ) : (
        <div className={`mt-[16px]`}>まだグループが作成されていません</div>
      )}
      <GroupModal
        group={modalGroup}
        visible={modalVisible}
        close={() => setModalVisible(false)}
      />
    </div>
  );
});
