import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Form, message, Modal, Result, Tag, Upload } from 'antd';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import firebase from 'firebase.js';
import EditableTagGroup from './editableTagGroup';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  arrayDiff,
  convertNtoBr,
  escapeHtml,
  extractEmail,
  extractName,
  extractNonInlineAttachments,
  extractUsedAttachments,
  Template as TemplateType,
  TemplateAttatchment,
  toRecipients,
} from 'lib';
import { From } from './From';
import Signature from './signature';
import Quote from './quote';
import { sendMessage } from './sendMessageFunc';
import Sending from './sending';
import { PCOnly } from '../../Common/MediaQuery';
import { uid as rcuid } from './rcuid';
import { eventNames, logEvent } from '../../../analytics';
import { generateAttachment } from '../Conversations/util';
import { parseReservedWords } from '../../Common/Editor/util';
import Template from './Template';
import { DatePickerModal } from './DatePickerModal';
import PreviewImageModal, {
  isPreviewableImageMimeType,
} from '../../Common/Modal/previewImageModal';
import {
  downloadAttachment,
  fetchFromPath,
  generateStorageAttachmentName,
  isCmdOrCtrl,
} from '../../../util';
import { formatFileSize } from 'utils/file';
import { createCompletionFunction } from '../../../functions';
import { AiPromptDialog } from '../../Common/Editor/AiPromptDialog/AiPromptDialog';
import {
  deleteDoc,
  DocumentSnapshot,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import CreateMessageFooter, { ToolbarItem } from './CreateMessageFooter';
import { tv } from 'tailwind-variants';
import { Icon } from '../../../components/basics';
import { NoteAdd, OpenInNew, Reply } from '../../../components/icons';
import { TemplateModal } from './Template/TemplateModal';
import { isSP } from 'shared/util';
import media from 'styled-media-query';
import { store as StoreProvider } from '../../../providers/StoreProvider';
import { messagesAtom } from '../../../atoms/firestore/message';
import CreateMessageTopBar, { TopbarItem } from './CreateMessageTopBar';
import SimpleBar from 'simplebar-react';
import { EditorToolbarWithControls } from '../../Common/Editor/EditorToolbar/EditorToolbarWithControls';
import { CreateMessageWysiwygEditor } from './CreateMessageWysiwygEditor';
import { EditorBubbleMenu } from './EditorBubbleMenu';
import { statusesAtomFamily } from 'atoms/firestore/customStatuses';
import {
  buildFrontMessageStatus,
  FrontMessageStatus,
  getNextStatus,
  UnknownFrontMessageStatus,
} from 'utils/frontMessageStatus';
import { mentionedMessagesAtom } from '../../../atoms/firestore/mention';
import { commentedMessagesAtom } from '../../../atoms/firestore/comment';
import { sentAtom } from '../../../atoms/firestore/sent';
import { draftsAtom, modalDraftAtom } from '../../../atoms/firestore/draft';
import { useAtomValue } from 'jotai';
import { EditorHandle } from 'App/Common/Editor/WysiwygEditor/WysiwygEditor';
import { Draft, DraftData } from 'firestore/entity/draft';

const { confirm } = Modal;

const FROM_DROPPABLE_ID = 'from';
const CC_DROPPABLE_ID = 'cc';
const BCC_DROPPABLE_ID = 'bcc';

const editorWrapper = tv({
  base: 'relative -mx-4 flex h-full min-h-[400px] flex-col overflow-hidden focus:shadow sm:pb-0',
  variants: {
    inModal: {
      true: 'h-full',
      false: 'sm:h-[400px] sm:max-h-[400px]',
    },
  },
  defaultVariants: {
    inModal: false,
  },
});

const optionButtons = tv({
  base: 'flex h-8 items-center gap-1',
});

const optionButton = tv({
  base: 'm-0 cursor-pointer rounded-[4px] bg-transparent p-[3px] text-[#8d8d8d] enabled:hover:bg-[#f3f3fa] disabled:cursor-not-allowed disabled:opacity-50',
});

type File = globalThis.File & {
  size: number;
  content_id: string;
  type: string;
  storagePath: string;
  uid: string;
  name: string;
};

type Inbox = {
  name: string;
  email: string;
};

type FileAttatchment = {
  disposition: string;
  contentDisposition: string;
  contentType: string;
  content_id: string;
  uid: string;
  //exist or no
  storagePath?: string;
};

type Message = {
  isReply: boolean;
  id: string;
  teamId: string;
  inboxId: string;
};

type Location = {
  pathname: string;
};

type IndexProps = {
  draft: Draft;
  location: Location;
  store: any;
  match: any;
  deleteCallback?: any;
  onEnter?: any;
  history?: any;
  modalDraft: any;
};

const Index: React.FC<IndexProps> = ({
  draft,
  store,
  location,
  match,
  deleteCallback,
  onEnter,
  history,
  modalDraft,
}) => {
  const [templateVisible, setTemplateVisible] = useState(false);
  const [textCompletionVisible, setTextCompletionVisible] = useState(false);
  //const [textConvertVisible, setTextConvertVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [customToolbarVisible, setCustomToolbarVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [originalInboxId, setOriginalInboxId] = useState(draft.inboxId);
  const [inboxId, setInboxId] = useState(draft.inboxId);
  const [teamId, setTeamId] = useState(draft.teamId);
  const [to, setTo] = useState(draft.to);
  const [cc, setCc] = useState(draft.cc);
  const [bcc, setBcc] = useState(draft.bcc);
  const [subject, setSubject] = useState(draft.subject);
  const [body, SetBody] = useState(draft.body);
  //   TODO: 次のrefactorで解消します。一旦このままにします。
  // SetBodyと同様の理由で、識別のため、大文字のSetSignatureを利用しています
  const [bodyHtml, setBodyHtml] = useState(
    draft.bodyHtml || convertNtoBr(draft.body)
  );
  const [signature, SetSignature] = useState<any | null>(null);
  const [useQuote, setUseQuote] = useState(draft.useQuote);
  const [attachments, setAttachments] = useState<any[]>(draft.attachments);
  const [uploadingAttachments, setUploadingAttachments] = useState<any[]>([]);
  const [updatingAttachments, setUpdatingAttachments] = useState(false);
  const [removingAttachments, setRemovingAttachments] = useState<any[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [updatedAt, setUpdatedAt] = useState<moment.Moment | null>(null);
  const [ccOpen, setCcOpen] = useState(draft.cc.length > 0);
  const [bccOpen, setBccOpen] = useState(draft.bcc.length > 0);
  const [subjectOpen, setSubjectOpen] = useState(
    draft.subject !== `${draft.originalSubject}` &&
      draft.subject !== `Re: ${draft.originalSubject}`
  );
  const [datePickerTarget, setDatePickerTarget] = useState<
    'schedule' | 'followup' | null
  >(null);
  const [datePickerInitialValue, setDatePickerInitialValue] =
    useState<any>(null);
  const [plainTextMode, setPlainTextMode] = useState(draft.plainTextMode);
  const [scheduledAt, setScheduledAt] =
    useState<firebase.firestore.Timestamp | null>(null);
  const [followupAt, setFollowupAt] =
    useState<firebase.firestore.Timestamp | null>(draft.followupAt ?? null);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
  const [dndSource, setDndSource] = useState(null);
  const [dndItem, setDndItem] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [uniqueId] = useState<string>(`_${uuidv4()}`);
  const [ignoreUnsubscribes, setIgnoreUnsubscribes] = useState<
    boolean | undefined | null
  >(null);
  const [toName, setToName] = useState<string | null>(null);
  const [editorHandle, setEditorHandle] = useState<EditorHandle>();
  const [signatureEditorHandle, setSignatureEditorHandle] =
    useState<EditorHandle>();
  const [htmlToolbarVisible, setHtmlToolbarVisible] = useState(false);
  const [lateDeleteAttachments, setLateDeleteAttachments] = useState<any[]>([]);
  const [signatureToolbarVisible, setSignatureToolbarVisible] = useState(false);

  const subjectRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);
  const uploadFieldRef: React.RefObject<HTMLInputElement> =
    useRef<HTMLInputElement>(null);

  useEffect(() => {
    updateDraftDebounced();
  }, [
    subject,
    body,
    bodyHtml,
    signature,
    useQuote,
    htmlToolbarVisible,
    plainTextMode,
    to,
    cc,
    bcc,
    dndSource,
    dndItem,
    followupAt,
    ccOpen,
    bccOpen,
    updatingAttachments,
  ]);

  // 更新
  const updateDraft: () => Promise<void> = async () => {
    setIsUpdating(true);
    const dedup = (recipients: any[]) => [...new Set(recipients)];

    //@ts-expect-error does not match
    await updateDoc(draft.ref, {
      originalInboxId,
      inboxId,
      teamId,
      to: dedup(to),
      cc: dedup(cc),
      bcc: dedup(bcc),
      subject,
      body,
      bodyHtml,
      signature,
      useQuote,
      attachments,
      plainTextMode,
      scheduledAt,
      followupAt,
      ignoreUnsubscribes,
      isSending: false,
      failedAt: null,
      updatedAt: serverTimestamp(),
    });

    setIsUpdating(false);
    setUpdatedAt(moment());
  };

  const updateDraftFuncRef = useRef<() => Promise<void>>();
  const updateDraftTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  updateDraftFuncRef.current = updateDraft;
  const updateDraftDebounced = useMemo(() => {
    const fn = () => {
      setDirty(true);
      clearTimeout(updateDraftTimeoutRef.current);
      updateDraftTimeoutRef.current = setTimeout(async () => {
        await updateDraftFuncRef.current?.();
        setDirty(false);
      }, 600);
    };
    fn.cancel = () => clearTimeout(updateDraftTimeoutRef.current);
    return fn;
  }, []);

  const markDirty = useCallback(() => {
    setDirty(true);
  }, []);

  const unsubscribeDraftRef = useRef<() => void>();
  const _autoFocusTo: boolean = to.length === 0;

  useEffect(() => {
    const fetchSignature = async () => {
      await fetchToAddressContactName(draft.to);
      const parsedSignature = parseSignature(
        draft.to,
        draft.inboxId,
        draft.signature
      );
      setSignature(parsedSignature);
    };

    fetchSignature();

    const diff = moment().diff(moment(draft.updatedAt.toDate()), 'minutes');
    if (draft.isSending && diff >= 10) {
      updateDoc(draft.ref, {
        isSending: false,
        updatedAt: serverTimestamp(),
      });
    }

    if (!subject) {
      focusSubject();
      return;
    }

    focusBody();

    return () => {
      updateDraftDebounced.cancel();
      unsubscribeDraftRef.current?.();
      deleteLateDeleteAttachments();
    };
  }, []);

  //find any
  const parseSignature = (to: string[], inboxId: string, signature: any) => {
    // @NOTE
    // 下書きのURLを直指定で開くとInboxがundefinedしてエラーになるので
    // Inboxがundefinedなときはskipする
    if (!signature || !store.getInbox(inboxId)) return null;
    return {
      ...signature,
      body: _parseReservedWords(to, inboxId, signature.body),
      bodyHtml: _parseReservedWords(to, inboxId, signature.bodyHtml),
    };
  };

  // inbox変更する場合にccとbccを再生成する
  const createCcAndBcc = (currentInboxId: string) => {
    const prevInboxId = inboxId;
    const prevInbox = store.getInbox(prevInboxId);
    const currentInbox = store.getInbox(currentInboxId);

    //const { to, cc, bcc } = this.state;
    // 追加されていた自動ccを削除
    const removedAutoCc = cc.filter(
      (c: any) =>
        !prevInbox.autoCcs.some((acc: string) => extractEmail(c) === acc)
    );

    // 追加されていた自動bccを削除
    const removedAutoBcc = bcc.filter(
      (b: any) =>
        !prevInbox.autoBccs.some((abcc: string) => extractEmail(b) === abcc)
    );

    const currentCc = [
      ...removedAutoCc,
      ...currentInbox.autoCcs.filter(
        (acc: string) =>
          ![...removedAutoCc, ...to, ...removedAutoBcc].some(
            (nc) => extractEmail(nc) === acc
          )
      ),
    ];

    const currentBcc = [
      ...removedAutoBcc,
      ...currentInbox.autoBccs.filter(
        (ac: string) =>
          ![...removedAutoBcc, ...to, ...currentCc].some(
            (nc) => extractEmail(nc) === ac
          )
      ),
    ];
    return [currentCc, currentBcc];
  };

  const setInbox = async (inboxIdValue: string, teamIdValue: string) => {
    const prevInboxId = inboxId;
    const prevTeamId = teamId;

    if (prevInboxId === inboxIdValue) return;

    // 自動cc/bccを追加
    const [cc, bcc] = createCcAndBcc(inboxIdValue);
    const teamChanged = prevTeamId !== teamIdValue;
    setInboxId(inboxIdValue);
    setTeamId(teamIdValue);
    setCc(cc);
    setBcc(bcc);
    setCcOpen(cc.length > 0);
    setBccOpen(bcc.length > 0);
    setUpdatingAttachments(teamChanged || updatingAttachments);

    if (!teamChanged) return;

    // 添付ファイルをアップデートする
    //const { attachments } = this.state;
    const updateAttachmentsPromise = attachments.map(async (attachment) => {
      await firebase
        .storage()
        .ref()
        .child(attachment.storagePath!)
        .updateMetadata({ customMetadata: { teamId } });
    });
    await Promise.all(updateAttachmentsPromise);
    setUpdatingAttachments(false);
  };

  const focusSubjectOrBody = () => {
    if (subjectOpen) {
      // 件名が開いている場合は件名にフォーカスする
      focusSubject();
      return;
    }

    // 開いていない場合は本文にフォーカスする
    focusBody();
  };

  const focusSubject = () => {
    subjectRef.current?.focus();
  };

  const focusBody = () => {
    editorHandle?.editor?.commands.focus();
  };

  const onDragStart = (
    { source, address }: { source: any; address: any },
    event: React.DragEvent
  ) => {
    const { x, y } = event.currentTarget.getBoundingClientRect();
    event.dataTransfer.setDragImage(
      event.currentTarget,
      event.pageX - x,
      event.pageY - y
    );
    setDndSource(source);
    setDndItem(address);
  };

  const onDragEnd = (target: string) => {
    if (!dndSource || !dndItem) {
      setDndSource(null);
      setDndItem(null);
      return;
    }

    let newTo = [...to];
    let newCc = [...cc];
    let newBcc = [...bcc];

    // ドロップ元からアドレスを削除
    if (dndSource === FROM_DROPPABLE_ID) {
      newTo = newTo.filter((address) => address !== dndItem);
    }
    if (dndSource === CC_DROPPABLE_ID) {
      newCc = newCc.filter((address) => address !== dndItem);
    }
    if (dndSource === BCC_DROPPABLE_ID) {
      newBcc = newBcc.filter((address) => address !== dndItem);
    }

    // ドロップ先にアドレスを追加
    if (target === FROM_DROPPABLE_ID) {
      newTo = [...newTo, dndItem];
    }
    if (target === CC_DROPPABLE_ID) {
      newCc = [...newCc, dndItem];
    }
    if (target === BCC_DROPPABLE_ID) {
      newBcc = [...newBcc, dndItem];
    }
    setTo(newTo);
    setCc(newCc);
    setBcc(newBcc);
    setDndSource(null);
    setDndItem(null);
  };

  // To,Cc,Bcc内で重複していないかを返す。
  const isDuplicatedRecipients = (
    newValue: string,
    options = { to: true, cc: true, bcc: true }
  ) => {
    const newEmail = extractEmail(newValue);

    // Toに一致しないか
    if (options.to && to.map((t) => extractEmail(t)).indexOf(newEmail) !== -1) {
      // Toに存在する場合
      return true;
    }
    // Ccに一致しない
    if (options.cc && cc.map((c) => extractEmail(c)).indexOf(newEmail) !== -1) {
      // Toに存在する場合
      return true;
    }
    // Bccに一致しないか
    if (
      options.bcc &&
      bcc.map((b) => extractEmail(b)).indexOf(newEmail) !== -1
    ) {
      // Toに存在する場合
      return true;
    }
    return false;
  };

  const getRecipientsLength = () => {
    return to.length + cc.length + bcc.length;
  };

  const setBody = (text: string, html: string) => {
    SetBody(text);
    setBodyHtml(html);
  };

  /**
   * @param handle {EditorHandle}
   * @param file {File}
   * @return {Promise<void>}
   */
  const onInsertImage = async (handle: EditorHandle, file: File) => {
    const url = await onUploadImage(file);
    if (!url) {
      console.error('Failed to upload image');
      return;
    }

    handle.insertImage(url, file.content_id);

    setBodyHtml(handle.editor!.getHTML());
  };

  /**
   * @param {moment.Moment} at
   * @param {boolean|undefined} ignoreUnsubscribes
   * @return {Promise<void>}
   */
  const handleSchedule = async (
    at: moment.Moment,
    ignoreUnsubscribes: boolean | undefined
  ): Promise<void> => {
    const scheduledAt = firebase.firestore.Timestamp.fromDate(at.toDate());
    if (ignoreUnsubscribes != null) {
      setIgnoreUnsubscribes(ignoreUnsubscribes);
    }

    setScheduledAt(scheduledAt);
  };

  useEffect(() => {
    if (!scheduledAt) {
      return;
    }

    (async () => {
      await deleteLateDeleteAttachments();
      await updateDraft();
      if (deleteCallback) deleteCallback();

      if (draft.isReply) {
        await store.companyCollection('events').add({
          user: store.me.id,
          name: store.me.name,
          teamId: draft.teamId,
          messageId: draft.inReplyToMessageId,
          type: `draft:schedule${draft.isForwarded ? ':forward' : ''}`,
          text: `${store.me.name}が送信予約を行いました。送信予定: ${moment(
            scheduledAt?.toDate()
          ).format('M月D日 HH:mm')}`,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }

      message.success('送信を予約しました。');
    })();
  }, [scheduledAt]);

  const handleFollowup = async (at: moment.Moment | null) => {
    const followupAt = at
      ? firebase.firestore.Timestamp.fromDate(at.toDate())
      : null;

    setFollowupAt(followupAt);
  };

  /**
   * @param {moment.Moment} at
   * @param {boolean} ignoreUnsubscribes
   * @param {string} source
   * @return {Promise<void>}
   */
  const handleDatePicker = async (
    at: moment.Moment,
    ignoreUnsubscribes: boolean,
    source = 'schedule'
  ): Promise<void> => {
    switch (datePickerTarget || source) {
      case 'followup':
        return handleFollowup(at);
      case 'schedule':
        return handleSchedule(at, ignoreUnsubscribes);
      default:
        break;
    }
  };

  /**
   * @param e {KeyboardEvent}
   * @return {Promise<void>}
   */
  const onKeyDownInSubject = async (e: React.KeyboardEvent): Promise<void> => {
    if (e.key !== 'Enter') {
      return;
    }
    e.preventDefault(); // 本文にフォーカスした際、改行が入ることを防いでいる
    if (isCmdOrCtrl(e)) {
      // win/mac
      // ctrl/cmd + Enter
      await handleSubmitWithNextStatus();
    }
  };

  const setSignature = (signature: any | null) => {
    if (signature === null) {
      SetSignature(signature);
      return;
    }
    const newSignature = {
      ...signature,
      ...{
        bodyHtml: `${
          parseReservedWordsByBody(signature.bodyHtml, {
            escape: true,
          }) ||
          parseReservedWordsByBody(convertNtoBr(escapeHtml(signature.body)), {
            escape: true,
          })
        }`,
        body: parseReservedWordsByBody(signature.body),
      },
    };
    SetSignature(newSignature);
    signatureEditorHandle?.setHtml(newSignature.bodyHtml);
  };

  /**
   * @param text {string}
   * @param html {string}
   */
  const setSignatureBody = (text: string, html: string) => {
    SetSignature(() => ({ ...signature, body: text, bodyHtml: html }));
  };

  const addSignature = () => {
    const inbox = store.getInbox(inboxId);
    setSignature(
      store.getSignature(inbox.defaultSignatureId)?.signature ||
        store.getFirstSignature(teamId)?.signature ||
        null
    );
  };

  const removeSignature = () => {
    setSignature(null);
    hideCustomToolbar();
  };

  const addQuote = () => {
    setUseQuote(true);
  };

  const removeQuote = () => {
    setUseQuote(false);
  };

  //TODO find any
  const parseReservedWordsByBody = (body: any, parseOptions = {}) => {
    return _parseReservedWords(to, inboxId, body, parseOptions);
  };

  const getParseItems = (to: string[], inbox: Inbox) => {
    let toNameValue = to.length > 0 ? extractName(to[0]) : null;
    if (toName) {
      toNameValue = toName;
    }
    return {
      to: {
        name: toNameValue,
        email: to.length > 0 ? extractEmail(to[0]) : null,
      },
      me: {
        name: store.me.name,
        email: store.me.email,
      },
      from: {
        name: inbox?.name,
        email: inbox?.email,
      },
    };
  };

  //TODO find any
  const _parseReservedWords = (
    to: string[],
    inboxId: string,
    body: any,
    parseOptions = {}
  ): any => {
    const inbox = store.getInbox(inboxId);
    const parseItems = getParseItems(to, inbox);
    return parseReservedWords(body, parseItems, parseOptions);
  };

  const setTemplate = async (template: TemplateType) => {
    // @NOTE いまTo,Cc,Bccに設定されているメールアドレスは除く
    // たとえば、テンプレートのBccにcontact@onebox.tokyoがある場合でも
    // いま作成中のメールのTo,Cc,Bccにcontact@onebox.tokyoがあったら
    // 追加で設定しないようにしている
    const allAddress = to.concat(cc).concat(bcc).map(extractEmail);
    const templateTo = arrayDiff(toRecipients(template.to), allAddress);
    const templateCc = arrayDiff(toRecipients(template.cc), allAddress);
    const templateBcc = arrayDiff(toRecipients(template.bcc), allAddress);

    // @NOTE 現在設定されている宛先にテンプレートの宛先を結合する
    const newTo = to.concat(templateTo);
    const newCc = cc.concat(templateCc);
    const newBcc = bcc.concat(templateBcc);

    let parsedNewTo = null;

    if (newTo.length > 0) {
      parsedNewTo = await fetchToAddressContactName(newTo);
    }

    const newBodyHtml = `${body.trim().length === 0 ? '' : bodyHtml}${
      parseReservedWordsByBody(template.bodyHtml, {
        overrideToName: parsedNewTo,
        escape: true,
      }) ||
      parseReservedWordsByBody(convertNtoBr(escapeHtml(template.body)), {
        overrideToName: parsedNewTo,
        escape: true,
      })
    }`;

    setTo(newTo);
    setCc(newCc);
    setBcc(newBcc);
    setCcOpen(newCc.length > 0);
    setBccOpen(newBcc.length > 0);
    setSubject(template.subject || subject);
    setSubjectOpen(template.subject ? true : subjectOpen);
    setBodyHtml(newBodyHtml);
    editorHandle?.setHtml(newBodyHtml);
    SetBody(
      body +
        '\n' +
        parseReservedWordsByBody(template.body, {
          overrideToName: parsedNewTo,
        })
    );

    setTemplateVisible(false);
    updateDraftDebounced();

    logEvent(eventNames.apply_template, { template_id: template.id });

    const promises = template.attachments.map(async (attachment) => {
      await uploadTemplateAttachment(attachment);
    });
    await Promise.allSettled(promises);

    // 時間かかるため、添付ファイルが全て更新したら再度ドラフトを更新する
    updateDraftDebounced();
  };

  const replaceImageFromTemplate = (
    newFile: { url: string; content_id: string } | null,
    attachment: TemplateAttatchment
  ) => {
    if (!editorHandle) {
      return;
    }
    if (plainTextMode || !isPreviewableImageMimeType(attachment.type)) {
      return;
    }

    // アップロードが失敗の場合は、アップロード後の画像が返却できないため、newFileがundefinedになる
    const isUploadSuccess = !!newFile;

    if (isUploadSuccess) {
      editorHandle.replaceImage(
        attachment.uid,
        newFile.content_id,
        newFile.url
      );
    } else {
      editorHandle.deleteImage(attachment.uid).then();
    }
  };

  const getFileUrlFromStorage = async (storagePath: string) => {
    try {
      const ref = firebase.storage().ref(storagePath);
      return ref.getDownloadURL();
    } catch (error) {
      console.error('Error processing attachment:', error);
    }
    return null;
  };

  /**
   * テンプレートの添付ファイルをdraftに挿入する際にいくつか要件がある
   *  1. テンプレートに添付されている画像はそのままdraftに挿入してはいけない、
   *     必ずテンプレートの添付画像を複製して再度アップロードしてからdraftに挿入すること
   *     もし、テンプレートの添付画像を複製せず、そのままdraftに挿入した場合、draftを削除の際にテンプレートの添付画像も削除されてしまう
   *  2. テンプレート画像のimgタグは必ずしもattachmentsに存在すると限らない、またはその逆も同様
   *  3. plainTextMode時、テンプレート画像がありの場合の挙動を考慮する
   *  4. 添付ファイルの容量上限を考慮する
   *  5. 添付ファイルの処理が遅いため、UX上で考慮する必要がある
   *  6. 画像はinlineおよびattachmentの両方が存在し、両方とも異なり、それぞれの処理を考慮する
   *
   */
  const uploadTemplateAttachment = async (
    attachment: TemplateAttatchment
  ): Promise<void> => {
    // Firebase Storageではコピーできないので、一度BlobとしてダウンロードしてからFileへ変換する
    const key = uuidv4();
    message.loading({
      content: `添付ファイル ${attachment.name} を取得中です`,
      key,
    });

    let response: Response | null = null;
    const url = await getFileUrlFromStorage(attachment.storagePath);
    if (url) {
      response = await fetch(url);
    }

    if (!response || !response.ok) {
      message.error({
        content: `添付ファイル ${attachment.name} の取得に失敗しました`,
        key,
      });
      return;
    }

    message.success({
      content: `添付ファイル ${attachment.name} を取得しました`,
      key,
      duration: 1,
    });

    const file: any = new File([await response.blob()], attachment.name);

    const isInline = (await editorHandle?.hasImage(attachment.uid)) || false;
    if (isInline) {
      // onUploadImage メソッドを使うと、attachmentsに追加される添付ファイルのcontentDispositionがinlineになる
      const newUrl = await onUploadImage(file);

      if (!newUrl) {
        console.error('Failed to upload inline image from template');
        message.error({
          content: `添付ファイル ${attachment.name} のアップロードに失敗しました`,
          key,
        });
        return;
      }

      const newFile = { url: newUrl, content_id: file.content_id };
      replaceImageFromTemplate(newFile, attachment);
      return;
    }

    file.uid = rcuid(); // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している

    // file.uidがあり、かつ、file.content_id がない場合、attachmentsに追加される添付ファイルのcontentDispositionがattachmentになる
    // file.uidがあり、かつ、file.content_id がある場合、attachmentsに追加される添付ファイルのcontentDispositionがinlineになる
    const uploadedFile = await validateFileAndUpload(file);
    if (!uploadedFile) {
      console.error('Failed to upload file from template');
      message.error({
        content: `添付ファイル ${attachment.name} のアップロードに失敗しました`,
        key,
      });
      return;
    }
  };

  const deleteDraft = async () => {
    const { teamId, inReplyToMessageId } = draft;
    if (draft.isReply) {
      // イベントを作成する
      await store.companyCollection('events').add({
        user: store.me.id,
        name: store.me.name,
        teamId: teamId,
        messageId: inReplyToMessageId,
        type: `draft:remove${draft.isForwarded ? ':forward' : ''}`,
        text: `${store.me.name}が${
          draft.isForwarded ? '転送' : '返信'
        }を取り消しました。`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
    // 下書きを削除する
    await deleteDoc(draft.ref);
    logEvent(eventNames.remove_draft);
    if (deleteCallback) deleteCallback();
  };

  // 削除
  const handleDelete = () => {
    confirm({
      title: '下書きを破棄しますか？',
      content: '下書きを破棄すると内容は削除されます。',
      okText: '破棄する',
      cancelText: 'キャンセル',
      async onOk() {
        await deleteDraft();
      },
      onCancel: () => {
        return;
      },
      okType: 'danger',
      maskClosable: true,
    });
  };

  // タブを閉じた場合、メッセージ送信中だと確認ダイアログを出す
  const handleConfirmCloseTab = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue =
      'メールの送信中です。ウィンドウもしくはタブを閉じるとメールが送信されませんがよろしいですか？';
  };

  // 送信後の遷移先リンク
  /**
   * @param message {Object|null}
   * @param isThread {boolean}
   * @return {string|null}
   */
  const toLink = (message: Message | null, isThread: boolean) => {
    if (location.pathname.startsWith('/me/drafts')) {
      if (!message) return '/me/drafts';
      return message.isReply
        ? `/me/drafts/${message.id}/teams/${message.teamId}/inboxes/${message.inboxId}`
        : `/me/drafts/${message.id}`;
    }

    const tab = store.messageFilterStore.view || 'messages';

    if (location.pathname.startsWith('/me/assigned')) {
      return message ? `/me/assigned/${tab}/${message.id}` : '/me/assigned';
    }

    const viewModeSuffix = isThread ? '?view=thread' : '';

    const { teamId, inboxId, tagId } = match.params;
    if (teamId && inboxId && tagId) {
      return message
        ? `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/${tab}/${message.id}${viewModeSuffix}`
        : `/teams/${teamId}/inboxes/${inboxId}/tags/${tagId}/${tab}`;
    }

    if (teamId && tagId) {
      return message
        ? `/teams/${teamId}/tags/${tagId}/${tab}/${message.id}${viewModeSuffix}`
        : `/teams/${teamId}/tags/${tagId}/${tab}`;
    }

    if (teamId) {
      return message
        ? `/teams/${teamId}/${tab}/${message.id}${viewModeSuffix}`
        : `/teams/${teamId}/${tab}`;
    }

    return null;
  };

  // 送信
  const handleSubmit = async (
    status: FrontMessageStatus | UnknownFrontMessageStatus | null
  ) => {
    if (!canSend()) return;

    const { draftId } = match.params; // 選択中の下書き

    const { sortedMessages: sortedSearchedMessages, inSearch } =
      store.searchStore;

    const pathname = location.pathname;
    let allMessagesAtom;
    if (pathname.startsWith('/me/mentioned')) {
      allMessagesAtom = mentionedMessagesAtom;
    } else if (pathname.startsWith('/me/commented')) {
      allMessagesAtom = commentedMessagesAtom;
    } else if (pathname.startsWith('/me/sent')) {
      allMessagesAtom = sentAtom;
    } else {
      allMessagesAtom = messagesAtom;
    }
    const allMessages: any = StoreProvider.get(allMessagesAtom as any);

    await updateDraft();

    // 次の未対応のメッセージを表示する。
    let nextLink;
    let selectedMessageId = draft.inReplyToMessageId; // 選択中のメッセージ or 選択中の下書き
    let submitMessageId = draft.inReplyToMessageId; // 送信対象のメッセージ or 送信対象の下書き
    let messages = inSearch ? sortedSearchedMessages : allMessages.data;

    // "me/drafts"の場合メッセージ一覧ではなく下書き一覧なので値を入れ直す。
    const draftsMe = location.pathname.startsWith('/me/drafts');
    if (draftsMe) {
      // 新規作成のモーダルで送信した場合、選択と送信対象の下書きが異なる場合がある
      selectedMessageId = draftId;
      submitMessageId = draft.id;
      messages = (StoreProvider.get(draftsAtom) as any).data;
    } else if (store.isInThreadView && draft.inReplyToMessageRef) {
      // スレッド表示時はthreadIdを使う
      const messageData: any = await getDoc(draft.inReplyToMessageRef);
      selectedMessageId = messageData.threadId;
      submitMessageId = messageData.threadId;
    }
    messages = messages ?? [];

    const inModal = modalDraft && modalDraft.id === draft.id;

    const selectedMessageIndex = messages.findIndex(
      ({ id }: { id: string }) => id === selectedMessageId
    );

    const hasSelectedMessage = selectedMessageIndex !== -1;
    const isSameSelectedAndSubmitMessage =
      selectedMessageId === submitMessageId;

    // メッセージリストが空になる場合
    if (
      !inModal &&
      hasSelectedMessage &&
      isSameSelectedAndSubmitMessage &&
      messages.length < 2
    ) {
      nextLink = toLink(null, store.isInThreadView);
    }

    // メッセージリストが空にならない場合
    if (
      !inModal &&
      hasSelectedMessage &&
      isSameSelectedAndSubmitMessage &&
      messages.length >= 2
    ) {
      const showMessage =
        selectedMessageIndex === messages.length - 1
          ? messages[selectedMessageIndex - 1]
          : messages[selectedMessageIndex + 1];
      nextLink = toLink(showMessage, store.isInThreadView);
    }

    if (nextLink) {
      history.push(nextLink);
    }

    // メッセージ送信前、イベントを登録する。
    window.addEventListener('beforeunload', handleConfirmCloseTab);

    await deleteLateDeleteAttachments();

    const { cancel } = (await sendMessage(draft.id, status)) as any;

    // メッセージ送信後、イベントを削除する。
    window.removeEventListener('beforeunload', handleConfirmCloseTab);

    if (cancel) {
      if (nextLink) {
        history.goBack();
      }
      return;
    }

    logEvent(eventNames.send_message);
  };

  const handleSubmitWithNextStatus = async () => {
    const [, customStatuses] = StoreProvider.get(
      statusesAtomFamily(draft.teamId)
    );
    const nextStatus = getNextStatus(
      buildFrontMessageStatus(draft.status, customStatuses),
      customStatuses
    );
    await handleSubmit(nextStatus);
  };

  const toggleHtmlToolbar = () => {
    if (plainTextMode) {
      confirm({
        title: 'プレーンテキストモードを解除しますか？',
        okText: '解除する',
        cancelText: '解除しない',
        onOk: async () => {
          setHtmlToolbarVisible(true);
          setPlainTextMode(false);

          logEvent(eventNames.toggle_html_toolbar);
        },
        onCancel: () => {
          return;
        },
        maskClosable: true,
      });
      return;
    }
    setHtmlToolbarVisible(!htmlToolbarVisible);

    logEvent(eventNames.toggle_html_toolbar);
  };

  const hideCustomToolbar = () => {
    setHtmlToolbarVisible(false);
  };

  const showTemplateModal = async () => {
    await fetchToAddressContactName(draft.to);
    setTemplateVisible(true);
    setCustomToolbarVisible(true);

    logEvent(eventNames.show_template);
  };

  const showTextCompletionModal = () => {
    setTextCompletionVisible(true);
    setCustomToolbarVisible(true);

    logEvent(eventNames.show_template);
  };

  const createCompletion = async (prompt: string) => {
    hideTextCompletionModal();
    message.loading({
      content: '本文を生成中です',
      key: draft.id,
      duration: 0,
    });
    await updateDoc(draft.ref, {
      isGenerating: true,
    });
    setReadOnly(true);

    unsubscribeDraftRef.current = onSnapshot(
      draft.ref,
      (doc: DocumentSnapshot<DraftData>) => {
        const data = doc.data();
        //TODO better logic?
        if (!data) {
          console.error('Data is missing or undefined');
          throw new Error('No data available');
        }

        const { body, bodyHtml, isGenerating } = data;

        SetBody(body);
        setBodyHtml(bodyHtml);

        editorHandle?.setHtml(bodyHtml);
        if (!isGenerating) {
          setReadOnly(false);
          unsubscribeDraftRef.current?.();
          unsubscribeDraftRef.current = undefined;
          message.success({
            content: `本文の生成が完了しました`,
            key: draft.id,
            duration: 2,
          });
        }
      }
    );

    await createCompletionFunction({
      companyId: store.signInCompany,
      draftId: draft?.id,
      prompt,
    }).catch(async () => {
      unsubscribeDraftRef.current?.();
      unsubscribeDraftRef.current = undefined;
      message.error({
        content: '本文の生成に失敗しました',
        key: draft.id,
      });
      await updateDoc(draft.ref, {
        isGenerating: false,
      });
      setReadOnly(false);
    });
  };

  const hideTemplateModal = () => {
    setTemplateVisible(false);
  };

  const hideTextCompletionModal = () => {
    setTextCompletionVisible(false);
  };

  const hidePreviewImageModal = () => {
    setPreviewImage(null);
    setPreviewImageUrl(null);
  };

  const movableToModal = () =>
    uploadingAttachments.length === 0 &&
    removingAttachments.length === 0 &&
    !updatingAttachments;

  const canSend = () => {
    return (
      to.length > 0 &&
      uploadingAttachments.length === 0 &&
      removingAttachments.length === 0 &&
      !updatingAttachments
    );
  };

  const showUploadDialog = () => {
    uploadFieldRef.current?.click();
  };

  const uploadFileToStorage = async (file: File): Promise<FileAttatchment> => {
    return new Promise((resolve, reject) => {
      setUploadingAttachments([...uploadingAttachments, file]);

      const storageRef = firebase.storage().ref();
      const attachmentId = uuidv4();
      const ref = storageRef.child(
        `companies/${store.signInCompany}/drafts/${
          draft.id
        }/attachments/${attachmentId}/${generateStorageAttachmentName(
          file.name
        )}`
      );

      const uploadTask = ref.put(file, {
        customMetadata: {
          teamId,
          uploader: store.me.id,
        },
      });

      message.loading({
        content: `${file.name}をアップロード中です（0%完了）`,
        key: attachmentId,
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          message.loading({
            content: `${file.name}をアップロード中です（${Math.floor(
              progress
            )}%完了）`,
            key: attachmentId,
          });
        },
        (error) => {
          console.error('CreateMessage.uploadFileToStorage:', error);
          message.error({
            content: `${file.name}のアップロードに失敗しました`,
            key: attachmentId,
          });
          setUploadingAttachments(
            uploadingAttachments.filter((f: any) => f.uid !== file.uid)
          );

          reject();
        },
        async () => {
          // 成功
          file.storagePath = uploadTask.snapshot.ref.fullPath; // Fileオブジェクトのため、スプレッド演算子を使用しない
          const attachment = generateAttachment(file);

          setAttachments((prevState) => [
            ...new Set([...getUsedAttachments(), attachment, ...prevState]),
          ]);

          setUploadingAttachments(
            uploadingAttachments.filter((f) => f.uid !== file.uid)
          );
          updateDraftDebounced();

          message.success({
            content: `${file.name}のアップロードが完了しました。`,
            key: attachmentId,
            duration: 2,
          });
          logEvent(eventNames.add_attachment);
          resolve(attachment as any);
        }
      );
    });
  };

  const onDrop = async (files: File[]) => {
    // 逐次実行したいので、for of で書いている
    for (const file of files) {
      // プレーンテキストモードじゃない状態で画像ファイルはインライン画像として、それ以外のファイルは添付ファイルとして扱う
      if (!plainTextMode && isPreviewableImageMimeType(file.type)) {
        await sendToServerByDrop(file);
        continue;
      }

      file.uid = rcuid(); // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
      await validateFileAndUpload(file);
    }
  };

  const beforeUpload = (file: File) => {
    validateFileAndUpload(file); // beforeUploadはPromiseを返せないため、敢えてawaitしていない
    return false;
  };

  const isFileSizeExceeded = (uploadSize: number) => {
    const attachments = getUsedAttachments();
    //@ts-expect-error original type lacks property size
    const totalSize = attachments.reduce((a, b) => a + b.size, 0);
    return !((uploadSize + totalSize) / 1024 / 1024 < 15);
  };

  const validateFileAndUpload = async (file: File) => {
    if (isFileSizeExceeded(file.size)) {
      message.error('添付ファイルの合計は15MBまでです');
      return;
    }
    return uploadFileToStorage(file);
  };

  const getNonInlineAttachments = () =>
    extractNonInlineAttachments(attachments).map((attachment) => ({
      ...attachment,
      //@ts-expect-error original type lacks property size and name
      name: attachment.name + ` (${formatFileSize(attachment.size)})`,
    }));

  const getUsedAttachments = () =>
    extractUsedAttachments(bodyHtml, attachments);

  /**
   * @param file
   * @param lateDelete {boolean}
   * @return {boolean}
   */
  const onRemove = (file: FileAttatchment, lateDelete = false): boolean => {
    setRemovingAttachments((removingAttachments) => [
      ...removingAttachments,
      file,
    ]);

    if (lateDelete) {
      setAttachments((attachments) =>
        attachments.filter((f) => f.uid !== file.uid)
      );
      setRemovingAttachments((removingAttachments) =>
        removingAttachments.filter((f) => f.uid !== file.uid)
      );
      setLateDeleteAttachments((lateDeleteAttachments) => [
        ...lateDeleteAttachments,
        file,
      ]);
      updateDraftDebounced();

      logEvent(eventNames.remove_attachment);
      return false;
    }

    (async () => {
      await firebase.storage().ref().child(file.storagePath!).delete();
      await editorHandle?.deleteImage(file.uid);
      setAttachments((attachments) =>
        attachments.filter((f) => f.uid !== file.uid)
      );
      setRemovingAttachments((removingAttachments) =>
        removingAttachments.filter((f) => f.uid !== file.uid)
      );
      updateDraftDebounced();
      logEvent(eventNames.remove_attachment);
    })();
    return false;
  };

  const deleteLateDeleteAttachments = async () => {
    const promises = lateDeleteAttachments.map((attachment) =>
      firebase.storage().ref().child(attachment.storagePath).delete()
    );
    await Promise.allSettled(promises);
  };

  const onPreview = async (attachment: File) => {
    if (!isPreviewableImageMimeType(attachment.type)) {
      downloadAttachment(attachment);
      return;
    }

    const { ok, url } = await fetchFromPath(attachment.storagePath);
    if (!ok) {
      message.error('画像ファイルの取得に失敗しました。');
      return;
    }

    setPreviewImage(attachment);
    setPreviewImageUrl(url);
  };

  const openCc = () => {
    setCcOpen(true);
  };

  const openBcc = () => {
    setBccOpen(true);
  };

  const openSubject = () => {
    setSubjectOpen(true);
  };

  /**
   * @param {string} target
   * @param {boolean} ignoreUnsubscribes
   */
  const openDatePicker = (
    target: 'schedule' | 'followup' | null,
    ignoreUnsubscribes: boolean
  ) => {
    setDatePickerTarget(target);
    setDatePickerInitialValue(null);
    setIgnoreUnsubscribes(ignoreUnsubscribes);
  };

  const closeDatePicker = () => {
    setDatePickerTarget(null);
  };

  const toggleTextMode = () => {
    const plainTextModeValue = !plainTextMode;

    setPlainTextMode(plainTextModeValue);
    setHtmlToolbarVisible(!plainTextModeValue);
  };

  const getAttachmentDownloadURL = async (attachment: FileAttatchment) => {
    const ref = firebase.storage().ref(attachment.storagePath);
    return ref.getDownloadURL();
  };

  const onUploadImage = async (file: File, uid = rcuid()) => {
    file.uid = uid; // rc-uploadを経由していないため、uidが作られず、表示時にエラーとなる。そのため、ここでuidを手動で生成している
    file.content_id = uid;
    const attachment = await validateFileAndUpload(file);
    if (!attachment) {
      // 添付ファイルのアップロードに失敗した場合
      return null;
    }

    // URLを取得する
    return getAttachmentDownloadURL(attachment);
  };

  const sendToServerByDrop = async (file: File) => {
    const url = await onUploadImage(file);
    if (editorHandle && url) {
      editorHandle.insertImage(url, file.content_id);
    }
  };

  /**
   *
   * @param file {File}
   * @return {Promise<{ src: string; contentId: string; }>}
   */
  const handleEditorUploadImage = async (
    file: globalThis.File
  ): Promise<{ src: string; contentId: string } | undefined> => {
    const customFile = file as File;
    const url = await onUploadImage(customFile);
    if (!url) {
      return;
    }

    return { src: url, contentId: customFile.content_id };
  };

  /**
   * @param contentId {string}
   */
  const handleInsertImage = (contentId: string) => {
    const attachment = lateDeleteAttachments.find(
      (attachment) => attachment.uid === contentId
    );
    if (!attachment) {
      return;
    }
    setLateDeleteAttachments((lateDeleteAttachments) =>
      lateDeleteAttachments.filter((a) => a.uid !== contentId)
    );
    setAttachments((attachments) => [...attachments, attachment]);
  };

  /**
   * @param contentId {string}
   */
  const handleDeleteImage = (contentId: string) => {
    getUsedAttachments()
      .filter((attachment) => attachment.uid === contentId)
      .forEach((attachment) => {
        onRemove(attachment, true);
      });
  };

  const checkSignatureToolbarVisible = (status: boolean) => {
    if (status) {
      setSignatureToolbarVisible(status);
    }
  };

  const moveToModal = async () => {
    if (!movableToModal()) return;
    await updateDraftDebounced();
    if (deleteCallback) deleteCallback();
    StoreProvider.set(modalDraftAtom, draft);
  };

  const submitting = () => store.cancelableSendingMessageIds.includes(draft.id);

  const onClickTopbar = (clickedTopbarItem: number) => {
    switch (clickedTopbarItem) {
      case TopbarItem.ShowTemplateModal:
        showTemplateModal();
        break;
      case TopbarItem.ShowTextCompletionModal:
        showTextCompletionModal();
        break;
      case TopbarItem.PositiveCompletion:
        createCompletion('ポジティブに返信してください');
        break;
      case TopbarItem.NegativeCompletion:
        createCompletion('ネガティブに返信してください');
        break;
      default:
        break;
    }
  };

  const onClickToolbar = (clickedToolbarItem: number) => {
    switch (clickedToolbarItem) {
      case ToolbarItem.ToggleHtml:
        toggleHtmlToolbar();
        break;
      case ToolbarItem.ShowTemplateModal:
        showTemplateModal();
        break;
      case ToolbarItem.ShowUploadDialog:
        showUploadDialog();
        break;
      case ToolbarItem.Delete:
        handleDelete();
        break;
      case ToolbarItem.PlainTextMode:
        toggleTextMode();
        break;
      default:
        break;
    }
  };

  /**
   * @param to {string[]}
   * @return {Promise<string|null>}
   */
  const fetchToAddressContactName = async (
    to: string[]
  ): Promise<string | null> => {
    if (toName) {
      return toName;
    }
    if (to.length <= 0) {
      return null;
    }

    const email = extractEmail(to[0]);
    let name = await store.contactStore.getContactNameByEmail({
      teamId: teamId,
      emailAddress: email,
    });

    if (name === email) {
      const extractedName = extractName(to[0]);
      name = extractedName.length > 0 ? extractedName : email.split('@')[0];
    }

    setToName(name);

    return name;
  };

  const {
    joinedTeams,
    getTeamInboxes,
    getInbox,
    isScheduledDraftSupported,
    isYaritoriAISupported,
  } = store;
  const { isFollowUpMessageSupported } = store.featureStore;
  const teamSignatures = store.getSignatures(teamId);
  const inbox = getInbox(inboxId);
  const submittingValue = submitting();

  if (submittingValue) {
    return (
      <Sending
        to={to}
        cc={cc}
        bcc={bcc}
        subject={subject}
        bodyHtml={bodyHtml}
        signature={signature}
        inboxId={inboxId}
        getInbox={store.getInbox}
      />
    );
  }

  const inModal = modalDraft && modalDraft.id === draft.id;

  const [datePickerTitle, datePickerOkText] = {
    schedule: ['送信日時を選択', '日時を指定'],
    followup: ['フォローアップ日時を選択', '日時を指定'],
  }[datePickerTarget!] || ['', ''];

  const bodyContent = (
    <Form
      className="grid h-full grid-rows-[auto_auto_1fr_auto]"
      data-testid="draft"
    >
      <Header>
        <div className="flex items-start gap-4">
          {draft.isReply && <Icon icon={Reply} className="h-6 w-6 shrink-0" />}
          <div className="w-full">
            {/* 宛先 */}
            <FromWrapper className="mb-2 flex-nowrap items-start">
              <Label className="mt-1.5 self-start">宛先:</Label>
              <div
                className="mr-1 flex-1 rounded-lg border border-gray-300 px-1 py-0"
                data-testid="draftTo"
              >
                <EditableTagGroup
                  droppableId={FROM_DROPPABLE_ID}
                  droppable={!isUpdating}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  values={to}
                  setValues={(to: string[]) => setTo(to)}
                  onEnterEmptyValue={focusSubjectOrBody}
                  isDuplicatedRecipients={isDuplicatedRecipients}
                  isDuplicatedOtherRecipients={(value: string) =>
                    isDuplicatedRecipients(value, {
                      to: false,
                      cc: true,
                      bcc: true,
                    })
                  }
                  getRecipientsLength={getRecipientsLength}
                  teamId={teamId}
                  disabled={submittingValue}
                  autoFocus={_autoFocusTo}
                />
              </div>
              <div className={optionButtons()}>
                {!ccOpen && (
                  <button
                    type="button"
                    className={optionButton()}
                    onClick={openCc}
                  >
                    Cc
                  </button>
                )}
                {!bccOpen && (
                  <button
                    type="button"
                    className={optionButton()}
                    onClick={openBcc}
                  >
                    Bcc
                  </button>
                )}
                {!subjectOpen && (
                  <button
                    type="button"
                    className={optionButton()}
                    onClick={openSubject}
                  >
                    件名
                  </button>
                )}
                {!inModal && (
                  <PCOnly>
                    <button
                      type="button"
                      className={optionButton()}
                      onClick={moveToModal}
                      disabled={dirty}
                    >
                      <Icon icon={OpenInNew} size={16} className="block" />
                    </button>
                  </PCOnly>
                )}
              </div>
            </FromWrapper>

            {/* Cc */}
            <div
              style={{
                marginBottom: 8,
                display: ccOpen ? 'flex' : 'none',
                alignItems: 'start',
              }}
            >
              <Label style={{ alignSelf: 'start', marginTop: '6px' }}>
                Cc:
              </Label>
              <div
                className="flex-1 rounded-lg border border-gray-300 px-1 py-0"
                data-testid="draftCc"
              >
                <EditableTagGroup
                  droppableId={CC_DROPPABLE_ID}
                  droppable={!isUpdating}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  values={cc}
                  setValues={setCc}
                  onEnterEmptyValue={focusSubjectOrBody}
                  isDuplicatedRecipients={isDuplicatedRecipients}
                  isDuplicatedOtherRecipients={(value: string) =>
                    isDuplicatedRecipients(value, {
                      to: true,
                      cc: false,
                      bcc: true,
                    })
                  }
                  getRecipientsLength={getRecipientsLength}
                  teamId={teamId}
                  disabled={submittingValue}
                />
              </div>
            </div>

            {/* Bcc */}
            <div
              style={{
                display: bccOpen ? 'flex' : 'none',
                alignItems: 'start',
                marginBottom: 8,
              }}
            >
              <Label style={{ alignSelf: 'start', marginTop: '6px' }}>
                Bcc:
              </Label>
              <div
                className="flex-1 rounded-lg border border-gray-300 px-1 py-0"
                data-testid="draftBcc"
              >
                <EditableTagGroup
                  droppableId={BCC_DROPPABLE_ID}
                  droppable={!isUpdating}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  values={bcc}
                  setValues={setBcc}
                  onEnterEmptyValue={focusSubjectOrBody}
                  isDuplicatedRecipients={isDuplicatedRecipients}
                  isDuplicatedOtherRecipients={(value: string) =>
                    isDuplicatedRecipients(value, {
                      to: true,
                      cc: true,
                      bcc: false,
                    })
                  }
                  getRecipientsLength={getRecipientsLength}
                  teamId={teamId}
                  disabled={submittingValue}
                />
              </div>
            </div>

            {/* 差出人 */}
            <div className="mb-4 mr-2 flex w-full">
              <Label>差出人:</Label>
              <From
                inboxId={inboxId}
                teamId={teamId}
                submitting={submittingValue}
                joinedTeams={joinedTeams}
                getTeamInboxes={getTeamInboxes}
                inbox={inbox}
                onChange={setInbox}
                isReply={draft.isReply}
                parseItems={getParseItems(to, inbox) as any}
              />
            </div>
          </div>
        </div>

        {/* 件名 */}
        <SubjectWrapper
          style={{
            display: subjectOpen ? 'flex' : 'none',
          }}
        >
          <Label>件名:</Label>
          {/* multiple rerender onChange cause the focus to shift from header input to body message input */}
          <input
            data-testid="draftSubject"
            className="flex-1 text-xs leading-8 focus:border-0 focus:shadow-none focus:outline-0 focus-visible:outline-0 "
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            disabled={submittingValue}
            placeholder=""
            onKeyDown={onKeyDownInSubject}
            ref={subjectRef}
            autoFocus
          />
        </SubjectWrapper>
      </Header>

      <CreateMessageTopBar
        isReply={draft.isReply}
        onClickTopbar={onClickTopbar}
        submitting={submittingValue}
        isYaritoriAISupported={isYaritoriAISupported}
      />

      {/* 本文 + 署名 + 引用 */}
      <div
        className={editorWrapper({
          inModal,
        })}
      >
        <SimpleBar
          id={uniqueId}
          className={'flex-1 px-4' + (inModal ? '' : ' h-[260px]')}
          classNames={{
            wrapper: 'simplebar-wrapper h-0 w-0',
            track: 'simplebar-track my-2',
            contentEl: 'simplebar-content h-full',
            contentWrapper: 'simplebar-content-wrapper outline-none h-full',
          }}
        >
          <div className="grid min-h-full grid-rows-[1fr_auto] gap-2 py-2">
            {/* 本文 */}
            <CreateMessageWysiwygEditor
              className="min-h-[100px]"
              defaultValue={bodyHtml}
              plainTextMode={plainTextMode}
              disabled={readOnly || submittingValue}
              initEditorHandle={(editorHandle) => setEditorHandle(editorHandle)}
              onMarkDirty={markDirty}
              //@ts-expect-error function does not match expected
              onChange={setBody}
              onCtrlEnter={async () => {
                await handleSubmitWithNextStatus();
              }}
              onFocus={() => setSignatureToolbarVisible(false)}
              onEnter={onEnter}
              uploadImage={handleEditorUploadImage}
              onInsertImage={handleInsertImage}
              onDeleteImage={handleDeleteImage}
              autoFocus={
                // 返信かつ転送ではないときにエディターにフォーカスさせる
                (draft.isReply || draft.inReplyToSentRef) && !draft.isForwarded
              }
              bubbleMenuContainerId={uniqueId}
            >
              {isYaritoriAISupported && editorHandle && (
                <EditorBubbleMenu
                  handle={editorHandle}
                  draftId={draft.id}
                  bubbleMenuContainerId={uniqueId}
                />
              )}
            </CreateMessageWysiwygEditor>
            {/* 署名 */}
            <Signature
              readOnly={readOnly}
              teamSignatures={teamSignatures}
              signature={signature}
              submitting={submittingValue}
              setSignature={setSignature}
              setSignatureBody={setSignatureBody}
              removeSignature={removeSignature}
              addSignature={addSignature}
              plainTextMode={plainTextMode}
              htmlToolbarVisible={htmlToolbarVisible}
              checkSignatureToolbarVisible={checkSignatureToolbarVisible}
              initEditorHandle={(signatureEditorHandle: EditorHandle) =>
                setSignatureEditorHandle(signatureEditorHandle)
              }
              uploadImage={handleEditorUploadImage}
              bubbleMenuContainerId={uniqueId}
            />

            {/* 引用 */}
            {(draft.isReply || draft.inReplyToSentRef) && (
              <Quote
                useQuote={useQuote}
                addQuote={addQuote}
                removeQuote={removeQuote}
                draft={draft}
                plainTextMode={plainTextMode}
                readOnly={false}
              />
            )}
          </div>
        </SimpleBar>
      </div>

      {plainTextMode && (
        <div className="mb-2">
          <Tag style={{ border: 'none', backgroundColor: '#F1F1F4' }}>
            プレーンテキストモード
          </Tag>
        </div>
      )}

      {/* ツールバー */}
      <div className="-mx-4 flex flex-col gap-2 border-t border-[#cccccc60] px-4 pt-2">
        {/*@ts-expect-error does not match*/}
        <CustomUpload
          beforeUpload={beforeUpload}
          onRemove={onRemove}
          onPreview={onPreview}
          listType="text"
          fileList={getNonInlineAttachments()}
          multiple
        >
          <div ref={uploadFieldRef} />
        </CustomUpload>
        {htmlToolbarVisible && !signatureToolbarVisible && (
          <EditorToolbarWithControls
            handle={editorHandle}
            onClose={() => hideCustomToolbar()}
            onInsertImage={onInsertImage as any}
          />
        )}
        {htmlToolbarVisible && signatureToolbarVisible && (
          <EditorToolbarWithControls
            handle={signatureEditorHandle}
            onClose={() => hideCustomToolbar()}
          />
        )}
        <CreateMessageFooter
          draft={draft}
          onClickToolbar={onClickToolbar}
          submitting={submittingValue}
          isUpdating={isUpdating}
          updatedAt={updatedAt}
          htmlToolbarVisible={htmlToolbarVisible}
          plainTextMode={plainTextMode}
          cancelFollowup={() => handleFollowup(null)}
          submitButtonProps={{
            draft: draft,
            disabled: submittingValue || !canSend(),
            handleSubmit: handleSubmit,
            handleDatePicker: handleDatePicker,
            openDatePicker: openDatePicker,
            isScheduledDraftSupported,
            isFollowUpMessageSupported,
          }}
        />
      </div>
    </Form>
  );

  const modalElements = (
    <>
      {/* モーダル - テンプレート */}
      <TemplateModal
        templateVisible={templateVisible}
        hideTemplateModal={hideTemplateModal}
        showTemplateModal={showTemplateModal}
      >
        <Template
          teamId={teamId}
          setTemplate={setTemplate}
          //TODO find any
          parseReservedWords={(body: any) =>
            parseReservedWordsByBody(body, {
              overrideToName: toName,
            })
          }
          draft={{
            subject: subject,
            body: body,
            bodyHtml: bodyHtml,
          }}
        />
      </TemplateModal>
      {/* モーダル - 自動生成 */}
      <AiPromptDialog
        open={textCompletionVisible}
        onGenerate={createCompletion}
        onOpenChange={(open) => setTextCompletionVisible(open)}
      />

      {!!datePickerTarget && (
        <DatePickerModal
          title={datePickerTitle}
          okText={datePickerOkText}
          initialValue={datePickerInitialValue}
          close={closeDatePicker}
          onChange={(datetime) =>
            handleDatePicker(datetime, ignoreUnsubscribes!)
          }
        />
      )}

      {/* モーダル - プレビュー */}
      <PreviewImageModal
        open={previewImage && previewImageUrl}
        onClose={hidePreviewImageModal}
        url={previewImageUrl}
        onDownload={() => downloadAttachment(previewImage)}
      />
    </>
  );

  if (isSP()) {
    return (
      <div className={inModal ? 'mt-4' : 'h-full'}>
        {bodyContent}
        {modalElements}
      </div>
    );
  }

  return (
    <div
      className="h-full"
      onDragOver={() => {
        if (!readOnly) {
          setReadOnly(true);
        }
      }}
      onDrop={() => {
        setTimeout(() => setReadOnly(false), 100);
      }}
    >
      <Dropzone onDrop={onDrop as any} noClick noKeyboard>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps({ className: 'dropzone h-full relative' })}>
            <input {...getInputProps()} />
            {isDragActive && (
              <div className="absolute left-0 top-0 flex w-full items-center justify-center bg-white/50">
                <Result
                  icon={
                    <Icon
                      icon={NoteAdd}
                      size={32}
                      className={'text-sumi-800'}
                    />
                  }
                  subTitle="ドロップしてファイルをアップロード"
                />
              </div>
            )}
            <DropzoneInner isDragActive={isDragActive}>
              {bodyContent}
            </DropzoneInner>
          </div>
        )}
      </Dropzone>
      {modalElements}
    </div>
  );
};

const Composed = compose(withRouter, inject('store'), observer)(Index as any);

export default (props: any) => {
  const modalDraft = useAtomValue(modalDraftAtom);
  return <Composed {...props} modalDraft={modalDraft} />;
};

interface DropzoneInner {
  isDragActive: boolean;
}

const DropzoneInner = styled.div<DropzoneInner>`
  height: 100%;
  ${({ isDragActive }) =>
    isDragActive &&
    css`
      opacity: 0.1;
    `};
`;

const CustomUpload = styled(Upload)`
    .ant-upload-select-text {
        display: none !important;
    }

    .ant-upload-list::before,
    .ant-upload-list::after {
        content: none;
    }

    .ant-upload-list-item-name {
        cursor: pointer;
        max-width: 500px;
        ${media.lessThan('medium')`
      max-width: 300px;
    `}
`;

const Label = styled.span`
  display: inline-block;
  width: 40px;
  flex: 0 0 40px;
  margin-right: 8px;
  white-space: nowrap;
  align-self: center;
  color: #8d8d8d;
`;

const FromWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const SubjectWrapper = styled.div`
  border-top: 1px solid #cccccc60 !important;
  border-bottom: 1px solid #cccccc60 !important;
  padding-left: 16px;
  margin: 12px -16px 4px;
`;

const Header = styled.div`
  position: sticky;
`;
