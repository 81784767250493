import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M4.75 19a.723.723 0 0 1-.534-.217.732.732 0 0 1-.216-.537.731.731 0 0 1 .75-.746H6.1V9.85c0-1.367.413-2.604 1.238-3.712C8.162 5.029 9.25 4.333 10.6 4.05v-.725c0-.383.136-.7.407-.95.27-.25.6-.375.987-.375s.719.125.994.375c.274.25.412.567.412.95v.725c1.35.283 2.442.98 3.275 2.088.833 1.108 1.25 2.345 1.25 3.712v7.65h1.325c.212 0 .39.072.534.217a.732.732 0 0 1 .216.537.731.731 0 0 1-.75.746H4.75ZM12 22c-.533 0-1-.196-1.4-.587-.4-.392-.6-.863-.6-1.413h4c0 .55-.196 1.02-.588 1.413A1.926 1.926 0 0 1 12 22Zm-4.4-4.5h8.825V9.85c0-1.25-.42-2.304-1.263-3.162-.841-.859-1.883-1.288-3.125-1.288-1.241 0-2.291.43-3.15 1.287C8.03 7.546 7.6 8.6 7.6 9.85v7.65Z"
    />
  </svg>
);
export default SvgNotification;
