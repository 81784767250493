import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDoneFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m10.58 14.146-2.322-2.323a.726.726 0 0 0-.522-.213.708.708 0 0 0-.532.213.718.718 0 0 0-.217.527c0 .206.072.382.217.527l2.744 2.744c.18.18.392.271.633.271.24 0 .452-.09.633-.27l5.563-5.564a.726.726 0 0 0 .212-.522.708.708 0 0 0-.212-.532.718.718 0 0 0-.527-.217.718.718 0 0 0-.527.217l-5.142 5.142Zm1.422 7.354a9.254 9.254 0 0 1-3.706-.748 9.596 9.596 0 0 1-3.016-2.03 9.595 9.595 0 0 1-2.032-3.016 9.246 9.246 0 0 1-.748-3.704c0-1.314.25-2.55.748-3.706a9.596 9.596 0 0 1 2.03-3.016 9.594 9.594 0 0 1 3.016-2.032 9.246 9.246 0 0 1 3.704-.748c1.314 0 2.55.25 3.706.748a9.596 9.596 0 0 1 3.017 2.03 9.594 9.594 0 0 1 2.03 3.016 9.247 9.247 0 0 1 .749 3.704c0 1.314-.25 2.55-.748 3.706a9.596 9.596 0 0 1-2.03 3.017 9.595 9.595 0 0 1-3.016 2.03 9.247 9.247 0 0 1-3.704.749Z"
    />
  </svg>
);
export default SvgDoneFilled;
