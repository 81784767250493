import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUpgrade = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={24}
    fill="none"
    viewBox="0 0 26 24"
    {...props}
  >
    <rect width={26} height={24} fill="#58DA42" rx={8} />
    <path
      fill="#fff"
      d="M14.043 8.48v9.997c0 .292-.1.54-.302.74a1.008 1.008 0 0 1-.741.303c-.293 0-.54-.101-.74-.302a1.008 1.008 0 0 1-.303-.741V8.48l-2.41 2.41a.967.967 0 0 1-.729.299 1.06 1.06 0 0 1-.73-.3 1.038 1.038 0 0 1-.316-.738.977.977 0 0 1 .3-.738l4.193-4.199a.965.965 0 0 1 .342-.225c.125-.047.256-.07.393-.07a1.1 1.1 0 0 1 .393.07c.125.047.24.122.342.225l4.194 4.2a.977.977 0 0 1 .299.737 1.039 1.039 0 0 1-.317.739 1.06 1.06 0 0 1-.73.299.967.967 0 0 1-.729-.3l-2.41-2.41Z"
    />
  </svg>
);
export default SvgUpgrade;
