import { useHistory, useLocation } from 'react-router-dom';
import { Icon } from '../../../basics';
import { CaretLeft } from '../../../icons';
import { DELIVERY_PATHS } from '../../page/DeliveryPage/deliveryPaths';

type State = {
  fromList?: boolean;
};

export const DeliveryMessageStatsHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const fromList = (location.state as State)?.fromList === true;
  return (
    <div className="flex items-start">
      <button
        type="button"
        className="relative left-[-6px] m-0 grid grid-cols-[auto_1fr] items-center gap-2 bg-transparent p-0 text-sm enabled:cursor-pointer"
        onClick={() =>
          fromList
            ? history.goBack()
            : history.push(DELIVERY_PATHS.getIndexPath())
        }
      >
        <Icon icon={CaretLeft} size={24} />
        <span>戻る</span>
      </button>
    </div>
  );
};
