import { Event } from 'lib';
import React, { ComponentProps, memo } from 'react';
import { Tooltip } from '../../basics/Tooltip/Tooltip';
import { Icon } from '../../basics';
import { Notification } from '../../icons';
import moment from 'moment-timezone';
import 'moment/locale/ja';
import { twMerge } from 'tailwind-merge';

type Props = ComponentProps<'div'> & {
  type: Event['type'];
  date: Date;
  text: string;
};

const VALID_EVENT_TYPES: readonly string[] = [
  'assignee:update',
  'status:update:backlog',
  'status:update:processed',
  'tags:add',
  'tags:remove',
  'reminder:update',
] as const;

const arePropsEqual = (oldProps: Props, newProps: Props) => {
  if (Object.is(oldProps, newProps)) {
    return true;
  }
  return (
    oldProps.date.getTime() === newProps.date.getTime() &&
    oldProps.type === newProps.type &&
    oldProps.text === newProps.text
  );
};

export const MessageEvent = memo(function MessageEvent({
  type,
  date,
  text,
  className,
  ...props
}: Props) {
  const time = moment(date);
  if (!VALID_EVENT_TYPES.includes(type)) {
    return null;
  }
  return (
    <div
      className={twMerge('flex items-center gap-2 text-xs', className)}
      {...props}
    >
      <div className="grid grid-cols-[auto_1fr] items-center gap-0.5">
        <div className="text-sumi-600">
          <Icon icon={Notification} size={18} className="block" />
        </div>
        <div className="text-sumi-700">{text}</div>
      </div>
      <div className="text-sumi-500">
        <Tooltip content={time.format('YYYY年M月D日 HH:mm')}>
          <span>{time.fromNow()}</span>
        </Tooltip>
      </div>
    </div>
  );
}, arePropsEqual);
