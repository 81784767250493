import { tv } from 'tailwind-variants';

const headingStyles = {
  h1: tv({
    base: `mb-8 border-b border-[#f1f1f3] pb-[5px] text-xl font-semibold text-[#44425E]`,
    variants: {
      noUnderline: {
        true: 'border-none',
      },
    },
    defaultVariants: {
      noUnderline: false,
    },
  }),
  h2: tv({
    base: `text-lg font-semibold text-[#44425E]`,
  }),
  h3: tv({
    base: `m-0 text-base font-semibold text-[#44425E]`,
  }),
};

export const Heading = <T extends keyof JSX.IntrinsicElements = 'h1'>({
  level = 1,
  noUnderline = false,
  children,
  className = '',
  ...props
}: {
  level?: 1 | 2 | 3;
  noUnderline?: boolean;
  children: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<T>) => {
  const Tag = `h${level}` as keyof JSX.IntrinsicElements as React.ElementType;
  const styles = headingStyles[`h${level}` as keyof typeof headingStyles];

  return (
    <Tag className={styles({ noUnderline, className })} {...props}>
      {children}
    </Tag>
  );
};
