import { ContactEditDrawer, ContactUpdate } from './ContactEditDrawer';
import { observer } from 'mobx-react';
import { useStore } from '../../../../hooks/useStore';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAtomValue } from 'jotai';
import { meAtom } from '../../../../atoms/auth';
import {
  addDoc,
  deleteDoc,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  ContactData,
  normalizeAddress,
  normalizeStr,
  unsubscribeConverter,
  UnsubscribeData,
} from 'lib';
import { eventNames, logEvent } from '../../../../analytics';
import { useToast } from '../../../../hooks/useToast';
import { companyCollection } from '../../../../firestore';
import { joinedTeamsAtom } from '../../../../atoms/firestore/team';
import {
  deliveryAddressesAtom,
  deliveryAddressesLoadingAtom,
} from '../../../../atoms/firestore/deliveryAddress';
import { uniq } from 'lodash';
import { ContactDrawerMemosWithLogic } from './ContactDrawerMemosWithLogic';

type Props = {
  teamId: string;
  contactId: string | undefined;
};

type Contact = ComponentProps<typeof ContactEditDrawer>['contact'];
type Statuses = ComponentProps<typeof ContactEditDrawer>['emailStatuses'];

export const ContactEditDrawerWithLogic = observer(
  ({ teamId, contactId }: Props) => {
    const store = useStore();
    const history = useHistory();
    const { showToast } = useToast();
    const { isReadOnly } = useAtomValue(meAtom);
    const [contact, setContact] = useState<Contact | undefined>();
    const [statuses, setStatuses] = useState<Statuses | undefined>();
    const teams = useAtomValue(joinedTeamsAtom);
    const deliveryAddressesLoading = useAtomValue(deliveryAddressesLoadingAtom);
    const deliveryAddresses = useAtomValue(deliveryAddressesAtom);

    const tags = store
      .getContactTags(teamId)
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name, 'ja'));

    const checkDuplicateEmail = async (email: string) => {
      const contacts = await store.getContactsByTeamIdEmail(teamId, email);
      return !!contacts && contacts.length > 0;
    };

    const handleChange = async (update: ContactUpdate) => {
      const tags = await store.getOrCreateContactTags(update.tags, teamId);
      const data = {
        ...update,
        nameNormalized: normalizeStr(update.name),
        emailNormalized: normalizeStr(update.email),
        companyNameNormalized: normalizeStr(update.companyName),
        phoneNumberNormalized: normalizeStr(update.phoneNumber),
        memoNormalized: normalizeStr(update.memo),
        tags: tags
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name, 'ja'))
          .map((t) => t.name),
        teamId,
        updatedAt: serverTimestamp(),
      };
      if (contactId) {
        const ref = store.doc(
          'contacts',
          contactId
        ) as DocumentReference<ContactData>;

        // 現在のタグを取得する
        const contactSnapshot = await getDoc(ref);
        if (!contactSnapshot.exists()) {
          console.error('Contacts.update: !contactSnapshot.exists():', ref);
          return;
        }

        await updateDoc(ref, data);

        // 削除されたタグが最後の一つだった場合、そのタグ自体を削除する
        const currentTags = contactSnapshot.data().tags ?? [];
        const removedTags = currentTags.filter(
          (tag) => !data.tags.includes(tag)
        );
        if (removedTags.length > 0) {
          await store.removeContactTagsIfNotAttached(removedTags, teamId);
        }
        store.contactStore.updateCachedName(teamId, data.email, data.name);
        showToast('success', 'コンタクトを更新しました');
        history.push('../contacts');
      } else {
        await addDoc(store.collection('contacts'), {
          ...data,
          createdAt: serverTimestamp(),
        });
        logEvent(eventNames.add_contact);
        showToast('success', 'コンタクトを作成しました');
        history.push('../contacts');
      }
    };

    const handleDelete = async (contactId: string) => {
      const ref = store.doc(
        'contacts',
        contactId
      ) as DocumentReference<ContactData>;
      const doc = await getDoc(ref);
      if (!doc.exists()) {
        console.error('コンタクトがありません', `contactId=${contactId}`);
        return;
      }
      const currentData = doc.data();
      await Promise.all([
        deleteDoc(ref),
        store.contactStore.unindexContacts(teamId, [contactId]),
        (currentData.tags ?? []).length > 0
          ? store.removeContactTagsIfNotAttached(currentData.tags, teamId)
          : null,
      ]);
      showToast('success', 'コンタクトを削除しました');
      history.push('../contacts');
    };

    const memoSection = useMemo(() => {
      if (!contactId) {
        return <></>;
      }
      return (
        <ContactDrawerMemosWithLogic contactId={contactId} teamId={teamId} />
      );
    }, [contactId, teamId]);

    useEffect(() => {
      setContact(undefined);
      if (contactId) {
        const contactTags = store.getContactTags(teamId);
        store.getContact(contactId).then((c) => {
          if (!c) {
            return;
          }
          setContact({
            name: c.name,
            email: c.email,
            companyName: c.companyName,
            tags: c.tags.map((name) => ({
              name: name,
              color: contactTags.find((t) => t.name === name)?.color,
            })),
            phoneNumber: c.phoneNumber,
          });
        });
      }
    }, [contactId]);

    useEffect(() => {
      if (!contact || !contact.email || deliveryAddressesLoading) {
        return;
      }
      const addresses = uniq(
        [
          ...store.getTeamInboxes(teamId).map((i) => i.email),
          ...deliveryAddresses.map((a) => a.email),
        ].map((a) => normalizeAddress(a))
      );

      if (!addresses.length) {
        setStatuses([]);
        return;
      }

      getDocs(
        query(
          companyCollection('unsubscribes', unsubscribeConverter),
          where('email', 'in', addresses)
        )
      ).then((res) => {
        const array = res.docs.flatMap((d) => d.data().data);
        const defaultData: UnsubscribeData[] = addresses
          .filter((e) => array.every((a) => a.email !== e))
          .map((email) => ({
            email,
            targets: [],
          }));
        const statuses: Statuses = [...defaultData, ...array].map((d) => ({
          email: d.email,
          status: d.targets.includes(normalizeAddress(contact.email!))
            ? 'optOut'
            : 'available',
        }));
        setStatuses(statuses);
      });
    }, [contact?.email, deliveryAddressesLoading, deliveryAddresses]);

    if (contactId && !contact) {
      return null;
    }

    const team = teams.find((t) => t.id === teamId);
    const teamName = team?.isPrivate !== true ? team?.name : undefined;

    return (
      <ContactEditDrawer
        contactId={contactId}
        contact={contact ?? {}}
        checkDuplicateEmail={checkDuplicateEmail}
        emailStatuses={statuses}
        tags={tags.map((t) => ({ name: t.name, color: t.color }))}
        teamName={teamName}
        memoSection={memoSection}
        open={true}
        onOpenChange={() =>
          history.push(`/contacts/${teamId}/contacts/contacts`)
        }
        onChange={handleChange}
        onDelete={handleDelete}
        readonly={isReadOnly}
      />
    );
  }
);
