import {
  createDefaultFilterAction,
  FilterAction,
  FilterActionType,
} from '../FilterEditDrawer/FilterEditDrawer';
import { Icon } from '../../../../../components/basics';
import { DoNotDisturbOn, Plus } from '../../../../../components/icons';
import { Select } from '../../../../../components/forms';
import { ReactElement } from 'react';
import { MultipleInput } from '../../../../../components/forms/MultipleInput/MultipleInput';

type Props = {
  value: FilterAction[];
  onChange: (value: FilterAction[]) => void;
  onValidate: (valid: boolean) => void;
  members: {
    id: string;
    name: string;
    renderAvatar: () => ReactElement;
  }[];
  tags: {
    id: string;
    name: string;
    // color: TagColor // TODO
  }[];
  disabled?: boolean;
};

export const FilterActionInput = ({
  value,
  onChange,
  onValidate,
  members,
  tags,
  disabled = false,
}: Props) => {
  const onUpdate = (index: number, action: FilterAction) => {
    const copy = [...value];
    copy[index] = action;
    validateAndChange(copy);
  };
  const onDelete = (index: number) => {
    validateAndChange(value.filter((_e, i) => i !== index));
  };
  const validateAndChange = (value: FilterAction[]) => {
    const foundInvalidAction = value.find((action) => {
      if (action.type === 'assign' && !action.assignee) {
        return true;
      }
      return action.type === 'addTag' && action.tags.length === 0;
    });
    onValidate(foundInvalidAction == null);
    onChange(value);
  };

  const renderUserItem = (value: string, label: string) => {
    const found = members.find((m) => m.id === value);
    if (!found) {
      return label;
    }
    return (
      <div className="grid grid-cols-[auto_1fr] items-center gap-2">
        <div className="h-[24px] w-[24px] overflow-hidden rounded-full">
          {found.renderAvatar()}
        </div>
        <span className="overflow-hidden truncate whitespace-nowrap">
          {label}
        </span>
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2 rounded-lg bg-sumi-50 p-2">
        {value.map((action, i) => {
          const isRemovable = value.length > 1;
          return (
            <div
              key={action.id}
              className="grid grid-cols-[1fr_1.5fr_auto] gap-2"
            >
              <Select
                value={action.type}
                onChange={(v: FilterActionType) => {
                  if (v === 'assign') {
                    onUpdate(i, {
                      id: action.id,
                      type: 'assign',
                      assignee: '',
                    });
                  } else if (v === 'addTag') {
                    onUpdate(i, { id: action.id, type: 'addTag', tags: [] });
                  } else {
                    onUpdate(i, { id: action.id, type: v });
                  }
                }}
                options={[
                  { value: 'assign', label: '担当者を設定する' },
                  { value: 'assignSender', label: '送信者を担当者に設定する' },
                  { value: 'updateStatusAsProcessed', label: '対応済みにする' },
                  { value: 'addTag', label: 'タグを付ける' },
                  { value: 'skipInbox', label: '受信トレイをスキップする' },
                ]}
                variants={{ rounded: 'lg' }}
                className="h-10 bg-white"
                disabled={disabled}
              />
              {action.type === 'assign' && (
                <Select
                  placeholder="担当者を選択してください"
                  value={action.assignee}
                  onChange={(v) => {
                    onUpdate(i, { ...action, assignee: v });
                  }}
                  options={members.map((m) => ({ value: m.id, label: m.name }))}
                  renderLabel={renderUserItem}
                  renderOption={renderUserItem}
                  variants={{ rounded: 'lg' }}
                  className="h-10 bg-white"
                  disabled={disabled}
                />
              )}
              {action.type === 'addTag' && (
                <MultipleInput
                  value={action.tags}
                  onChange={(tags) => onUpdate(i, { ...action, tags })}
                  suggests={tags.map((t) => ({ value: t.id, text: t.name }))}
                  disabled={disabled}
                />
              )}
              {isRemovable && (
                <div className="flex items-center justify-start">
                  <button
                    type="button"
                    className="block cursor-pointer bg-transparent p-0 text-sumi-300 disabled:cursor-not-allowed"
                    onClick={() => onDelete(i)}
                    disabled={disabled}
                  >
                    <Icon icon={DoNotDisturbOn} size={24} className="block" />
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {!disabled && (
        <button
          type="button"
          className="flex h-8 w-full cursor-pointer items-center justify-center gap-2 rounded-lg border border-sumi-300 bg-transparent bg-white p-0 text-sm hover:bg-sumi-50"
          onClick={() =>
            validateAndChange([...value, createDefaultFilterAction()])
          }
        >
          <Icon icon={Plus} size={20} />
          <span>処理を追加する</span>
        </button>
      )}
    </div>
  );
};
