import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m9.45 15.7 9.075-9.075a.738.738 0 0 1 .539-.225.73.73 0 0 1 .536.226c.15.151.225.33.225.538a.732.732 0 0 1-.225.536l-9.625 9.625a.72.72 0 0 1-.525.225.72.72 0 0 1-.525-.225l-4.55-4.55a.696.696 0 0 1-.212-.538.777.777 0 0 1 .239-.537c.15-.15.33-.225.537-.225.208 0 .386.075.536.225l3.975 4Z"
    />
  </svg>
);
export default SvgDone;
