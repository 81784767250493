import { Skeleton } from 'antd';
import FullHeightIframe from './FullHeightIframe';
import styled from 'styled-components';
import { Attachments } from './Attachments';
import { User } from 'lib';
import { Reply, ReplyAll, SendOn } from 'components/icons';
import MessageBodyTextButton from './MessageBodyTextButton';
import { useEffect } from 'react';

type Props = {
  messageId: string;
  lock: any;
  startReply: () => void;
  startReplyAll: () => void;
  startReplyInstead: () => void;
  startForwarding: () => void;
  html: any;
  loading: boolean;
  attachments: any;
  locker: User | undefined;
  me: User | undefined;
  onLoad?: (id: string) => void;
  isReadOnly?: boolean;
};

const MessageBody = ({
  messageId,
  lock,
  loading,
  attachments,
  html,
  startReplyAll,
  startReply,
  startForwarding,
  startReplyInstead,
  locker,
  me,
  onLoad,
  isReadOnly = false,
}: Props) => {
  const locked = Boolean(lock);

  const bodyStyles = {
    maxHeight: 400,
    overflow: 'auto',
  };

  useEffect(() => {
    if (!loading) {
      onLoad?.(messageId);
    }
  }, [loading, messageId, onLoad]);

  return (
    <div className="px-4 pb-3">
      {loading && <Skeleton loading={loading} active />}
      {html && (
        <>
          <div className="mb-3" style={locked ? { ...bodyStyles } : {}}>
            <Attachments attachments={attachments} />
            <FullHeightIframe srcDoc={html} />
          </div>

          <div className="flex items-center gap-2 text-xs">
            <MessageBodyTextButton
              component={ReplyAll}
              text="全員に返信"
              disabled={isReadOnly || locked}
              onClick={startReplyAll}
            />
            <MessageBodyTextButton
              component={Reply}
              text="返信"
              disabled={isReadOnly || locked}
              onClick={startReply}
            />
            <MessageBodyTextButton
              component={SendOn}
              text="転送"
              disabled={isReadOnly}
              onClick={startForwarding}
            />
          </div>

          {locked && (
            <>
              <div className="pt-1">
                {locker?.name || '削除されたユーザ'}が返信中です。
              </div>
              {!isReadOnly && me && lock.locker !== me.id && (
                <ReplyInsteadOf onClick={startReplyInstead}>
                  代わりに返信しますか？
                </ReplyInsteadOf>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MessageBody;

const ReplyInsteadOf = styled.span`
  vertical-align: bottom;
  color: #3577ef;
  cursor: pointer;

  &:hover {
    transition: opacity 0.2s;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    opacity: 0.8;
  }
`;
