import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { db } from '../../../firebase';
import {
  createPortalSession,
  fetchSubscriptionItem,
  updateSubscriptionProduct,
} from '../../../functions';
import {
  message as toast,
  Alert,
  Button,
  Descriptions,
  Modal,
  Radio,
  Row,
  Col,
  Spin,
  Tooltip,
} from 'antd';
import { Heading } from 'components/basics/Heading/Heading';
import { PlanSelectModal } from './PlanSelectModal';
import { Product } from './Product';
import { Loading } from 'components/basics';
import { useStore } from 'hooks/useStore';

const VERY_OLD_UI_PRODUCT_VERSION = 4;

const formatFileSize = (size: number) => {
  const gb = size / 1024 / 1024 / 1024;
  return `${gb.toFixed(2)} GB`;
};

export const getProducts = async (): Promise<Product[]> => {
  const snapshot = await db
    .collection('stripe')
    .doc('collections')
    .collection('products')
    .where('active', '==', true)
    .get();

  return (
    snapshot.docs
      // FIXME: any
      .map((doc) => new Product({ id: doc.id, ...doc.data() } as any))
      .sort((a, b) => a.order - b.order)
  );
};

/**
 * v5またはv4以下のPayment
 * また、v4以下の場合は VERY_OLD_UI_PRODUCT_VERSION で制御する。
 */
const Payments = observer(() => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const [oldModalVisible, setOldModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<
    Product | null | undefined
  >(null);
  const [updatedProduct, setUpdatedProduct] = useState<
    Product | null | undefined
  >(null);
  const [customerPortalLoading, setCustomerPortalLoading] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (!store.me.isAdmin) {
      return;
    }
    const loadSubscription = async () => {
      const [resp, ps] = await Promise.all([
        fetchSubscriptionItem({
          companyId: store.signInCompany,
          itemType: 'plan',
        }).catch((e) => {
          if (e.code !== 'not-found') throw e;
        }),
        getProducts(),
      ]);

      const currentProductId = resp?.data.price.product;
      const current = ps.find((p) => p.id === currentProductId);

      setCurrentProduct(current);
      setProducts(ps.filter((p) => p.version === current?.version));
    };

    loadSubscription();
  }, [store.signInCompany, store.me.isAdmin]);

  useEffect(() => {
    // FIXME: nullable
    setProductDescription(updatedProduct as Product);
  }, [updatedProduct]);

  useEffect(() => {
    setLoading(!currentProduct);
    setUpdatedProduct(currentProduct);
  }, [currentProduct]);

  const redirectToCustomerPortal = async () => {
    setCustomerPortalLoading(true);
    const resp = await createPortalSession({
      companyId: store.signInCompany,
      returnUrl: window.location.href,
    });
    if (!resp?.data?.url) {
      setCustomerPortalLoading(false);
      return;
    }
    window.location.assign(resp.data.url);
  };

  const updateProduct = async (product?: Product | null) => {
    if (!product) {
      return;
    }

    const key = 'updateProduct';
    toast.loading({ content: 'プランを変更中です…', duration: 0, key });
    setLoading(true);
    await updateSubscriptionProduct({
      companyId: store.signInCompany,
      plan: product.plan,
    });
    toast.success({ content: 'プランを変更しました', key });
    setLoading(false);
    setCurrentProduct(product);
  };

  const setProductDescription = (product: Product) =>
    setDescription(
      `共有メールアドレス数${
        product?.maxInboxes ? `${product.maxInboxes}個まで` : '無制限'
      }${product?.maxUsers ? `\nユーザー数${product.maxUsers}名まで` : ''}`
    );

  const handleClickChnagePlanButton = () => {
    if (!currentProduct) {
      return;
    }

    if (currentProduct.version < VERY_OLD_UI_PRODUCT_VERSION) {
      setOldModalVisible(true);
    } else {
      setModalVisible(true);
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSelectProduct = (product: Product) => {
    setModalVisible(false);
    updateProduct(product);
  };

  const joinableUserCount = store.users.length + store.invitedUsers.length;

  if (!store.me.isAdmin) {
    return null;
  }

  if (loading) {
    return (
      <div
        className={`z-0 max-w-full flex-1 overflow-auto p-[32px_16px_32px_32px]`}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div
        className={`z-0 max-w-full flex-1 overflow-auto p-[32px_16px_32px_32px]`}
      >
        <Heading level={1}>お支払い</Heading>
        <Descriptions bordered column={1}>
          <Descriptions.Item
            label={
              <Row type="flex" justify="space-between" align="middle">
                <Col>現在のプラン</Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={handleClickChnagePlanButton}
                    disabled={loading}
                  >
                    変更
                  </Button>
                </Col>
              </Row>
            }
          >
            <div style={{ textAlign: 'center' }}>
              <Spin spinning={loading}>{currentProduct?.name || ''}</Spin>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="メールアドレス数">
            <div style={{ textAlign: 'center' }}>{store.inboxesLength}</div>
          </Descriptions.Item>
          <Descriptions.Item label="有償ユーザー数">
            <div style={{ textAlign: 'center' }}>
              {store.users.filter((u) => u.role !== 'readonly').length}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="無償ユーザー数">
            <div style={{ textAlign: 'center' }}>
              {store.users.filter((u) => u.role === 'readonly').length}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="合計ユーザー数">
            <div style={{ textAlign: 'center' }}>{store.users.length}</div>
          </Descriptions.Item>
          {store.company?.storageSize && (
            <Descriptions.Item label="使用容量">
              <div style={{ textAlign: 'center' }}>
                {formatFileSize(store.company.storageSize)}
              </div>
            </Descriptions.Item>
          )}
        </Descriptions>
        <Button
          type="link"
          onClick={redirectToCustomerPortal}
          loading={customerPortalLoading}
        >
          お支払いの詳細はこちら（別ページに遷移します）
        </Button>
      </div>
      {/* 旧プラン変更モーダル */}
      <Modal
        title="プランの変更"
        visible={oldModalVisible}
        okText="変更"
        okButtonProps={{
          disabled: loading || updatedProduct?.id === currentProduct?.id,
        }}
        onOk={async () => {
          setOldModalVisible(false);
          await updateProduct(updatedProduct);
        }}
        cancelText="キャンセル"
        onCancel={() => {
          setOldModalVisible(false);
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Radio.Group
            defaultValue={currentProduct}
            buttonStyle="solid"
            size="large"
            onChange={(e) => setUpdatedProduct(e.target.value)}
          >
            {products.map((product) => (
              <Tooltip
                title={
                  (product.hasSmallerMaxInboxes(store.inboxesLength) &&
                    `このプランで使用できる共有メールアドレス数(${product.maxInboxes}個まで)を超えているため選択できません`) ||
                  (product.hasSmallerMaxUsers(joinableUserCount) &&
                    `このプランで使用できるユーザー数(招待中のユーザー含め${product.maxUsers}名まで)を超えているため選択できません`)
                }
                key={`${product.id}-selectable`}
              >
                <Tooltip
                  title={
                    product.id === currentProduct?.id && '現在のプランです'
                  }
                  key={`${product.id}-isCurrent`}
                >
                  <Radio.Button
                    key={product.id}
                    value={product}
                    disabled={
                      !product.selectable(
                        store.inboxesLength,
                        joinableUserCount
                      )
                    }
                  >
                    {product.name}
                  </Radio.Button>
                </Tooltip>
              </Tooltip>
            ))}
          </Radio.Group>
        </div>
        <Alert
          style={{ marginTop: 16 }}
          message={
            <>
              {description.split('\n').map((line, index) => (
                <p key={index} style={{ margin: 0 }}>
                  {line}
                </p>
              ))}
            </>
          }
          type="info"
        />
      </Modal>
      {/* 新プラン(version 4以降)変更モーダル */}
      <PlanSelectModal
        visible={modalVisible}
        currentProduct={currentProduct ?? undefined}
        products={products}
        onClose={handleCloseModal}
        onSelect={handleSelectProduct}
        userCount={joinableUserCount}
        inboxCount={store.inboxesLength}
      />
    </>
  );
});

export default Payments;
