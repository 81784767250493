import { useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Comment,
  commentConverter,
  Message,
  messageConverter,
  MessageLike,
  messageLikeConverter,
  Sent,
  sentConverter,
} from 'lib';
import { Draft, draftConverter } from '../../../../firestore/entity/draft';
import {
  companyCollection,
  companyDoc,
  registerUnsubscribe,
} from '../../../../firestore';
import { useSubscribeDocument } from '../../../../hooks/firestore/subscription';
import {
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { v4, v4 as uuidv4 } from 'uuid';
import { subscribeQueryInArray } from '../../../../utils/firebase';
import { Lock, lockConverter } from '../../../../firestore/entity/lock';
import { Event, eventConverter } from '../../../../firestore/entity/event';
import * as Sentry from '@sentry/react';
import { getCountFromServer } from '@firebase/firestore';
import { flatten, isEqual, sumBy } from 'lodash';

type Conversation =
  | {
      loading: true;
    }
  | {
      loading: false;
      headerMessage: MessageLike;
      messages: Message[];
      sentMessages: Sent[];
      drafts: Draft[];
      locks: Lock[];
      events: Event[];
      comments: Comment[];
      fetchMoreComments: () => Promise<boolean>;
      hasMoreComments: boolean;
    };

const COMMENT_PAGE_SIZE = 20;

export const useConversation = (
  threadView: boolean,
  messageId: string,
  onAddSentMessage: (sentMessage: Sent) => void,
  onAddComment: (comment: Comment) => void
): Conversation => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<Message[]>();
  const [sentMessages, setSentMessages] = useState<Sent[]>();
  const [drafts, setDrafts] = useState<Draft[]>();
  const [locks, setLocks] = useState<Lock[]>();
  const [events, setEvents] = useState<Event[]>();
  // メッセージに紐づいたデータを取得する際に使用
  const [messageIds, setMessageIds] = useState<string[]>([]);

  const [, headerMessage] = useSubscribeDocument(
    companyDoc(
      threadView ? 'threads' : 'messages',
      messageId,
      messageLikeConverter
    )
  );
  const location = useLocation();
  const deleted = location.pathname.includes('/deleted');

  const {
    comments,
    hasMore: hasMoreComments,
    fetchMore: fetchMoreComments,
  } = useComments(headerMessage?.teamId, messageIds, onAddComment);

  useEffect(() => {
    if (threadView && headerMessage) {
      return registerUnsubscribe(
        uuidv4(),
        onSnapshot(
          query(
            companyCollection('messages', messageConverter),
            where('teamId', '==', headerMessage.teamId),
            where('threadId', '==', headerMessage.id),
            where('deleted', '==', deleted)
          ),
          (snapshot) => {
            if (snapshot.size) {
              const messages = snapshot.docs.map((doc) => doc.data());
              messages.sort((a, b) => a.date.diff(b.date));
              setMessages(messages);
              setMessageIds((prev) => {
                const ids = messages.map((message) => message.id);
                return isEqual(prev, ids) ? prev : ids;
              });
            } else {
              Sentry.withScope((scope) => {
                scope.setTag('thread', headerMessage.id);
                console.error('Messages not found in thread');
              });
            }
          }
        )
      );
    }
  }, [threadView, headerMessage?.id, headerMessage?.teamId, deleted]);

  useEffect(() => {
    if (!threadView && headerMessage) {
      setMessages([headerMessage.asMessage()]);
      if (messageIds[0] !== headerMessage.id) {
        setMessageIds([headerMessage.id]);
      }
    }
  }, [threadView, headerMessage]);

  useEffect(() => {
    if (!headerMessage || !messages?.length) {
      return;
    }

    const teamId = headerMessage.teamId;
    const unsubscribes: Unsubscribe[] = [];

    const messageIdField = threadView ? 'threadId' : 'inReplyToMessageId';
    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        onSnapshot(
          query(
            companyCollection('sent', sentConverter),
            where('teamId', '==', headerMessage.teamId),
            where(messageIdField, '==', headerMessage.id)
          ),
          (snapshot) => {
            setSentMessages(snapshot.docs.map((doc) => doc.data()));
            snapshot.docChanges().forEach((change) => {
              if (!loading && change.type === 'added') {
                onAddSentMessage(change.doc.data());
              }
            });
          }
        )
      )
    );

    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        subscribeQueryInArray(
          query(
            companyCollection('drafts', draftConverter),
            where('teamId', '==', teamId)
          ),
          'inReplyToMessageId',
          messageIds,
          (snapshot, docs) => {
            setDrafts(docs.map((doc) => doc.data()));
          }
        )
      )
    );

    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        subscribeQueryInArray(
          query(
            companyCollection('locks', lockConverter),
            where('teamId', '==', teamId)
          ),
          'messageId',
          messageIds,
          (snapshot, docs) => {
            setLocks(docs.map((doc) => doc.data()));
          }
        )
      )
    );

    unsubscribes.push(
      registerUnsubscribe(
        uuidv4(),
        subscribeQueryInArray(
          query(
            companyCollection('events', eventConverter),
            where('teamId', '==', teamId)
          ),
          'messageId',
          messageIds,
          (snapshot, docs) => {
            setEvents(docs.map((doc) => doc.data()));
          }
        )
      )
    );

    return () => unsubscribes.forEach((f) => f());
  }, [threadView, headerMessage?.teamId, messageIds]);

  if (
    headerMessage &&
    messages?.length &&
    sentMessages &&
    drafts &&
    locks &&
    events &&
    comments
  ) {
    if (loading) {
      setLoading(false);
    }
    return {
      loading: false,
      headerMessage:
        threadView && deleted ? headerMessage.toDeletedThread() : headerMessage,
      messages,
      sentMessages,
      drafts,
      locks,
      events,
      comments,
      hasMoreComments,
      fetchMoreComments,
    };
  }
  return {
    loading: true,
  };
};

type UseCommentsReturn = {
  comments: Comment[];
  hasMore: boolean;
  fetchMore: () => Promise<boolean>;
};

const useComments = (
  teamId: string | undefined,
  messageIds: string[],
  onAddComment: (comment: Comment) => void
): UseCommentsReturn => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [commentCount, setCommentCount] =
    useState<{ [key in string]: number }>();

  const addedCommentIdsRef = useRef<string[]>([]);
  const startTimeRef = useRef(Timestamp.now());
  const filterTimeRef = useRef(Timestamp.now());
  const fetchingRef = useRef(false);
  const initialFetchedRef = useRef(false);

  const internalFetchMore = useCallback(
    async (countMap?: { [key in string]: number }) => {
      if (fetchingRef.current) {
        return false;
      }

      const counts = countMap ?? commentCount;
      if (!counts) {
        return false;
      }

      fetchingRef.current = true;

      const promises = messageIds.map((messageId) =>
        getDocs(
          query(
            companyCollection('comments', commentConverter),
            where('teamId', '==', teamId),
            where('messageId', '==', messageId),
            where('createdAt', '<', filterTimeRef.current),
            orderBy('createdAt', 'desc'),
            limit(COMMENT_PAGE_SIZE)
          )
        ).then((r) => r.docs.map((r) => r.data()))
      );
      try {
        const result = await Promise.all(promises).then(flatten);
        const sliced = result
          .sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis())
          .slice(0, COMMENT_PAGE_SIZE);
        if (!sliced.length) {
          return false;
        }

        filterTimeRef.current = sliced.at(-1)!.createdAt;
        setComments((prev) => [...prev, ...sliced]);
        return true;
      } catch (e) {
        console.error(e);
      } finally {
        fetchingRef.current = false;
      }
      return false;
    },
    [messageIds, commentCount, comments.length]
  );

  const fetchMore = useCallback(() => internalFetchMore(), [internalFetchMore]);

  const initialFetch = useCallback(async () => {
    if (!teamId || !messageIds.length) {
      return;
    }
    const countPromises = messageIds.map(
      async (messageId): Promise<[string, number]> => [
        messageId,
        await getCountFromServer(
          query(
            companyCollection('comments', commentConverter),
            where('teamId', '==', teamId),
            where('messageId', '==', messageId)
          )
        ).then((r) => r.data().count),
      ]
    );
    const countResult = await Promise.all(countPromises).then((r) =>
      Object.fromEntries(r)
    );
    setCommentCount(countResult);
    await internalFetchMore(countResult);
    initialFetchedRef.current = true;
  }, [teamId, messageIds]);

  const totalCommentCount = useMemo(() => {
    if (!commentCount) {
      return 0;
    }
    return sumBy(Object.entries(commentCount), (e) => e[1]);
  }, [commentCount]);
  const hasMore = useMemo(() => {
    return comments.length < totalCommentCount;
  }, [comments.length, totalCommentCount]);

  useEffect(() => {
    if (initialFetchedRef.current) {
      return;
    }
    initialFetch().then();
  }, [initialFetch]);

  useEffect(() => {
    if (!teamId) {
      return;
    }
    return registerUnsubscribe(
      v4(),
      subscribeQueryInArray(
        query(
          companyCollection('comments', commentConverter),
          where('teamId', '==', teamId)
        ),
        'messageId',
        messageIds,
        (snapshot) => {
          for (const docChange of snapshot.docChanges()) {
            const data = docChange.doc.data();
            if (docChange.type === 'added') {
              if (addedCommentIdsRef.current.includes(data.id)) {
                continue;
              }
              if (data.createdAt < startTimeRef.current) {
                continue;
              }
              setComments((prev) => [...prev, data]);
              setCommentCount((prev) => ({
                ...prev,
                [data.messageId]: (prev?.[data.messageId] ?? 0) + 1,
              }));
              onAddComment(data);
              addedCommentIdsRef.current.push(data.id);
            }
            if (docChange.type === 'removed') {
              setComments((prev) =>
                prev.filter((c) => c.id !== docChange.doc.id)
              );
              setCommentCount((prev) => ({
                ...prev,
                [data.messageId]: Math.max(
                  (prev?.[data.messageId] ?? 0) - 1,
                  0
                ),
              }));
            }
            if (docChange.type === 'modified') {
              setComments((prev) => {
                const index = prev.findIndex((c) => c.id === docChange.doc.id);
                if (index >= 0) {
                  const newComments = [...prev];
                  newComments[index] = data;
                  return newComments;
                }
                return prev;
              });
            }
          }
        }
      )
    );
  }, [teamId, messageIds, onAddComment]);

  return { comments, hasMore, fetchMore };
};
