import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAutoReplied = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.7 2.4 3.6 6.867l5.09.463-.8-1.316a7.631 7.631 0 0 1 3.814-1.01c4.1 0 7.422 3.185 7.422 7.113 0 3.928-3.323 7.112-7.422 7.112-2.413 0-4.558-1.104-5.913-2.813L3.82 17.849c1.807 2.28 4.666 3.751 7.884 3.751 5.465 0 9.896-4.246 9.896-9.483 0-5.238-4.43-9.483-9.896-9.483-1.847 0-3.577.485-5.057 1.33L5.7 2.4Zm10.49 9.716-7.422 4.106V8.01l7.421 4.106Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAutoReplied;
