import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSendOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m18.1 11.65-3.55 3.55a.677.677 0 0 0-.212.525c.008.2.087.375.237.525.15.15.33.225.538.225a.734.734 0 0 0 .537-.225l4.825-4.825a.72.72 0 0 0 .225-.525.72.72 0 0 0-.225-.525l-4.85-4.85a.734.734 0 0 0-.537-.225.734.734 0 0 0-.538.225.734.734 0 0 0-.225.537c0 .209.075.388.225.538l3.55 3.55H7.95c-1.416 0-2.596.47-3.537 1.412C3.47 12.504 3 13.683 3 15.1v3.15a.729.729 0 0 0 .75.75.728.728 0 0 0 .75-.75V15.1c0-1 .325-1.825.975-2.475.65-.65 1.475-.975 2.475-.975H18.1Z"
    />
  </svg>
);
export default SvgSendOn;
