import { useEffect, useState } from 'react';
import { Company } from 'components/icons';
import { RightSidebarMessage } from './RightSidebarMessage';
import { Loading } from 'components/basics';
import { SearchMessage } from 'store/search';
import { RightSidebarCard } from './RightSidebarCard';
import { RightSidebarCardMoreButton } from './RightSidebarCardMoreButton';
import { observer } from 'mobx-react';
import { useStore } from '../../../hooks/useStore';

const DEFAULT_VISIBLE_MESSAGES = 10;
const LOAD_MORE_COUNT = 10;

export const CompanyInteractionHistorySection = observer(() => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const [visibleMessages, setVisibleMessages] = useState(
    DEFAULT_VISIBLE_MESSAGES
  );
  const [domainMessages, setDomainMessages] = useState<SearchMessage[]>([]);

  const contactData = store.contactStore.selectedContact;
  const contactDomain = contactData?.email.split('@')[1];

  const loadMoreMessages = () =>
    setVisibleMessages((prev) => prev + LOAD_MORE_COUNT);

  useEffect(() => {
    const fetchDomainMessages = async () => {
      if (!contactData?.teamId || !contactDomain) return;

      setLoading(true);
      try {
        const messages = await store.searchStore.searchMessagesByDomain(
          contactData.teamId,
          contactDomain,
          visibleMessages
        );
        setDomainMessages(messages);
      } catch (error) {
        console.error('Failed to fetch domain messages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDomainMessages();
  }, [contactDomain, contactData?.teamId, visibleMessages]);

  return (
    <RightSidebarCard icon={Company} title="会社との対応履歴">
      <div className="-mt-3 flex flex-col">
        {loading && domainMessages.length === 0 && (
          <div className="flex justify-center p-12">
            <Loading size={24} />
          </div>
        )}
        {!loading && domainMessages.length === 0 && (
          <div className="px-4 py-2 text-[#a7a7aa]">履歴が存在しません</div>
        )}
        {domainMessages.map((message) => (
          <RightSidebarMessage key={message.id} message={message} />
        ))}
        {!loading && domainMessages.length >= visibleMessages && (
          <div className="mt-3">
            <RightSidebarCardMoreButton onClick={loadMoreMessages} />
          </div>
        )}
      </div>
    </RightSidebarCard>
  );
});
