import React, { useEffect, useState } from 'react';
import { Button, Icon, message as antdMessage, Modal, Select, Tag } from 'antd';
import { Heading } from 'components/basics/Heading/Heading';
import { DefaultInput } from '../../../../Common/Input';
import { getTagLabel } from '../../../../../util';
import { DefaultButton } from '../../../../Common/Button';
import { v4 as uuidv4 } from 'uuid';
import { db, FieldValue } from '../../../../../firebase';
import { eventNames, logEvent } from '../../../../../analytics';

const { Option } = Select;

const ALL_ACTIONS = {
  addTag: {
    label: 'タグを付ける',
    options: {
      tags: [],
    },
  },
  skipInbox: {
    label: '受信トレイをスキップする',
  },
};

const newAction = (type) => {
  const options = ALL_ACTIONS[type].options;
  return {
    id: uuidv4(),
    type,
    ...options,
  };
};

export default ({ store, message, visible, onCancel, afterClose }) => {
  const teamId = message.teamId;
  const email = message.from.value[0].address;

  const [teamTags] = useState(() => store.getTags(teamId));
  const [name, setName] = useState('');
  const [actions, setActions] = useState([
    newAction(Object.keys(ALL_ACTIONS)[0]),
  ]);
  const [isAdding, setIsAdding] = useState(false);

  const close = () => {
    onCancel();
  };

  useEffect(() => {
    if (visible) {
      setName('');
      setActions([newAction(Object.keys(ALL_ACTIONS)[0])]);
    }
  }, [visible]);

  const add = async (e) => {
    e.preventDefault();
    if (!isValid()) {
      return;
    }

    setIsAdding(true);

    const inboxIds = [message.inboxId];
    const conditions = [
      {
        id: uuidv4(),
        equalType: 'contain',
        filterText: email,
        isOr: false,
        type: 'from',
      },
    ];
    const teamFilters = store.getFilters(teamId);
    const order = teamFilters.reduce((a, b) => Math.max(a, b.order), -1) + 1;
    const now = FieldValue.serverTimestamp();

    await db.collection(`companies/${store.signInCompany}/filters`).add({
      name,
      inboxIds,
      conditions,
      actions,
      teamId,
      order,
      createdAt: now,
      updatedAt: now,
    });
    logEvent(eventNames.add_filter);
    antdMessage.success('フローを作成しました');
    setIsAdding(false);
    close();
  };

  const isValid = () => {
    if (!name) {
      return false;
    }
    return actions.every(
      (action) => action.type !== 'addTag' || action.tags.length
    );
  };

  const setAction = (action, index) => {
    const newActions = [...actions];
    newActions[index] = action;
    setActions(newActions);
  };

  const onChangeActionType = (type, index) => setAction(newAction(type), index);

  const onChangeTags = (action, tags, index) => {
    setAction(
      {
        ...action,
        tags: tags,
      },
      index
    );
  };

  const addAction = () => {
    const types = actions.map((action) => action.type);
    const type = Object.keys(ALL_ACTIONS).find((type) => !types.includes(type));
    setActions([...actions, newAction(type)]);
  };

  const removeAction = (index) => {
    const newActions = [...actions];
    newActions.splice(index, 1);
    setActions(newActions);
  };

  const isDisabledOption = (id, type) =>
    actions.some((action) => action.id !== id && action.type === type);

  return (
    <Modal
      visible={visible}
      onCancel={close}
      afterClose={afterClose}
      footer={null}
      width={600}
    >
      <Heading level={1} className={`mt-[30px] pb-[30px] text-center`}>
        フローを追加する
      </Heading>
      <div className={`mx-[20px] my-0`}>
        <Heading level={3} className={`mr-[10px] min-w-[250px]`}>
          フロー名
        </Heading>
        <div className={`mb-[20px] flex`}>
          <DefaultInput
            className="flex-1"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <Heading level={3} className={`mr-[10px] min-w-[250px]`}>
          条件（送信元メールアドレス）
        </Heading>
        <div className={`mb-[20px] flex`}>
          <DefaultInput className="flex-1" value={email} disabled />
        </div>
        <Heading level={3} className={`mr-[10px] min-w-[250px]`}>
          処理
        </Heading>
        <div className={`mb-[20px] flex`}>
          <div className={`flex flex-1 flex-col gap-[8px]`}>
            {actions.map((action, index) => (
              <div className={`flex`} key={action.id}>
                <Select
                  value={action.type}
                  style={{
                    minWidth: 200,
                  }}
                  onChange={(value) => onChangeActionType(value, index)}
                >
                  {Object.entries(ALL_ACTIONS).map(([key, value]) => (
                    <Option
                      key={key}
                      value={key}
                      disabled={isDisabledOption(action.id, key)}
                    >
                      {value.label}
                    </Option>
                  ))}
                </Select>
                {action.type === 'addTag' && (
                  <Select
                    mode="multiple"
                    value={action.tags}
                    style={{
                      flex: '1 1 auto',
                      marginLeft: 4,
                    }}
                    onChange={(value) => onChangeTags(action, value, index)}
                  >
                    {teamTags.map((tag) => {
                      const parentTag = teamTags.find(
                        (t) => t.id === tag.parentTagId
                      );
                      const { shortLabel, label } = getTagLabel(tag.name, {
                        parentTagLabel: parentTag?.name,
                      });
                      return (
                        <Option key={tag.id} value={tag.id}>
                          <Tag color={tag.color}>{shortLabel ?? label}</Tag>
                        </Option>
                      );
                    })}
                  </Select>
                )}
                {actions.length > 1 && (
                  <div className={`ml-[8px] mr-[4px] flex items-center`}>
                    <Icon
                      className={`text-[20px] text-[#cccccc]`}
                      type="minus-circle-o"
                      onClick={() => removeAction(index)}
                    />
                  </div>
                )}
              </div>
            ))}
            {actions.length < Object.keys(ALL_ACTIONS).length && (
              <Button
                type="dashed"
                onClick={addAction}
                style={{ flex: '1 1 auto' }}
              >
                <Icon type="plus" /> 処理を追加する
              </Button>
            )}
          </div>
        </div>
        <div className={`mb-[20px] flex justify-center`}>
          <DefaultButton
            className={`mr-[16px] h-[40px] w-[100px]`}
            type="danger"
            onClick={close}
            disabled={isAdding}
          >
            キャンセル
          </DefaultButton>
          <DefaultButton
            className={`h-[40px] w-[100px]`}
            type="primary"
            onClick={add}
            disabled={!isValid() || isAdding}
          >
            追加
          </DefaultButton>
        </div>
      </div>
    </Modal>
  );
};
