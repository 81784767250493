import { PCSplitPane } from '../PCSplitPane';
import { MessagePaneDetail } from './MessagePaneDetail';
import { matchPath, useLocation } from 'react-router-dom';
import { MessageListWrapper } from 'components/MessageList';
import { isSP } from 'shared/util';
import React from 'react';
import { SPMessageListLayout } from '../MessageList/SPMessageListLayout';

type Props = {
  listPagePath: string | string[];
  empty: boolean;
  emptyComponent: React.ReactNode;
  listComponent: React.ReactNode;
};

export const BaseMessagePane: React.FC<Props> = ({
  listPagePath,
  emptyComponent,
  empty,
  listComponent,
}) => {
  const location = useLocation();

  const isListPage = !!matchPath(location.pathname, {
    path: listPagePath,
    exact: true,
  });

  if (isSP()) {
    if (isListPage) {
      return (
        <SPMessageListLayout>
          {empty ? emptyComponent : listComponent}
        </SPMessageListLayout>
      );
    }

    return <MessagePaneDetail />;
  }

  if (isListPage && empty) {
    return <>{emptyComponent}</>;
  }

  return (
    <PCSplitPane
      defaultWidth={300}
      maxWidth={800}
      minWidth={300}
      preferenceKey="InnerSplitPane"
    >
      <MessageListWrapper>{listComponent}</MessageListWrapper>
      <div key="right-sidebar" className="grid h-full grid-cols-[1fr_auto]">
        <div className="h-full max-h-full overflow-auto">
          <MessagePaneDetail />
        </div>
      </div>
    </PCSplitPane>
  );
};
