import * as React from 'react';
import type { SVGProps } from 'react';
const SvgThumbUpGradient = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    viewBox="0 0 28 28"
    {...props}
  >
    <rect width={27} height={27} x={0.5} y={0.5} stroke="url(#a)" rx={13.5} />
    <path
      fill="url(#b)"
      d="M21.5 10.667c.445 0 .833.166 1.167.5.333.333.5.722.5 1.166V14c0 .097-.014.201-.042.313a9.66 9.66 0 0 1-.083.312l-2.5 5.875a1.683 1.683 0 0 1-.625.708A1.628 1.628 0 0 1 19 21.5H9.833V10.667l5-4.959c.209-.208.455-.33.74-.364.285-.035.559.017.823.156.264.139.458.333.583.583.125.25.153.507.084.771l-.938 3.813H21.5Zm-10 .708v8.458H19L21.5 14v-1.667H14l1.125-4.583-3.625 3.625ZM7.333 21.5c-.458 0-.85-.163-1.177-.49a1.605 1.605 0 0 1-.49-1.177v-7.5c0-.458.164-.85.49-1.177.327-.326.719-.49 1.177-.49h2.5v1.667h-2.5v7.5h2.5V21.5h-2.5Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={14}
        x2={14}
        y1={0}
        y2={28}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={14.417}
        x2={14.417}
        y1={5.333}
        y2={21.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4E8BE6" />
        <stop offset={0.703} stopColor="#60CBED" />
        <stop offset={1} stopColor="#60DCED" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgThumbUpGradient;
