import { useEffect, useState, useReducer, useMemo } from 'react';

import { DefaultInput } from '../Common/Input';
import { siteKey } from '../../firebase';
import { Button, Menu, Modal } from 'antd';
import { Heading } from 'components/basics/Heading/Heading';

import {
  getAuth,
  reauthenticateWithCredential,
  EmailAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
} from 'firebase/auth';
import RecaptchaContainer from './RecaptchaContainer';

/**
 * @typedef {object} MFAModalOptions
 * @prop {string=} title
 */

/**
 *
 * @param {MFAModalOptions=} ops
 * @returns
 */
export default function useMFAModal(ops) {
  const title = ops?.title ?? '再認証';

  /**
   * @type {import('antd/lib/modal').ModalFuncProps}
   */
  const initModalProps = useMemo(
    () => ({
      title,
      content: '',
      onCancel: () => {
        dispatch({ type: 'close' });
      },
    }),
    [title]
  );

  const [callbacks, setCallbacks] = useState(null);

  /**
   * @typedef {object} ModalState
   * @prop {'init' | 'input_password' | 'select_factor' | 'input_code' | 'done' | 'error'} state
   * @prop {import('antd/lib/modal').ModalFuncProps} props
   * @prop {string=} password
   * @prop {string=} code
   * @prop {() => void=} resolve
   * @prop {(err: unknown) => void=} reject
   * @prop {import('firebase/auth').MultiFactorResolver=} resolver
   */

  /**
   * @typedef {object} OpenModalAction
   * @prop {'open'} type
   */

  /**
   * @typedef {object} CloseModalAction
   * @prop {'close'} type
   */

  /**
   * @typedef {object} SetPasswordModalAction
   * @prop {'set_password'} type
   * @prop {string} password
   */

  /**
   * @typedef {object} SetCodeModalAction
   * @prop {'set_code'} type
   * @prop {string} code
   */

  /**
   * @typedef {object} RequestSelectFactorModalAction
   * @prop {'request_select_factor'} type
   * @prop {import('firebase/auth').MultiFactorResolver} resolver
   */

  /**
   * @typedef {object} SelectFactorModalAction
   * @prop {'select_factor'} type
   * @prop {import('firebase/auth').MultiFactorInfo} info
   */

  /**
   * @typedef {object} RequestCodeModalAction
   * @prop {'request_code'} type
   * @prop {import('firebase/auth').MultiFactorResolver} resolver
   */

  /**
   * @typedef {OpenModalAction | CloseModalAction | SetPasswordModalAction | RequestSelectFactorModalAction | SelectFactorModalAction | RequestCodeModalAction | SetCodeModalAction} ModalAction
   */

  /**
   *
   * @param {ModalState} state
   * @param {ModalAction} action
   * @returns {ModalState}
   */
  function reducer(state, action) {
    function reauthenticate() {
      return reauthenticateWithCredential(
        getAuth().currentUser,
        EmailAuthProvider.credential(
          getAuth().currentUser.email,
          action.password
        )
      ).catch((err) => {
        if (err.code !== 'auth/multi-factor-auth-required') {
          throw err;
        }
        const resolver = getMultiFactorResolver(getAuth(), err);
        dispatch({ type: 'request_factor', resolver });
        return Promise.reject();
      });
    }

    if (action.type === 'open' && action?.password) {
      reauthenticate();
      return {
        promise: action.promise || state.promise,
        resolve: action.resolve || state.resolve,
        reject: action.reject || state.reject,
        password: action.password,
        props: {
          ...initModalProps,
          content: null,
        },
      };
    }

    switch (action.type) {
      case 'open':
        return {
          state: 'input_password',
          promise: action.promise,
          resolve: action.resolve,
          reject: action.reject,
          props: {
            ...initModalProps,
            content: (
              <InputPasswordContent
                password={state.password}
                onChange={(event) => {
                  const val = event.target.value;
                  dispatch({ type: 'set_password', password: val });
                }}
              />
            ),
          },
        };
      case 'close':
        if (action.cred && state.resolve) {
          state.resolve(action.cred);
        } else {
          if (state.reject) {
            state.reject(new Error('close'));
          }
        }
        return { state: 'init', props: initModalProps };
      case 'set_password':
        return {
          ...state,
          password: action.password,
          props: {
            ...state.props,
            content: (
              <InputPasswordContent
                password={action.password}
                onChange={(event) => {
                  const val = event.target.value;
                  dispatch({ type: 'set_password', password: val });
                }}
              />
            ),
            onOk: () => reauthenticate(),
          },
        };
      case 'request_factor':
      case 'update_recaptcha':
        /**
         * @type {import('firebase/auth').MultiFactorResolver}
         */
        const resolver = action.resolver || state.resolver;
        const recaptchaVerifier =
          action.type === 'update_recaptcha'
            ? action.recaptchaVerifier
            : state.recaptchaVerifier;
        return {
          ...state,
          state: 'select_factor',
          resolver,
          recaptchaVerifier,
          props: {
            ...state.props,
            content: (
              <form>
                <RecaptchaContainer
                  siteKey={siteKey}
                  onCheckBox={({ recaptchaVerifier }) => {
                    dispatch({ type: 'update_recaptcha', recaptchaVerifier });
                  }}
                  expiredCallback={() =>
                    dispatch({
                      type: 'update_recaptcha',
                      recaptchaVerifier: null,
                    })
                  }
                  onError={(error) => state.reject(error)}
                />
                <Menu
                  onClick={(event) => {
                    const { key } = event;
                    if (!recaptchaVerifier) {
                      return null;
                    }
                    const hint = resolver.hints.find(
                      (hint) => hint.uid === key
                    );
                    if (hint) {
                      const phoneInfoOptions = {
                        multiFactorHint: hint,
                        session: resolver.session,
                      };
                      const phoneAuthProvider = new PhoneAuthProvider(
                        getAuth()
                      );
                      // Send SMS verification code
                      return phoneAuthProvider
                        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                        .then(function (verificationId) {
                          dispatch({ type: 'request_code', verificationId });
                        });
                    }
                  }}
                >
                  {resolver.hints.map((hint) => (
                    <Menu.Item disabled={!recaptchaVerifier} key={hint.uid}>
                      <Button
                        disabled={!recaptchaVerifier}
                        style={{ width: '250px' }}
                      >
                        {hint.phoneNumber.replaceAll('*', 'X')} にSMSを送信
                      </Button>
                    </Menu.Item>
                  ))}
                </Menu>
              </form>
            ),
            okButtonProps: { style: { display: 'none' } },
          },
        };
      case 'request_code':
        const verificationId = action.verificationId;
        return {
          ...state,
          state: 'input_code',
          verificationId,
          props: {
            ...state.props,
            content: (
              <InputCodeContent
                code={state.code}
                onChange={(event) => {
                  const val = event.target.value;
                  dispatch({ type: 'set_code', code: val });
                }}
              />
            ),

            okButtonProps: { style: {} },
          },
        };
      case 'set_code':
        const code = action.code || state.code;
        return {
          ...state,
          code,
          props: {
            ...state.props,
            content: (
              <InputCodeContent
                code={code}
                onChange={(event) => {
                  const val = event.target.value;
                  dispatch({ type: 'set_code', code: val });
                }}
              />
            ),
            onOk: async () => {
              const cred = PhoneAuthProvider.credential(
                state.verificationId,
                code
              );
              const multiFactorAssertion =
                PhoneMultiFactorGenerator.assertion(cred);
              const _cred =
                await state.resolver.resolveSignIn(multiFactorAssertion);
              dispatch({ type: 'close', cred: _cred });
            },
          },
        };
      default:
    }
    return state;
  }

  const [state, dispatch] = useReducer(reducer, {
    state: 'init',
    props: initModalProps,
  });

  useEffect(() => {
    function openOrUpdate(props) {
      if (callbacks) {
        callbacks.update(props);
      } else {
        setCallbacks(Modal.confirm(props));
      }
    }
    switch (state.state) {
      case 'init':
      case 'done':
      case 'error':
        if (callbacks) {
          callbacks.destroy();
          setCallbacks(null);
        }
        break;
      default:
        openOrUpdate(state.props);
    }
  }, [callbacks, initModalProps, state]);

  return {
    /**
     * @param {import('firebase/auth').User} user
     * @returns {Promise<void>}
     */
    reauthenticate: (user, { password } = {}) => {
      let resolve, reject;
      const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
      });
      dispatch({
        type: 'open',
        user,
        password,
        promise,
        resolve,
        reject,
      });
      return promise;
    },
  };
}

const InputPasswordContent = ({ password, onChange }) => (
  <>
    <Heading level={3}>パスワード</Heading>
    <DefaultInput
      className={`mb-[10px] h-[40px] w-[400px]`}
      style={{ width: '100%' }}
      type="password"
      value={password}
      placeholder="************"
      onChange={onChange}
    />
  </>
);

const InputCodeContent = ({ code, onChange }) => (
  <>
    <Heading level={3}>確認コード</Heading>
    <DefaultInput
      className={`mb-[10px] h-[40px] w-[400px]`}
      style={{ width: '100%' }}
      type="text"
      value={code}
      onChange={onChange}
    />
  </>
);
