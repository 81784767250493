import { ComponentProps, useCallback, useState } from 'react';
import { useConfirmDialog } from '../../../hooks/confirmDialog';
import { MemoEditForm } from '../MemoEditForm/MemoEditForm';
import { Icon } from '../../basics';
import * as Popover from '@radix-ui/react-popover';
import { More } from '../../icons';
import { tv } from 'tailwind-variants';
import { Avatar } from '../../basics/Avatar/Avatar';
import { Tooltip } from '../../basics/Tooltip/Tooltip';

type Props = {
  id: string;
  avatar: Omit<ComponentProps<typeof Avatar>, 'size'>;
  username: string;
  text: string;
  timestamp: Date | undefined;
  onEdit: (id: string, text: string) => Promise<boolean>;
  onDelete: (id: string) => Promise<void>;
};

const menuItem = tv({
  base: 'm-0 flex h-8 cursor-pointer items-center rounded bg-transparent px-2 py-0 hover:bg-sumi-100 ',
});

export const ContactDrawerMemo = ({
  id,
  avatar,
  username,
  text,
  timestamp,
  onEdit,
  onDelete,
}: Props) => {
  const confirm = useConfirmDialog();
  const [editing, setEditing] = useState(false);

  const handleDelete = useCallback(() => {
    confirm({
      title: 'メモを削除しますか？',
      description: '一度削除すると元に戻せません',
      okType: 'danger',
      okText: '削除',
      onOk: () => onDelete(id),
    });
  }, [confirm, onDelete, id]);

  const startEditing = useCallback(() => setEditing(true), []);

  const handleEdit = useCallback(
    async (value: string) => {
      if (await onEdit(id, value)) {
        setEditing(false);
      }
    },
    [onEdit, id]
  );

  if (editing) {
    return (
      <div className="relative flex flex-col gap-2 text-sm">
        <MemoEditForm value={text} onEdit={handleEdit} onCancel={setEditing} />
      </div>
    );
  }

  return (
    <div className="relative flex flex-col gap-2 text-sm">
      <div className="flex items-center gap-2">
        <Avatar {...avatar} size={32} />
        <div className="truncate font-semibold" title={username}>
          {username}
        </div>
        <div
          className="truncate text-gray-600"
          title={timestamp?.toLocaleString()}
        >
          {timestamp && timestamp.toLocaleString()}
        </div>
        <Popover.Root>
          <Tooltip content="その他" delayDuration={0.5}>
            <Popover.Trigger
              aria-label="メッセージメニュー"
              className="m-0 ml-auto block cursor-pointer bg-transparent p-0"
            >
              <Icon icon={More} size={24} className="block" />
            </Popover.Trigger>
          </Tooltip>
          <Popover.Content
            align="end"
            className="relative z-50 flex flex-col rounded-lg bg-white p-2 shadow-dropdown"
          >
            <button type="button" className={menuItem()} onClick={startEditing}>
              編集
            </button>
            <button type="button" className={menuItem()} onClick={handleDelete}>
              削除
            </button>
          </Popover.Content>
        </Popover.Root>
      </div>
      <div className="whitespace-pre-wrap break-all">{text}</div>
    </div>
  );
};
