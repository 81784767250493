import { Tooltip } from '../../basics/Tooltip/Tooltip';
import { Icon } from '../../basics';
import { AddReaction } from '../../icons';
import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = Omit<ComponentPropsWithoutRef<'button'>, 'children'>;

export const AddReactionButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, ...props }, ref) => {
    return (
      <Tooltip content="リアクションする">
        <button
          type="button"
          className={twMerge(
            'm-0 flex h-7 select-none items-center gap-1 rounded-lg border border-sumi-100 bg-white p-0 px-1.5 text-sm text-sumi-600 hover:border-sumi-300 enabled:cursor-pointer disabled:opacity-50',
            className
          )}
          {...props}
          ref={ref}
        >
          <Icon icon={AddReaction} size={18} />
        </button>
      </Tooltip>
    );
  }
);
