import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { Button, Icon } from '../../../components/basics';
import { Edit } from '../../../components/icons';
import { Input } from '../../../components/forms';
import { observer } from 'mobx-react';
import { Tooltip } from '../../../components/basics/Tooltip/Tooltip';

export const RightSidebarContactName = observer(() => {
  const store = useStore();
  const contact = store.contactStore.selectedContact;
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const startEditing = useCallback(() => {
    setEditing(true);
    setTimeout(() => inputRef.current?.select());
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (!contact) {
        return;
      }
      const value = inputRef.current?.value.trim();
      if (contact.name === value) {
        setEditing(false);
        return;
      }
      setLoading(true);
      try {
        if (!value) {
          return;
        }
        await store.contactStore.updateSelectedContact({
          ...contact,
          name: value,
        });
        setEditing(false);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [contact, store.contactStore.updateSelectedContact]
  );

  useEffect(() => {
    if (!editing) {
      return;
    }
    const handleClick = (e: MouseEvent) => {
      if (loading) {
        return;
      }
      const target = e.target;
      if (!(target instanceof Element)) {
        return;
      }

      if (
        target.getAttribute('data-contact-name') ||
        target.closest('[data-contact-name]')
      ) {
        return;
      }

      setEditing(false);
    };
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [editing, loading]);

  if (editing) {
    return (
      <form
        action=""
        onSubmit={handleSubmit}
        className="grid w-full grid-cols-[1fr_auto] gap-2"
        data-contact-name={true}
      >
        <Input
          ref={inputRef}
          defaultValue={contact?.name ?? ''}
          className="text-sm"
        />
        <Button type="submit" className="rounded-lg" loading={loading}>
          保存
        </Button>
      </form>
    );
  }

  return (
    <div
      className="group flex w-full justify-between"
      onDoubleClick={startEditing}
      data-contact-name={true}
    >
      <div className="text-lg font-semibold">{contact?.name}</div>
      <Tooltip content="名前を編集">
        <button
          type="button"
          className="m-0 cursor-pointer bg-transparent p-0 text-sumi-600 opacity-0 group-hover:opacity-100"
          onClick={startEditing}
        >
          <Icon icon={Edit} size={18} className="block" />
        </button>
      </Tooltip>
    </div>
  );
});
