import { useCallback, useState } from 'react';
import { useToast } from 'hooks/useToast';
import { meAtom } from 'atoms/auth';
import { useAtom, useAtomValue } from 'jotai';
import {
  addDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { companyCollection, companyDoc } from '../../../firestore';
import { RightSidebarCard } from './RightSidebarCard';
import { Description, Plus } from '../../../components/icons';
import { MemoEditForm } from '../../../components/contact/MemoEditForm/MemoEditForm';
import { Loading } from '../../../components/basics';
import { MemoEntry } from './MemoEntry';
import { RightSidebarCardMoreButton } from './RightSidebarCardMoreButton';
import { memosFamily } from '../../../atoms/firestore/memo';
import { useStore } from '../../../hooks/useStore';
import { ContactObject } from '../../../store/contact';
import { observer } from 'mobx-react';
import { List } from '../../../components/basics/List/List';

export const MemoInfoSection = observer(() => {
  const store = useStore();
  const contact = store.contactStore.selectedContact;

  if (!contact) {
    return null;
  }

  return <Internal contact={contact} />;
});

type InternalProps = {
  contact: ContactObject;
};

const Internal = observer(({ contact }: InternalProps) => {
  const { showToast } = useToast();
  const [isMemoEditing, setIsMemoEditing] = useState(false);
  const store = useStore();

  const me = useAtomValue(meAtom);
  const teamId = contact?.teamId;

  const handleAddMemo = useCallback(
    async (value: string) => {
      const contactId = await store.contactStore.updateSelectedContact(contact);

      await addDoc(companyCollection(`contacts/${contactId}/memos`), {
        userId: me.id,
        text: value,
        teamId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      setIsMemoEditing(false);
      showToast('success', 'メモを追加しました');
    },
    [contact, me.id, teamId]
  );

  const openMemoForm = useCallback(() => {
    setIsMemoEditing(true);
    return true;
  }, []);

  return (
    <RightSidebarCard
      icon={Description}
      title="メモ"
      rightSection={{
        icon: Plus,
        label: '追加',
        action: openMemoForm,
      }}
    >
      {isMemoEditing && (
        <MemoEditForm
          className="mb-3 last:mb-0"
          onEdit={handleAddMemo}
          value=""
          onCancel={setIsMemoEditing}
        />
      )}
      {hasId(contact) && (
        <List>
          <MemoList contact={contact} isEditing={isMemoEditing} />
        </List>
      )}
      {!contact.id && !isMemoEditing && <Empty />}
    </RightSidebarCard>
  );
});

type MemoListProps = {
  contact: Omit<ContactObject, 'id'> & { id: string };
  isEditing: boolean;
};

const MemoList = ({ contact, isEditing }: MemoListProps) => {
  const { showToast } = useToast();
  const [memos, dispatch] = useAtom(
    memosFamily({ contactId: contact.id, teamId: contact.teamId })
  );

  const handleEdit = useCallback(
    async (memoId: string, value: string) => {
      try {
        await updateDoc(companyDoc(`contacts/${contact.id}/memos`, memoId), {
          text: value,
          updatedAt: serverTimestamp(),
        });
        return true;
      } catch (e) {
        console.error('Failed to update memo:', e);
        showToast('error', 'メモの更新に失敗しました');
        return false;
      }
    },
    [contact.id, showToast]
  );

  const handleDelete = useCallback(
    async (memoId: string) => {
      try {
        await deleteDoc(companyDoc(`contacts/${contact.id}/memos`, memoId));
        showToast('success', 'メモを削除しました');
      } catch (error) {
        console.error('Failed to delete memo:', error);
        showToast('error', 'メモの削除に失敗しました');
      }
    },
    [contact.id, showToast]
  );

  if (memos.state === 'loading') {
    return (
      <div className="flex justify-center p-12">
        <Loading size={24} />
      </div>
    );
  }

  if (memos.state === 'hasError') {
    return <div>エラーが発生しました</div>;
  }

  return (
    <>
      {memos.data.length === 0 && !isEditing && <Empty />}
      {memos.data.map((memo) => (
        <List.Item key={memo.id}>
          <MemoEntry
            key={memo.id}
            memo={memo}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </List.Item>
      ))}
      {memos.hasMore && (
        <List.Item>
          <RightSidebarCardMoreButton onClick={() => dispatch('loadMore')} />
        </List.Item>
      )}
    </>
  );
};

const Empty = () => {
  return (
    <div className="flex h-12 items-center justify-center text-sumi-600">
      メモが存在しません
    </div>
  );
};

const hasId = <T extends object>(
  obj: Omit<T, 'id'> & { id?: string }
): obj is Omit<T, 'id'> & { id: string } => {
  return !!obj.id;
};
