import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { TagColor } from 'lib';
import { convertTagColorToHexColor } from '../../../color';
import { tv } from 'tailwind-variants';
import { Icon } from '../Icon';
import { Close } from '../../icons';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'color'> & {
  color: TagColor | null | undefined;
  size?: 'md' | 'sm';
  closable?: boolean;
  onClose?: () => void;
};

const tag = tv({
  base: 'grid w-fit select-none items-center rounded-lg border text-xs leading-4',
  variants: {
    colored: {
      true: 'bg-[color-mix(in_srgb,var(--tag-color)_10%,white)] text-[var(--tag-color)]',
      false: 'border-sumi-300 bg-sumi-100 text-black',
    },
    closable: {
      true: 'grid-cols-[1fr_auto] gap-1.5',
      false: 'grid-cols-[1fr]',
    },
    size: {
      sm: ' h-6 px-1',
      md: 'h-8 px-2',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const Tag = forwardRef<HTMLDivElement, Props>(
  (
    {
      color,
      size,
      closable = false,
      onClick,
      onClose,
      style,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const cssColor = color ? convertTagColorToHexColor(color) : undefined;
    return (
      <div
        className={twMerge(
          tag({ size, colored: !!color, closable }),
          className
        )}
        style={{
          ...style,
          borderColor: cssColor
            ? 'color-mix(in srgb ,var(--tag-color) 30%, white)' // Tailwindで機能しないのでスタイルで指定する
            : undefined,
          ['--tag-color' as never]: cssColor,
        }}
        onClick={(e) => {
          if ((e.target as HTMLElement).closest('[data-close-button]')) {
            return;
          }
          onClick?.(e);
        }}
        {...props}
        ref={ref}
      >
        {children}
        {closable && (
          <button
            type="button"
            onClick={(e) => {
              if (onClose) {
                e.stopPropagation();
                onClose();
              }
            }}
            className="cursor-pointer bg-transparent p-0"
            data-close-button={true}
          >
            <Icon icon={Close} size={16} className="block" />
          </button>
        )}
      </div>
    );
  }
);

Tag.displayName = 'Tag';
