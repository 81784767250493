import { Heading } from 'components/basics/Heading/Heading';
import { Table } from 'antd';
import React from 'react';
import { Button } from 'components/basics';
import { useHistory } from 'react-router-dom';

type Props = {
  loading: boolean;
  data: TagDataItem[];
  isSupportReportMessageByTags: boolean;
};

export type TagDataItem = {
  teamName: string;
  teamId: string;
  tagName: string;
  tagId: string | null | undefined;
  count: number;
};

const { Column } = Table;

export const ReportMessageByTagsTable: React.FC<Props> = ({
  loading,
  data,
  isSupportReportMessageByTags,
}) => {
  const history = useHistory();

  return (
    <div>
      <Heading level={2}>タグ別スレッド件数</Heading>
      <div className="relative max-w-[600px]">
        <Table
          dataSource={data}
          loading={loading}
          pagination={false}
          className="pb-6"
          style={{
            filter: isSupportReportMessageByTags ? 'blur(0)' : 'blur(4px)',
          }}
          rowKey={(record) => `${record.teamId}${record.tagId}`}
          bordered
        >
          <Column title="チーム" dataIndex="teamName" align="right" />
          <Column title="タグ" dataIndex="tagName" align="right" />
          <Column title="件数" dataIndex="count" align="right" />
        </Table>
        {!isSupportReportMessageByTags && (
          <Button
            onClick={() => history.push('/settings/company/payments')}
            variant="outlined"
            color="sumi"
            className="absolute left-1/2 top-1/2 w-[200px] -translate-x-1/2 -translate-y-1/2"
          >
            プランをアップデート
          </Button>
        )}
      </div>
    </div>
  );
};
