import { ReactNode } from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { Icon } from '../Icon';
import { Close } from '../../icons';
import { tv } from 'tailwind-variants';

type Props = {
  title: ReactNode;
  border?: boolean;
};

const header = tv({
  base: 'flex h-14 items-center justify-between px-4',
  variants: {
    border: {
      true: 'border-y border-b-sumi-300 border-t-transparent',
    },
  },
});

export const DialogHeader = ({ title, border = true }: Props) => {
  return (
    <div className={header({ border })}>
      <RadixDialog.Title className="m-0 text-[16px] font-bold">
        {title}
      </RadixDialog.Title>
      <RadixDialog.Close asChild>
        <button
          type="button"
          className="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-transparent p-0"
          data-testid="dialogCloseButton"
        >
          <Icon icon={Close} size={24} />
        </button>
      </RadixDialog.Close>
    </div>
  );
};
