import { Route, Switch } from 'react-router-dom';
import { PrintMessage, PrintMessagePath } from './Message/PrintMessage';
import { PrintSent, PrintSentPath } from './Sent/PrintSent';

export const AppPrintIndex = () => {
  return (
    <div className="flex h-[100vh]">
      <Switch>
        {/* メッセージ */}
        <Route path={PrintMessagePath} component={PrintMessage} />
        {/* 送信済み */}
        <Route path={PrintSentPath} component={PrintSent} />
      </Switch>
    </div>
  );
};
