import { Icon } from './Icon';
import { twMerge } from 'tailwind-merge';
import { ProgressActivity } from '../icons';

type Props = {
  size?: number | string;
  className?: string;
};

export const Loading = ({ size = `16px`, className }: Props) => {
  const cssSize = typeof size === 'number' ? `${size}px` : size;
  return (
    <div
      style={{ ['--size' as never]: cssSize }}
      className="h-[var(--size)] w-[var(--size)]"
      data-chromatic="ignore"
    >
      <Icon
        size={size}
        icon={ProgressActivity}
        className={twMerge('animate-spin', className)}
      />
    </div>
  );
};
