import { Button, Icon } from '../../../../components/basics';
import { Tooltip } from '../../../../components/basics/Tooltip/Tooltip';
import { ReactNode, useState } from 'react';
import { Dialog } from '../../../../components/basics/dialog/Dialog';
import { DialogHeader } from '../../../../components/basics/dialog/DialogHeader';
import { DialogContent } from '../../../../components/basics/dialog/DialogContent';
import SimpleBar from 'simplebar-react';
import { NoteAdd } from '../../../../components/icons';

type Props = {
  canCreateTemplate: boolean;
  renderDialog: (onClose: () => void) => ReactNode;
};

export const NewTemplate = ({ canCreateTemplate, renderDialog }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  return (
    <>
      <Tooltip
        content="上限に達したため、プランをアップグレードしてください。"
        visible={!canCreateTemplate}
      >
        <Button
          variant="text"
          color="sumi"
          className="flex items-center gap-1"
          disabled={!canCreateTemplate}
          onClick={() => setDialogOpen(true)}
        >
          <Icon icon={NoteAdd} size={18} />
          <span>下書きをテンプレートとして保存</span>
        </Button>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        overlayClassName="z-[1001]"
        contentClassName="top-[5dvh] z-[1001]"
      >
        <DialogHeader title="新規テンプレート"></DialogHeader>
        <SimpleBar className="max-h-[85dvh]">
          <DialogContent>
            {dialogOpen && renderDialog(() => setDialogOpen(false))}
          </DialogContent>
        </SimpleBar>
      </Dialog>
    </>
  );
};
