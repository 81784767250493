import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Textarea } from '../../forms/Textarea/Textarea';
import { Button } from '../../basics';
import { shouldSubmit } from '../../../utils/keyboard';

type Props = {
  value: string;
  onEdit: (value: string) => void;
  onCancel: (falsy: false) => void;
  className?: string;
  autoFocus?: boolean;
};

export const MemoEditForm: FC<Props> = ({
  value,
  onEdit,
  onCancel,
  className,
  autoFocus,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const handleCancel = useCallback(() => onCancel(false), [onCancel]);
  const onSubmit = useCallback(
    ({ value }: { value: string }) => onEdit(value),
    [onEdit]
  );
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    setFocus,
  } = useForm({
    defaultValues: {
      value,
    },
  });
  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (shouldSubmit(e)) {
      e.preventDefault();
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true }));
    }
  }, []);

  useEffect(() => {
    if (autoFocus) {
      setFocus('value');
    }
  }, [autoFocus, setFocus]);

  return (
    <form
      className={className}
      action=""
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <Textarea
        placeholder="メモを入力してください"
        {...register('value')}
        disabled={isSubmitting}
        autoFocus={true}
        onKeyDown={onKeyDown}
      />
      <div className="mt-2 flex justify-end space-x-2">
        <Button
          onClick={handleCancel}
          variant="text"
          disabled={isSubmitting}
          className="no-underline"
        >
          キャンセル
        </Button>
        <Button type="submit" disabled={isSubmitting}>
          メモを保存
        </Button>
      </div>
    </form>
  );
};
