import { SearchMessage } from 'store/search';
import moment from 'moment';
import { Message, MessageLike } from 'lib';
import { Attach, AutoReplied, OpenInNew } from 'components/icons';
import { ImpressionIcon } from '../MessageList/Teams/Messages/Message/ImpressionIcon';
import { Icon } from 'components/basics';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useMemo } from 'react';
import { useAtomValue } from 'jotai';
import { threadViewAtom } from '../../../atoms/auth';

type Props = {
  message: MessageLike | SearchMessage | Message;
};

export const RightSidebarMessage = ({ message }: Props) => {
  const threadView = useAtomValue(threadViewAtom);
  const hasNonRelatedAttachments =
    message.attachments?.filter((attachment) => !attachment.related).length > 0;

  const to = useMemo(() => {
    if (threadView && hasThreadId(message)) {
      return `/teams/${message.teamId}/messages/${message.threadId}?view=thread`;
    }
    return `/teams/${message.teamId}/messages/${message.id}`;
  }, [threadView, message]);

  return (
    <Link
      to={to}
      target="_blank"
      className="relative block w-full cursor-pointer py-3 text-sumi-900 before:absolute before:inset-x-2 before:bottom-0 before:h-[1px] before:bg-sumi-300 before:content-[''] last:before:hidden"
    >
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex flex-1 flex-col gap-1 overflow-hidden">
          {(hasNonRelatedAttachments || message.isAutoReplied) && (
            <div className="flex items-center gap-2">
              {message.isAutoReplied && (
                <Icon icon={AutoReplied} size={20} className="text-[#fc9a33]" />
              )}
              {hasNonRelatedAttachments && <Icon icon={Attach} size={20} />}
            </div>
          )}

          <div className="text-sm">
            <ImpressionIcon impression={message.impression} />
            <div
              className={twMerge(
                'truncate',
                message.subject ? '' : 'text-sumi-600'
              )}
            >
              {message.subject ?? '（件名がありません）'}
            </div>
          </div>
          <div className="flex items-center justify-between gap-1 text-xs">
            <div
              className={twMerge(
                'truncate',
                message.text ? '' : 'text-sumi-600'
              )}
            >
              {message.text ?? '（本文がありません）'}
            </div>
            <div className="whitespace-nowrap text-sumi-600">
              {message.date.isSame(moment(), 'day')
                ? message.date.format('HH:mm')
                : message.date.format('M月D日 HH:mm')}
            </div>
          </div>
        </div>
        <Icon icon={OpenInNew} size={20} className="text-sea-500" />
      </div>
    </Link>
  );
};

const hasThreadId = <T extends object>(
  obj: T
): obj is T & { threadId: string } => {
  return Object.keys(obj).includes('threadId');
};
