import {
  ComponentProps,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Icon } from '../../../components/basics';
import { CaretDown } from '../../../components/icons';
import { twMerge } from 'tailwind-merge';

type Props = {
  icon?: ComponentProps<typeof Icon>['icon'];
  title: string;
  rightSection?: {
    icon: ComponentProps<typeof Icon>['icon'];
    label: string;
    action: () => boolean;
    disabled?: boolean;
  };
  children: ReactNode;
};

export const RightSidebarCard = ({
  icon,
  title,
  rightSection,
  children,
}: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapse = useCallback(() => setCollapsed((prev) => !prev), []);
  const onClickRightSection = useCallback(() => {
    if (!rightSection?.action) {
      return;
    }
    if (rightSection.action()) {
      setCollapsed(false);
    }
  }, [rightSection?.action]);
  const leftElement = useMemo(
    () => (
      <button
        type="button"
        className={twMerge(
          'flex w-full cursor-pointer items-center gap-2 bg-transparent px-4 pb-2 pt-4',
          collapsed ? 'pb-4' : ''
        )}
        onClick={toggleCollapse}
      >
        {icon && <Icon icon={icon} size={20} />}
        <span className="select-none font-bold">{title}</span>
        <Icon
          icon={CaretDown}
          size={20}
          className={!collapsed ? 'rotate-180' : 'rotate-0'}
        />
      </button>
    ),
    [collapsed, toggleCollapse, icon, title]
  );
  const rightElement = useMemo(() => {
    if (!rightSection) {
      return null;
    }
    return (
      <div className={twMerge('pb-2 pl-2 pr-4 pt-4', collapsed ? 'pb-4' : '')}>
        <button
          type="button"
          className="m-0 flex cursor-pointer select-none items-center gap-1 whitespace-nowrap bg-transparent p-0 font-bold text-sea-500"
          onClick={onClickRightSection}
        >
          <Icon icon={rightSection.icon} size={20} />
          <span>{rightSection.label}</span>
        </button>
      </div>
    );
  }, [rightSection?.icon, rightSection?.label, collapsed, onClickRightSection]);
  return (
    <div className="flex flex-col rounded-lg bg-white text-sm shadow-card">
      <div className="justify-betweentext-sm flex items-center">
        {leftElement}
        {rightElement}
      </div>
      {!collapsed && <div className="px-4 pb-4 pt-2">{children}</div>}
    </div>
  );
};
