import { ContactDrawerMemos } from '../../../../components/contact/ContactDrawerMemos/ContactDrawerMemos';
import { useAtom } from 'jotai/index';
import { memosFamily } from '../../../../atoms/firestore/memo';
import { ComponentProps, useCallback, useMemo } from 'react';
import {
  addDoc,
  deleteDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { companyCollection, companyDoc } from '../../../../firestore';
import { useToast } from '../../../../hooks/useToast';
import { useAtomValue } from 'jotai';
import { usersAtom } from '../../../../atoms/firestore/user';
import { meAtom } from '../../../../atoms/auth';

type Props = {
  contactId: string;
  teamId: string;
};

export const ContactDrawerMemosWithLogic = ({ contactId, teamId }: Props) => {
  const { showToast } = useToast();
  const [memos, dispatch] = useAtom(memosFamily({ contactId, teamId }));
  const users = useAtomValue(usersAtom);
  const me = useAtomValue(meAtom);

  const handleAddMemo = useCallback(
    async (value: string) => {
      try {
        await addDoc(companyCollection(`contacts/${contactId}/memos`), {
          userId: me.id,
          text: value,
          teamId,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        return true;
      } catch (e) {
        console.error(e);
        showToast('error', 'メモの保存に失敗しました');
        return false;
      }
    },
    [contactId, me.id, teamId, showToast]
  );

  const handleEdit = useCallback(
    async (memoId: string, value: string) => {
      try {
        await updateDoc(companyDoc(`contacts/${contactId}/memos`, memoId), {
          text: value,
          updatedAt: serverTimestamp(),
        });
        return true;
      } catch (e) {
        console.error(e);
        showToast('error', 'メモの更新に失敗しました');
        return false;
      }
    },
    [contactId]
  );

  const handleDelete = useCallback(
    async (memoId: string) => {
      try {
        await deleteDoc(companyDoc(`contacts/${contactId}/memos`, memoId));
      } catch (e) {
        console.error(e);
        showToast('error', 'メモの削除に失敗しました');
      }
    },
    [contactId, showToast]
  );

  const handleLoadMore = useCallback(() => dispatch('loadMore'), [dispatch]);

  const entries: ComponentProps<typeof ContactDrawerMemos>['memos'] =
    useMemo(() => {
      if (memos.state !== 'hasData') {
        return [];
      }
      return memos.data.map((m) => {
        const author = users.find((u) => u.id === m.userId);
        return {
          id: m.id,
          username: author?.name ?? '',
          avatar: { user: author },
          text: m.text,
          timestamp: m.isOld ? undefined : m.updatedAt.toDate(),
        };
      });
    }, [memos, users]);

  return (
    <ContactDrawerMemos
      memos={entries}
      onAdd={handleAddMemo}
      onEdit={handleEdit}
      onDelete={handleDelete}
      loading={memos.state === 'loading'}
      hasMore={memos.hasMore}
      onLoadMore={handleLoadMore}
    />
  );
};
